import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, CrudFulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const submitFsmManualJobTest = createAsyncThunk("fsmSubmitManualTicketTest", async (payload) => {
  return await APICall(BackendType.MnC, `fsm/createManualJob?testSend=true`, "POST", payload);
});

export const fsmManualJobTestSlice = createSlice({
  name: Slices.FsmManualJobTest,
  initialState: DefaultThunkState(),
  reducers: {
      resetFsmManualJobTestState: (state, action) => {
          state = DefaultThunkState();
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitFsmManualJobTest.pending, PendingThunk())
      .addCase(submitFsmManualJobTest.fulfilled, CrudFulfilledThunk())
      .addCase(submitFsmManualJobTest.rejected, CrudFulfilledThunk())
  },
});

const selectFsmCreateManualJobTestCrudSuccess = createSelector(
  [ state => state[Slices.FsmManualJobTest].crudSuccess ], (d) => d
);

const selectFsmCreateManualJobTestMessage = createSelector(
  [ state => state[Slices.FsmManualJobTest].message ], (d) => d
);

export { submitFsmManualJobTest, selectFsmCreateManualJobTestCrudSuccess, selectFsmCreateManualJobTestMessage };
export const { resetFsmManualJobTestState } = fsmManualJobTestSlice.actions;
export default fsmManualJobTestSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const sliceName = "app";

export const appSlice = createSlice({
    name: sliceName,
    initialState: {
        snackbar: {
            open: false,
            message: "",
            severity: "error"
        }
    },
    reducers: {
        setSnackbar: (state, action) => {
            state.snackbar = action.payload;
        },
        closeSnackbar: (state, action) => {
            state.snackbar.open = false;
        }
    }
});

const selectSnackbar = (state) => {
    return state[sliceName].snackbar;
}

export { selectSnackbar };
export const { setSnackbar, closeSnackbar } = appSlice.actions;
export default appSlice.reducer;
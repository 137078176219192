import { Alert, Snackbar } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectSnackbar, setSnackbar } from "../redux/slices/appSlice"

export default function AppSnackbar()
{
    const dispatch = useDispatch();
    const snackbar = useSelector(selectSnackbar);
    const { open, message, severity } = snackbar;

    const OnClose = (e, reason) => {
        if (reason === "clickaway") return;
        dispatch(setSnackbar({
            open: false,
            severity: severity,
            message: message
        }));

        setTimeout(() => {
            dispatch(setSnackbar({
                open: false,
                severity: severity,
                message: ""
            }));
        }, 300);
    }

    return (
        <Snackbar open={Boolean(open)} message={message} onClose={OnClose}>
            <Alert open={Boolean(open)} severity={severity} onClose={OnClose}>
                {message}
            </Alert>
        </Snackbar>
    )
}
import React from 'react';
import { Dialog, DialogContent, Stack, DialogTitle, IconButton, Box } from '@mui/material';
import { Close } from '@mui/icons-material';

export default function InformationDialog ({ open, onClose, title, children }){
    return (
      <Dialog open={Boolean(open)} onClose={onClose} maxWidth={"lg"}>
        <DialogTitle sx={{borderBottom: "1px solid lightgray"}} pl={1} pr={1} bgcolor={"lightgray"}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            {title}
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Box pt={1}></Box>
        <DialogContent>
          <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
            {children}
          </Stack>
        </DialogContent>
      </Dialog>
    );
};
import React from "react"
import { Button } from "@mui/material";
import { addDays, endOfMonth, format, startOfMonth } from "date-fns"
import { fetchFsmInventoryReport, selectFSMReport } from "../../redux/slices/fsmReportSlice"
import { fetchFsmXLSInventoryReport } from "../../redux/slices/fsmXLSReportSlice"
import { useSelector } from "react-redux"
import { MonitoringSearchDate, MonitoringSearchInput, MonitoringSearchRow, MonitoringSearchText } from "../MonitoringSearchCriteria"
import FSMReportBox from "./FSMReportBox"

const TableStyles = {
    padding: "8px",
    align: "center"
};

const ColumnStyles = {
    ...TableStyles,
    sortable: true
};

const GenerateTableFields = (labels) => {
    return labels.map((l) => {
        return { label: l, ...TableStyles };
    });
}

const Columns = [
    { ...ColumnStyles, label: "SRE Id" },
    { ...ColumnStyles, label: "Machine Id" },
    { ...ColumnStyles, label: "Mail Recv Time" },
    { ...ColumnStyles, label: "FSM Reg Time" },
    { ...ColumnStyles, label: "FSM Job Id" },
    { ...ColumnStyles, label: "Arrival Time" },
    { ...ColumnStyles, label: "Completed Time" },
    { ...ColumnStyles, label: "Reported Issue" },
    { ...ColumnStyles, label: "Action Taken" },
    { ...ColumnStyles, label: "Serviced By" }
];

const SearchGridSizes = [5, 3, 2, 2];
const MobileSearchGridSizes = [5, 7];

export default function MachineInfoReport({isMobile}) {
    const [machId, setMachId] = React.useState("");
    const [sreId, setSreId] = React.useState("");
    const [startDate, setStartDate] = React.useState(startOfMonth(new Date())); 
    const [endDate, setEndDate] = React.useState(endOfMonth(new Date())); 

    const [rows, setRows] = React.useState([]);
    const [gridSize, setGridSize] = React.useState(SearchGridSizes);

    const [filterChips, setFilterChips] = React.useState([]);

    const data = useSelector(selectFSMReport);

    React.useEffect(() => {
        setGridSize(isMobile === true ? MobileSearchGridSizes : SearchGridSizes);
    }, [isMobile]);

    React.useEffect(() => {
        const _rows = data.map((d) => {
            return GenerateTableFields([
                d.JobNo, d.Customer, d.ReportNo, d.IntDoc, d.ArrivalDate, d.ReviewDate, d.MachineId, d.SerialNo, d.MachineIdSerialNo, d.PartNo, d.PartDesc, d.Quantity, d.Chargeable
            ]);
        });

        setRows(_rows);
    }, [data]);

    React.useEffect(() => {
        let _stack = [];

        if (machId.length > 0) _stack.push(machId);
        if (sreId.length > 0) _stack.push(sreId);
        _stack.push(`${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}`);

        setFilterChips(_stack);
    }, [machId, sreId, startDate, endDate]);

    const GetSelectors = () => {
        const payload = {
            machId: machId,
            sreId: sreId,
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd"),
        };

        return {
            fetch: fetchFsmInventoryReport(payload),
            fetchExcel: fetchFsmXLSInventoryReport(payload)
        };
    }

    const ResetDateTime = () => {
        setStartDate(startOfMonth(new Date()));
        setEndDate(endOfMonth(new Date()));
    }

    return (
        <FSMReportBox title={"Machine Information Service Report List"} columns={Columns} rows={rows} filterChips={filterChips} selectors={GetSelectors()} isMobile={isMobile}>
            <MonitoringSearchRow gridSizes={isMobile === true ? [12] : [5]}>
                <MonitoringSearchInput label={"Machine ID"} value={machId} setValue={setMachId} />
            </MonitoringSearchRow>
            <MonitoringSearchRow gridSizes={isMobile === true ? [12] : [5]}>
                <MonitoringSearchInput label={"SRE ID"} value={sreId} setValue={setSreId} />
            </MonitoringSearchRow>
            <MonitoringSearchRow gridSizes={gridSize}>
                <MonitoringSearchDate label={"Date Range"} dateLabel={"Start Date"} value={startDate} setValue={setStartDate} />
                <MonitoringSearchDate dateLabel={"End Date"} value={endDate} setValue={setEndDate} maxDate={addDays(startDate, 30)} />
                <Button variant={"contained"} onClick={ResetDateTime} fullWidth>Reset</Button>
                <MonitoringSearchText color={"blue"} fontStyle={"italic"} >*limit up to 30 days only!</MonitoringSearchText>
            </MonitoringSearchRow>
        </FSMReportBox>
    )
}
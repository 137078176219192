const NavPaths = {
  Home: "",
  BookRoom: "bookroom",
  ExceptionDashboard: "dashboard",
  ExceptionList: "dashboard/list",
  FSMTables: "dashboard/info",
  FSMReport: "dashboard/report",
  FSMInventoryReport: "dashboard/report/inventory",
  MachInfoReport: "dashboard/report/machine",
  FSMManualJob: "dashboard/manualJob",
  FSMManualJobTest: "dashboard/manualJobTest",
  FSMSreSummary: "dashboard/sreSummary",
  FSMMachPartsHistory: "dashboard/machPartsHistory",
  Login: "login"
};

const SupportNav = [
  { text: "System Exception List", subtitle: "Display Exception Table Details", path: NavPaths.ExceptionList },
  { text: "FSM Information List", subtitle: "Display FSM Table Details", path: NavPaths.FSMTables },
  { text: "FSM Manual Job Ticket List", subtitle: "Display FSM Manual Job Ticket Details", path: NavPaths.FSMManualJob },
  { text: "SRE Summary", subtitle: "Display SRE Ticket Details", path: NavPaths.FSMSreSummary },
];

const ReportingNav = [
  { text: "Service Call Report List", subtitle: "Generate Excel for SLM Report", path: NavPaths.FSMReport },
  { text: "FSM Inventory Report", subtitle: "Generate Inventory Report for FSM", path: NavPaths.FSMInventoryReport },
  { text: "Machine Parts History", subtitle: "Display Machine Parts History for FSM", path: NavPaths.FSMMachPartsHistory },
  // { text: "Machine Information Service Report", subtitle: "Generate Machine Informations Report", path: NavPaths.MachInfoReport },
]

const NavItems = [
  // { text: "Home", path: NavPaths.Home },
  // { text: "Book Room", path: NavPaths.BookRoom },
  // { text: "Monitoring", navItems: ExceptionsNav },
  { text: "Dashboard", path: NavPaths.ExceptionDashboard },
  { text: "Support", navItems: SupportNav },
  { text: "Reporting", navItems: ReportingNav }
]

const SiteColors = {
  Blue: "#29B6F6",
  DarkBlue: "#006298",
  Red: "#F44336",
  DarkRed: "#700000",
  Gold: "#FFA726",
  DarkGold: "#904900",
  Green: "#66BB6A",
  DarkGreen: "#005F04",
  StatusNormal: "#ACE2AE",
  StatusWarning: "#FFCD83",
  StatusCritical: "#FBADAD",
  SeverityLow: "#AEAEAE",
  SeverityMedium: "#FFCD83",
  SeverityHigh: "#FBADAD"
};

const Slices = {
  ExceptionDashboard: "exceptionDashboard",
  FsmDashboard: "fsmDashboard",
  FsmTableBankCode: "fsmTableBankCode",
  FsmTableRecord: "fsmTableRecord",
  FsmPendingJob: "fsmPendingJob",
  FsmPreviewInfo: "fsmPreviewInfo",
  FsmProductReview: "fsmProductReview",
  FsmOneProductReview: "fsmOneProductReview",
  FsmDetailPreview: "fsmDetailPreview",
  ExceptionSystems: "exceptionSystems",
  ExceptionSystemExceptions: "exceptionSystemExceptions",
  ExceptionSettingsSms: "exceptionSettingsSms",
  ExceptionSettingsMail: "exceptionSettingsMail",
  ExceptionSettingsNotification: "exceptionSettingsNotification",
  Charts: "charts",
  FsmReport: "fsmReport",
  FsmXLSReport: "fsmXLSReport",
  FsmManualJob: "fsmManualJob",
  FsmManualJobTest: "fsmManualJobTest",
  FsmFollowupEmails: "fsmFollowupEmails",
  FsmFollowupEmailDetails: "fsmFollowupEmailDetails",
  FsmSreSummary: "fsmSreSummary",
  FsmMachPartsHistory: "fsmMachPartsHistory",
};

const DefaultThunkState = (defaultData = []) => {
  return {
    fetching: false,
    data: defaultData,
    message: "",
    crudSuccess: null
  };
};

const ExceptionSeverity = {
  High: 3,
  Medium: 2,
  Low: 1
};

const OpenSansStyled = `
  font-family: Open Sans;
  font-size: 13px;
  line-height: 1.7em;
`;

const SodoSansStyled = `
  font-family: sodo sans regular;
  font-size: 16px;
  font-weight: 400;
`;

const Classes = {
  opensans: "open-sans-font",
  sodosans: "sodo-sans-font"
}

const DefaultTableCellStyle = { align: "center", padding: "8px" };

const MenuPropsHeightLimit = (height) => {
  return {
    PaperProps: {
      style: {
        maxHeight: height
      }
    }
  }
}

exports.NavItems = NavItems;
exports.SiteColors = SiteColors;
exports.Slices = Slices;
exports.DefaultThunkState = DefaultThunkState;
exports.NavPaths = NavPaths;
exports.ExceptionSeverity = ExceptionSeverity;
exports.OpenSansStyled = OpenSansStyled;
exports.SodoSansStyled = SodoSansStyled;
exports.Classes = Classes;
exports.DefaultTableCellStyle = DefaultTableCellStyle;
exports.MenuPropsHeightLimit = MenuPropsHeightLimit;
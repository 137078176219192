import { Box, darken, styled } from "@mui/material";

const TagBadgeStyled = styled(Box, { shouldForwardProp: (props) => props !== 'color' })(({ theme, color }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};

    border: 2px solid ${darken(color?.length > 0 ? color : '#000', 0.25)};
    border-radius: ${theme.spacing(2)};
    background: ${color};
`);

export default function TagBadge({text, color}) {
    return (
        <TagBadgeStyled color={color}>
            { text }
        </TagBadgeStyled>
    )
}
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, CrudFulfilledThunk, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchExceptionSystemExceptions = createAsyncThunk("exception/systemExceptions", async ({ id, unresolved, type }) => {
  return await APICall(BackendType.MnC, `exception/list/${id}?unresolved=${unresolved}&type=${type}`);
});

const resolveSystemException = createAsyncThunk("exception/resolve", async ({ id }) => {
  return await APICall(BackendType.MnC, `exception/resolve`, "POST", { exceptionId: id });
});

const PendingAction = (state, action) => {
  PendingThunk()(state, action);
  state.refetch = false;
}

const FulfilledAction = (state, action) => {
  FulfilledThunk()(state, action);
  state.refetch = false;
}

const CrudFulfilledAction = (state, action) => {
  CrudFulfilledThunk()(state, action);
  state.refetch = action.payload.statusCode === 200;
}

export const exceptionSystemExceptionsSlice = createSlice({
  name: Slices.ExceptionSystemExceptions,
  initialState: {
    ...DefaultThunkState(),
    refetch: false
  },
  reducers: {
      clearSystemExceptions: (state, action) => {
          state.data = [];
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExceptionSystemExceptions.pending, PendingAction)
      .addCase(fetchExceptionSystemExceptions.fulfilled, FulfilledAction)
      .addCase(fetchExceptionSystemExceptions.rejected, FulfilledAction)

      .addCase(resolveSystemException.pending, PendingAction)
      .addCase(resolveSystemException.fulfilled, CrudFulfilledAction)
      .addCase(resolveSystemException.rejected, CrudFulfilledAction);
  },
});

const selectSystemExceptionsData = createSelector(
  [ state => state[Slices.ExceptionSystemExceptions].data ], (d) => d
);

const selectSystemExceptionsRefetch = createSelector(
  [ state => state[Slices.ExceptionSystemExceptions].refetch ], (r) => r
);

const selectSystemExceptionsFetching = createSelector(
  [ state => state[Slices.ExceptionSystemExceptions].fetching ], (r) => r
);

export { fetchExceptionSystemExceptions, resolveSystemException, selectSystemExceptionsData, selectSystemExceptionsRefetch, selectSystemExceptionsFetching };
export const { clearSystemExceptions } = exceptionSystemExceptionsSlice.actions;
export default exceptionSystemExceptionsSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType } from "../../Utils";
import { format } from "date-fns";

const sliceName = "timeslot";

const fetchMeetings = createAsyncThunk('getMeetings', async ({date, roomId}) => {
    const dateStr = format(date, "yyyyMMdd");
    const resp = await APICall(BackendType.RoomBooking, `bookroom/getMeetings?date=${dateStr}&room=${roomId}`);
    return resp.response;
});

const bookRoom = createAsyncThunk('bookRoom', async (payload) => {
    return await APICall(BackendType.RoomBooking, `bookroom/bookRoom`, 'POST', payload);
});

const startMeeting = createAsyncThunk('startMeeting', async (payload) => {
    return await APICall(BackendType.RoomBooking, `bookroom/startMeeting`, 'POST', payload);
});

const endMeeting = createAsyncThunk('endMeeting', async (payload) => {
    return await APICall(BackendType.RoomBooking, `bookroom/endMeeting`, 'POST', payload);
});

const cancelMeeting = createAsyncThunk('cancelMeeting', async (payload) => {
    return await APICall(BackendType.RoomBooking, `bookroom/cancelMeeting`, 'POST', payload);
});

const extendMeeting = createAsyncThunk('extendMeeting', async (payload) => {
    return await APICall(BackendType.RoomBooking, `bookroom/extendMeeting`, "POST", payload);
});

const ModifyBookingPending = (state) => {
    state.refresh = false;
    state.message = "";
}

const ModifyBookingFulfilled = (state, action) => {
    if (action.payload.statusCode === 200) 
    {
        state.refresh = true;
    }
    else
    {
        state.message = action.payload.response.message;
    }
}

export const timeslotSlice = createSlice({
    name: sliceName,
    initialState: {
        fetching: false,
        meetings: [],
        refresh: false,
        refreshNextMeeting: false,
        message: ""
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchMeetings.pending, (state) => {
            state.fetching = true;
            state.refresh = false;
            state.message = "";
        })
        .addCase(fetchMeetings.fulfilled, (state, action) => {
            state.meetings = action.payload?.data ?? [];
            state.fetching = false;
        })
        .addCase(fetchMeetings.rejected, (state, action) => {
            state.fetching = false;
            state.meetings = [];
        })
        .addCase(bookRoom.pending, ModifyBookingPending)
        .addCase(bookRoom.fulfilled, ModifyBookingFulfilled)

        .addCase(startMeeting.pending, (state) => {
            ModifyBookingPending(state);
            state.refreshNextMeeting = false;
        })
        .addCase(startMeeting.fulfilled, (state, action) => {
            ModifyBookingFulfilled(state, action);
            state.refreshNextMeeting = true;
        })

        .addCase(endMeeting.pending, (state) => {
            ModifyBookingPending(state);
            state.refreshNextMeeting = false;
        })
        .addCase(endMeeting.fulfilled, (state, action) => {
            ModifyBookingFulfilled(state, action);
            state.refreshNextMeeting = true;
        })

        .addCase(cancelMeeting.pending, (state) => {
            ModifyBookingPending(state);
            state.refreshNextMeeting = false;
        })
        .addCase(cancelMeeting.fulfilled, (state, action) => {
            ModifyBookingFulfilled(state, action);
            state.refreshNextMeeting = true;
        })
        .addCase(extendMeeting.pending, (state) => {
            ModifyBookingPending(state);
            state.refreshNextMeeting = false;
        })
        .addCase(extendMeeting.fulfilled, (state, action) => {
            ModifyBookingFulfilled(state, action);
            state.refreshNextMeeting = true;
        })
    }
});

const selectRefresh = (state) => {
    return state[sliceName].refresh;
}

const selectFetching = (state) => {
    return state[sliceName].fetching;
}

const selectMeetings = (state) => {
    return state[sliceName].meetings;
}

const selectMessage = (state) => {
    return state[sliceName].message;
}

const selectRefreshNextMeeting = (state) => {
    return state[sliceName].refreshNextMeeting;
}

export { fetchMeetings, bookRoom, startMeeting, endMeeting, cancelMeeting, extendMeeting, selectRefresh, selectFetching, selectMeetings, selectMessage, selectRefreshNextMeeting };
export default timeslotSlice.reducer;
import React from 'react'
import { Bar } from 'react-chartjs-2'
import { setSnackbar } from "../../redux/slices/appSlice"
import {Chart as chartjs} from 'chart.js/auto'
import { fetchChartData } from '../../redux/slices/chartSlice'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import useEffectNoMount from '../../CustomHooks/useEffectNoMount'

const ChartData = ({daysOrWeeks}) => {
    const dispatch = useDispatch();
    const [chartData, setChartData] = useState({
        datasets: [],
    });
    
    const [chartOptions, setChartOptions] = useState({});
    
    useEffectNoMount(() => {
        let errorMessage = []
        let total = []
        
        dispatch(fetchChartData(daysOrWeeks))
        .then(response => {
            if (response.payload.response.Data.length === 0) {
                setChartData(null);
                setChartOptions(null);
            } else {
                for(const dataObj of response.payload.response.Data){
                    errorMessage.push(dataObj.errorMessage)
                    total.push(parseInt(dataObj.total))
                }
                const datasetLabels = errorMessage.map((_, index) => `Error ${index + 1}`); // Limit to 10 characters
                setChartData({
                    labels: datasetLabels,
                    datasets: [
                        {
                            label: 'Total',
                            data: total,
                            backgroundColor: 'rgba(203,221,230,0.7)',
                            borderColor:'#5072A7',
                            borderWidth:'2'
                        },
                    ]
                });
                setChartOptions({
                    scales: {
                        x: {
                            display: false,
                            title: {
                                display: false,
                                text: 'ErrorMessage',
                            },
                        },
                        y: {
                            title: {
                                display: false,
                                text: 'Total',
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                title: (context) => errorMessage[context[0].dataIndex]
                            }
                        }    
                    },
                    backgroundColor: 'rgba(255, 255, 255, 1)'
                }) 
            }    
        })
        .catch(error => {
            dispatch(setSnackbar({
              open: true,
              severity: 'error',
              message: 'An error occurred while fetching chart data.'
            }));
        })
         
    }, [dispatch,daysOrWeeks]);

  return (
    <div>
        {daysOrWeeks && (chartData ? (
                <Bar data={chartData} options={chartOptions} />
            ) : (
                <div>No problems reported today!</div>
            ))}
    </div>
  )
}

export default ChartData

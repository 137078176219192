import { Box, CircularProgress, MenuItem, Pagination, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography, styled } from "@mui/material";
import React from "react";
import { AutoSizer } from "react-virtualized";
import { Classes, OpenSansStyled } from "../constants";
import { compareDesc, parse } from "date-fns";

const NewTableStyled = styled(Table)`
    th, td, p {
        ${OpenSansStyled}
    }

    .MuiTableHead-root {
        .MuiTableCell-root {
            background: black;
            color: white;

            .MuiButtonBase-root {
                color: white;

                .MuiSvgIcon-root {
                    color: white;
                }
            }
        }
    }

    .MuiTableCell-root {
        border: 1px solid #2D5171;
    }
`;

const StyledPagination = styled(Pagination)`
    .MuiPaginationItem-root {
        ${OpenSansStyled}
    }
`;

const StyledSelect = styled(Select)`
    .MuiInputBase-input {
        ${OpenSansStyled}
    }
`;

const DefaultDate = new Date(1970, 1, 1, 0, 0, 0);

function descendingComparator(a, b, orderBy, columns) {
    if (orderBy < 0) return 0;

    let _type = columns[orderBy]?.type;
    if (_type === undefined) _type = String;

    let _dtFormat = columns[orderBy]?.dateFormat;

    a = a[orderBy].label ?? a[orderBy].render;
    b = b[orderBy].label ?? b[orderBy].render;
    
    switch (_type.name)
    {
        case Number.name:
            a = Number(a);
            b = Number(b);
            break;
        case Date.name:
            if (_dtFormat !== undefined)
            {
                a = a?.length <= 0 ? DefaultDate : parse(a, _dtFormat, new Date());
                b = b?.length <= 0 ? DefaultDate : parse(b, _dtFormat, new Date());
            }
            else
            {
                a = a?.length <= 0 ? DefaultDate : Date.parse(a);
                b = b?.length <= 0 ? DefaultDate : Date.parse(b);
            }

            return compareDesc(a, b);
    }

    if (b < a) {
        return -1;
    }
    if (b > a) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy, columns) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, columns)
        : (a, b) => -descendingComparator(a, b, orderBy, columns);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const InnerTableBody = React.memo(function InnerTableBody({columns, rows, disablePagination, disablePageSizes, disableFooter, pageSizeOptions, loading, sibling, boundary, skipLeft, skipRight, rightHeader, page, OnPageChanged}) {
    const [finalRows, setFinalRows] = React.useState(rows);
    const [pageCounts, setPageCounts] = React.useState(1);

    const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
    const [curPage, setCurPage] = React.useState(page);

    const [displayRows, setDisplayRows] = React.useState([]);
    const [startRow, setStartRow] = React.useState(1);

    const [sortCol, setSortCol] = React.useState("");
    const [sort, setSort] = React.useState("asc");

    React.useEffect(() => {
        if (finalRows.length <= 0)
        {
            setPageCounts(1);
            return;
        }

        let pageCounts = Math.ceil(finalRows.length/pageSize);
        
        if (curPage > pageCounts)
        {
            setCurPage(1);
        }
        setPageCounts(pageCounts);
    }, [finalRows, pageSize]);

    React.useEffect(() => {
        let endCount = curPage * pageSize;
        let startCount = endCount - pageSize;

        setStartRow(startCount + 1);
        setDisplayRows(finalRows.slice(startCount, endCount));
    }, [finalRows, curPage, pageSize]);

    React.useEffect(() => {
        if (sortCol.length <= 0)
        {
            let sortIndex = columns.findIndex(c => c.defaultSort === true);
            if (sortIndex >= 0)
            {
                setSortCol(columns[sortIndex].label);
                return;
            }
        }
        
        const labelIndex = columns.findIndex(c => c.label === sortCol);
        const _rows = stableSort(rows, getComparator(sort, labelIndex, columns));
        setFinalRows(_rows);
    }, [rows, sort, sortCol]);

    const OnPageSizeChanged = (e) => {
        setPageSize(e.target.value);
    }
    
    const OnCurPageChanged = (e, v) => {
        if (OnPageChanged !== undefined)
        {
            OnPageChanged(v);
        }

        setCurPage(v);
    }

    const OnSort = (label) => () => {
        const isAsc = sortCol === label && sort === 'asc';

        setSort(isAsc ? 'desc' : 'asc');
        setSortCol(label);
    }

    return (
        <>
            {
                disablePagination !== true &&
                <Stack justifyContent={"space-between"} flexDirection={"row"}>
                    <StyledPagination count={pageCounts} siblingCount={sibling} boundaryCount={boundary} shape={"rounded"} variant={"outlined"}  page={curPage} onChange={OnCurPageChanged} showFirstButton={skipLeft} showLastButton={skipRight} />
                    {
                        rightHeader !== null &&
                        rightHeader
                    }
                    {
                        (rightHeader === null && disablePageSizes !== true) &&
                        <Typography className={Classes.opensans}>
                            Show&nbsp;
                            <StyledSelect value={pageSize} onChange={OnPageSizeChanged} size={"small"}>
                                {
                                    pageSizeOptions.map((ps) => {
                                        return (
                                            <MenuItem key={`new-table-pagination-page-size-${ps}`} value={ps}>{ps}</MenuItem>
                                        )
                                    })
                                }
                            </StyledSelect> 
                            &nbsp;per page
                        </Typography>
                    }
                </Stack>
            }
            <TableContainer sx={{flex: 1}}>
                <NewTableStyled stickyHeader>
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((c) => {
                                    return (
                                        <TableCell key={`new-table-cell-${c.label}`} align={c.align ?? "left"} sx={{padding: c.padding ?? "16px", width: c.width ?? "auto"}} sortDirection={sortCol === c.label ? sort : false}>
                                            {
                                                c.sortable === true &&
                                                <TableSortLabel active={sortCol === c.label} direction={sortCol === c.label ? sort : 'asc'} hideSortIcon={true}
                                                    onClick={OnSort(c.label)}>
                                                    {c.label}
                                                </TableSortLabel>
                                            }
                                            {
                                                c.sortable !== true &&
                                                c.label
                                            }
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading === true &&
                            <TableRow>
                                <TableCell align="center" colSpan={columns.length}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        }
                        {
                            (loading !== true && displayRows.length > 0) &&
                            displayRows.map((r, rInd) => {
                                return (
                                    <TableRow key={`new-table-body-row-${rInd}`}>
                                        {
                                            r.length > 0 &&
                                            r.map((cell, cInd) => {
                                                return (
                                                    <TableCell key={`new-table-body-cell-${rInd}-${cInd}`} colSpan={cell.colspan ?? 1} align={cell.align ?? "left"} sx={{background: cell.background ?? "white", color: cell.color ?? "black", padding: cell.padding ?? "16px"}}>{cell.label ?? cell.render ?? ""}</TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </NewTableStyled>
            </TableContainer>
            {
                (disablePagination !== true && disableFooter !== true) &&
                <Typography className={Classes.opensans} textAlign={"right"}>
                    {startRow}-{startRow + displayRows.length - 1} of {rows.length}
                </Typography>
            }
        </>
    )
});

const InnerTable = ({columns, rows, disablePagination, disablePageSizes, disableFooter, pageSizeOptions, loading, height="100%", width="100%", sibling, boundary, skipLeft, skipRight, rightHeader, page, OnPageChanged}) => {
    return (
        <Stack spacing={1} height={height} width={width}>
            <InnerTableBody columns={columns} rows={rows} disablePagination={disablePagination} disablePageSizes={disablePageSizes} disableFooter={disableFooter} pageSizeOptions={pageSizeOptions} loading={loading}
                sibling={sibling} boundary={boundary} skipLeft={skipLeft} skipRight={skipRight} rightHeader={rightHeader} page={page} OnPageChanged={OnPageChanged} />
        </Stack>
    )
}

/*
    columns: Array of objects 
    { 
        label: ""
        align: "left", "center", "right" (optional)
        padding: "" (optional)
        width: "" (optional)
    }

    rows: 2D array of objects
    [                           
        // Row 1
        [ 
            // Cell 1
            {
                label: "" (Takes priority over render)
                render: React Component

                colspan: number (optional)
                align: "left", "center", "right" (optional)
                background: "" (optional)
                color: "" (optional)
                padding: "" (optional)
            },
            // Cell 2
            {},
            // Cell 3
            {},
            ...
        ],
        // Row 2
        [

        ],
        ...
    ]
*/
export default function NewTable({columns, rows, disablePagination=false, disablePageSizes=false, disableFooter=false, autoHeight=false, pageSizeOptions=[5,10,15], loading=false, sibling = 1, boundary = 1, skipLeft = false, skipRight = false, rightHeader = null, page = 1, OnPageChanged = undefined}) {
    return (
        <Box height={"100%"}>
            {
                autoHeight !== true &&
                <InnerTable columns={columns} rows={rows} disablePagination={disablePagination} disablePageSizes={disablePageSizes} disableFooter={disableFooter} pageSizeOptions={pageSizeOptions} loading={loading} sibling={sibling} boundary={boundary} skipLeft={skipLeft} skipRight={skipRight} rightHeader={rightHeader} page={page} OnPageChanged={OnPageChanged} />
            }
            {
                autoHeight === true &&
                <AutoSizer>
                    {({width, height}) => 
                        <InnerTable height={height} width={width} columns={columns} rows={rows} disablePagination={disablePagination} disablePageSizes={disablePageSizes} disableFooter={disableFooter} pageSizeOptions={pageSizeOptions} loading={loading} sibling={sibling} boundary={boundary} skipLeft={skipLeft} skipRight={skipRight} rightHeader={rightHeader} page={page} OnPageChanged={OnPageChanged} />
                    }
                </AutoSizer>
            }
        </Box>
    )
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchExceptionSystems = createAsyncThunk("exceptionSystems", async () => {
  return await APICall(BackendType.MnC, "exception/systems");
});

export const exceptionSystemsSlice = createSlice({
  name: Slices.ExceptionSystems,
  initialState: DefaultThunkState(),
  extraReducers: (builder) => {
    builder
      .addCase(fetchExceptionSystems.pending, PendingThunk())
      .addCase(fetchExceptionSystems.fulfilled, FulfilledThunk())
      .addCase(fetchExceptionSystems.rejected, FulfilledThunk());
  },
});

export { fetchExceptionSystems };
export default exceptionSystemsSlice.reducer;

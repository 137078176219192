import React from "react"
import { addDays, endOfMonth, format, startOfMonth } from "date-fns"
import { fetchFsmReport, selectFSMReport } from "../../redux/slices/fsmReportSlice"
import { fetchFsmXLSReport } from "../../redux/slices/fsmXLSReportSlice"
import { useSelector } from "react-redux"
import { MonitoringSearchDate, MonitoringSearchRow, MonitoringSearchSelect, MonitoringSearchText } from "../MonitoringSearchCriteria"
import FSMReportBox from "./FSMReportBox"

const TableStyles = {
    padding: "8px",
    align: "center"
};

const ColumnStyles = {
    ...TableStyles,
    sortable: true
};

const GenerateTableFields = (labels) => {
    return labels.map((l) => {
        return { label: l, ...TableStyles };
    });
}

const HSBCColumns = [
    { ...ColumnStyles, label: "Bank" },
    { ...ColumnStyles, label: "Br Code" },
    { ...ColumnStyles, label: "CQM" },
    { ...ColumnStyles, label: "MTBF Machine (Y/N)" },
    { ...ColumnStyles, label: "Location" },
    { ...ColumnStyles, label: "Date" },
    { ...ColumnStyles, label: "Call Time" },
    { ...ColumnStyles, label: "Arrival Time" },
    { ...ColumnStyles, label: "Complete Time" },
    { ...ColumnStyles, label: "Waiting Time" },
    { ...ColumnStyles, label: "Call Type (SC/request)" },
    { ...ColumnStyles, label: "Machine Normal?" },
    { ...ColumnStyles, label: "Resp Time" },
    { ...ColumnStyles, label: "Down Time" },
    { ...ColumnStyles, label: "MTBF Failure (Y/N)" },
    { ...ColumnStyles, label: "Problem Code" },
    { ...ColumnStyles, label: "Fault Found by Vendor" },
    { ...ColumnStyles, label: "Remarks" },
    { ...ColumnStyles, label: "Gross Down Time" },
    { ...ColumnStyles, label: "Vendor's Comment" },
    { ...ColumnStyles, label: "Multiple same day failure" },
    { ...ColumnStyles, label: "Serial" },
    { ...ColumnStyles, label: "JR No.#" },
    { ...ColumnStyles, label: "CE" },
    { ...ColumnStyles, label: "Major" },
    { ...ColumnStyles, label: "Ticket No.#" }
];

const BOCColumns = [
    { ...ColumnStyles, label: "Term ID" },
    { ...ColumnStyles, label: "JR" },
    { ...ColumnStyles, label: "SN" },
    { ...ColumnStyles, label: "Location" },
    { ...ColumnStyles, label: "Entered Date" },
    { ...ColumnStyles, label: "Entered Time" },
    { ...ColumnStyles, label: "Appointment Party" },
    { ...ColumnStyles, label: "Appointment Date" },
    { ...ColumnStyles, label: "Appointment Time" },
    { ...ColumnStyles, label: "Arrival Date" },
    { ...ColumnStyles, label: "Arrival Time" },
    { ...ColumnStyles, label: "Close Date" },
    { ...ColumnStyles, label: "Close Time" },
    { ...ColumnStyles, label: "SLA" },
    { ...ColumnStyles, label: "Problem Code" },
    { ...ColumnStyles, label: "Problem Description" },
    { ...ColumnStyles, label: "Helpdesk Reported Problem" },
    { ...ColumnStyles, label: "RI Findings" },
    { ...ColumnStyles, label: "FLM OR SLM" },
    { ...ColumnStyles, label: "CE" }
];

const BankName = {
    "HSBC": "004",
    "HASE": "024",
    "BOC": "012",
    "NCB": "043",
    "CYB": "039"  
  };

const SelectBankName = Object.entries(BankName).map(([k, v]) => {
    return {
        label: k,
        value: v
    }
});

const DateType = {
    "Call Received Date": "job_call_received_dt",
    "Arrival Date": "job_arrival_dt"
}

const SelectDateType = Object.entries(DateType).map(([k, v]) => {
    return {
        label: k,
        value: v
    }
});

const SearchGridSizes = [6, 4, 2];
const MobileSearchGridSizes = [6, 6];

export default function FSMServiceCallReport({isMobile}) {
    const [startDate, setStartDate] = React.useState(startOfMonth(new Date())); 
    const [endDate, setEndDate] = React.useState(endOfMonth(new Date())); 
    const [bank, setBank] = React.useState(Object.values(BankName)[0]);
    const [dateType, setDateType] = React.useState(Object.values(DateType)[0]); 
    const [columns, setColumns] = React.useState(HSBCColumns);

    const [rows, setRows] = React.useState([]);
    const [gridSize, setGridSize] = React.useState(SearchGridSizes);

    const [filterChips, setFilterChips] = React.useState([]);

    const data = useSelector(selectFSMReport);

    React.useEffect(() => {
        setGridSize(isMobile === true ? MobileSearchGridSizes : SearchGridSizes);
    }, [isMobile]);

    React.useEffect(() => {
        let _rows = [];
        let _dataAction = (d) => {};
        let _columns = [];

        switch (bank)
        {
            case "004":
            case "024":
                _columns = HSBCColumns;
                _dataAction = (d) => {
                    _rows.push(GenerateTableFields([
                        d.Bank, d.BrCode, d.CQM, d.MTBFMach, d.Location, d.Date, d.CallTime, d.ArrivalTime, d.CompleteTime, d.WaitingTime,
                        d.CallType, d.MachineNormal, d.RespTime, d.DownTime, d.MTBFFail, d.ProbCode, d.FaultFound, d.Remarks, d.GrossDowntime,
                        "", "", d.Serial, d.JRNo, d.CE, d.Major, d.TicketNo
                    ]));
                };
                break;
            case "012":
            case "043":
            case "039":
                _columns = BOCColumns;
                _dataAction = (d) => {
                    _rows.push(GenerateTableFields([d.TermID, d.JR, d.SN, d.Location, d.EnteredDate, d.EnteredTime, d.AppointmentParty, d.AppointmentDate, d.AppointmentTime, d.ArrivalDate, d.ArrivalTime,
                        d.CloseDate, d.CloseTime, d.SLA, d.ProblemCode, d.ProblemDescription, d.HelpdeskReportedProblem, d.RIFindings, d.FLMOrSLM, d.CE]));
                };
                break;
        }

        data.forEach(_dataAction);

        setRows(_rows);
        setColumns(_columns);
    }, [data]);
    
    React.useEffect(() => {
        let _stack = [];

        _stack.push(SelectBankName.find(x => x.value === bank).label);
        _stack.push(SelectDateType.find(x => x.value === dateType).label);
        _stack.push(`${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}`);

        setFilterChips(_stack);
    }, [bank, startDate, endDate]);
    
    const GetSelectors = () => {
        const payload = {
            bankName: bank,
            dateType: dateType,
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd"),
        };

        return {
            fetch: fetchFsmReport(payload),
            fetchExcel: fetchFsmXLSReport(payload)
        };
    }
    
    return (
        <FSMReportBox title={"FSM Service Call Report List"} columns={columns} rows={rows} filterChips={filterChips} selectors={GetSelectors()} isMobile={isMobile}>
            <MonitoringSearchRow gridSizes={isMobile === true ? [12] : [6]}>
                <MonitoringSearchSelect label={"Bank Name"} value={bank} setValue={setBank} items={SelectBankName} />
            </MonitoringSearchRow>
            <MonitoringSearchRow gridSizes={isMobile === true ? [12] : [6]}>
                <MonitoringSearchSelect label={"Date Type"} value={dateType} setValue={setDateType} items={SelectDateType} />
            </MonitoringSearchRow>
            <MonitoringSearchRow gridSizes={gridSize}>
                <MonitoringSearchDate label={"Date Range"} dateLabel={"Start Date"} value={startDate} setValue={setStartDate} />
                <MonitoringSearchDate dateLabel={"End Date"} value={endDate} setValue={setEndDate} maxDate={addDays(startDate, 30)} />
                <MonitoringSearchText color={"blue"} fontStyle={"italic"} >*limit up to 30 days only!</MonitoringSearchText>
            </MonitoringSearchRow>
        </FSMReportBox>
    )
}
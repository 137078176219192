import { FormControl, Grid } from "@mui/material";

export default function BookingInputLine ({small, text, children}) {
    return (
        <Grid container alignItems={"center"}>
            <Grid item xs={4}>
                {text}:
            </Grid>
            <Grid item xs={8}>
                <FormControl fullWidth size={small === true ? "small": "medium"}>
                    {children}
                </FormControl>
            </Grid>
        </Grid>
    )
}
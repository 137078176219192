import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchFsmProductReview = createAsyncThunk("fsmProductReview", async () => {
  return await APICall(BackendType.MnC, `fsm/productReview`);
});

export const fsmProductReviewSlice = createSlice({
  name: Slices.FsmProductReview,
  initialState: DefaultThunkState(),
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmProductReview.pending, PendingThunk())
      .addCase(fetchFsmProductReview.fulfilled, FulfilledThunk())
      .addCase(fetchFsmProductReview.rejected, FulfilledThunk());
  },
});

const selectFsmProductReviewFetching = createSelector(
  [ state => state[Slices.FsmProductReview].fetching ], (d) => d
);

const selectFsmProductReviewData = createSelector(
  [ state => state[Slices.FsmProductReview].data ], (d) => d
);

export { fetchFsmProductReview, selectFsmProductReviewFetching, selectFsmProductReviewData };
export default fsmProductReviewSlice.reducer;

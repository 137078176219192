import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType } from "../../Utils";

const sliceName = "room";

const fetchRooms = createAsyncThunk('rooms', async () => {
    const resp = await APICall(BackendType.RoomBooking, `bookroom/rooms`);
    return resp.response;
});

export const roomSlice = createSlice({
    name: sliceName,
    initialState: {
        fetching: false,
        rooms: []
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchRooms.pending, (state) => {
            state.fetching = true;
        })
        .addCase(fetchRooms.fulfilled, (state, action) => {
            state.rooms = action.payload?.data ?? [];
            state.fetching = false;
        })
        .addCase(fetchRooms.rejected, (state, action) => {
            state.fetching = false;
            state.rooms = [];
        })
    }
});

const selectRoomFetching = (state) => {
    return state[sliceName].fetching;
}

const selectRooms = (state) => {
    return state[sliceName].rooms;
}

export { fetchRooms, selectRoomFetching, selectRooms };
export default roomSlice.reducer;
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";
import { format } from "date-fns";

const fetchFsmTableRecords = createAsyncThunk("fsmTableRecord", async ({table, startDate, endDate, sreId, bankCode, mailStatus}) => {
  let url = `fsm/records?type=${table}&startDate=${format(startDate, "MM/dd/yyyy")}&endDate=${format(endDate, "MM/dd/yyyy")}`;

  if (sreId?.length > 0)
  {
    url += `&sreId=${sreId}`;
  }

  if (bankCode?.length > 0)
  {
    url += `&bankCode=${bankCode}`;
  }

  if (mailStatus?.length > 0)
  {
    url += `&mailStatusArray=${mailStatus}`;
  }

  return await APICall(BackendType.MnC, url);
});

export const fsmTableRecordSlice = createSlice({
  name: Slices.FsmTableRecord,
  initialState: DefaultThunkState(),
  reducers: {
      clearFsmTableRecords: (state, action) => {
          state.data = [];
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmTableRecords.pending, PendingThunk())
      .addCase(fetchFsmTableRecords.fulfilled, FulfilledThunk())
      .addCase(fetchFsmTableRecords.rejected, FulfilledThunk());
  },
});

const selectFSMTableRecords = createSelector(
  [ state => state[Slices.FsmTableRecord].data ], (d) => d
);

const selectFSMTableRecordsFetching = createSelector(
  [ state => state[Slices.FsmTableRecord].fetching ], (d) => d
);

export { fetchFsmTableRecords, selectFSMTableRecords, selectFSMTableRecordsFetching };
export const { clearFsmTableRecords } = fsmTableRecordSlice.actions;
export default fsmTableRecordSlice.reducer;

import axios from "axios";
import { format, parse } from "date-fns";

const RoomStatus = {
  Available: 1,
  In_Use: 2,
  Starting_Soon: 3,
};

const BackendType = {
  RoomBooking: 1,
  MnC: 2,
}

const SeverityLevel = {
  Low: 1,
  Medium: 2,
  High: 3
}

const APICall = async (backend, path, method = "GET", data = {}, responseType = 'json') => {
  let url = "";

  switch (backend)
  {
    case BackendType.RoomBooking:
      url = `${process.env.REACT_APP_BACKEND_SECURE}://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/${path}`;
      break;
    case BackendType.MnC:
      url = `${process.env.REACT_APP_BACKEND_CSWEBAPI_SECURE}://${process.env.REACT_APP_BACKEND_CSWEBAPI_HOST}:${process.env.REACT_APP_BACKEND_CSWEBAPI_PORT}/${path}`;
      break;
    default:
      return { Data: [], Message: "" }
  }

  let options = {
    url: url,
    method: method,
    responseType: responseType
  };

  if (Object.keys(data).length > 0) {
    options.data = data;
  }

  let resp = {
    statusCode: 200,
    response: {},
    headers: {}
  };

  try
  {
    let axiosResp = await axios.request(options);
    resp.response = axiosResp.data;
    resp.headers = axiosResp.headers.toJSON();
  }
  catch (err)
  {
    resp.statusCode = err.response?.status ?? 0;
    resp.response = err.response?.data ?? { Data: [], Message: err.message }
  }

  return resp;
}

const ParseDT = (dt) => {
  if (typeof(dt) !== "string") return dt;
  return Date.parse(dt);
}

const FormatDateTime12Hour = (dt) => {
  return format(ParseDT(dt), "dd/MM/yyyy hh:mm:ss a");
}

const FormatDateTime24Hour = (dt) => {
  return format(ParseDT(dt), "dd/MM/yyyy HH:mm:ss");
}

const DateTimeValueGetter = (params) => {
  if (!params.value) return params.value;
  return ParseDT(params.value);
}

const DateTime12HourFormatter = (params) => {
  if (!params.value) return params.value;
  return FormatDateTime12Hour(params.value);
}

const DateTime24HourFormatter = (params) => {
  if (!params.value) return params.value;
  return FormatDateTime24Hour(params.value);
}

const PendingThunk = (defaultData = []) => (state, action) => {
  state.fetching = true;
  state.data = defaultData;
  state.message = "";
  state.crudSuccess = null;
}

const FulfilledThunk = (defaultData = []) => (state, action) => {
  state.fetching = false;
  state.message = action.payload.response?.Message ?? "";

  state.data = action.payload.statusCode === 200 ? action.payload.response?.Data ?? defaultData : defaultData;
};

const CrudFulfilledThunk = (defaultData = []) => (state, action) => {
  state.fetching = false;
  state.message = action.payload.response?.Message ?? "";

  state.data = action.payload.statusCode === 200 ? action.payload.response?.Data ?? defaultData : defaultData;
  state.crudSuccess = action.payload.statusCode === 200;
}

const GetCookie = (key) => {
  let cookies = document.cookie.split(';').map((c) => c.trim());
  let index = cookies.findIndex(c => c.startsWith(`${key}=`));

  if (index < 0) return undefined;
  return cookies[index].split('=')[1];
}

const ParseDBDate = (val, parseFormat, retFormat) => {
  if (val.length <= 0 ) return "N/A";

  let dt = process.env.NODE_ENV === 'development'
      ? parse(val, parseFormat, new Date())
      : Date.parse(val);

  return format(dt, retFormat);
}

const SanitizeInput = (str) => {
  if (str.length <= 0) return str;
  return str.replaceAll("'", "").replaceAll("\"", "").replaceAll('%', '%25');
}

export { APICall, FormatDateTime12Hour, FormatDateTime24Hour, DateTimeValueGetter, DateTime12HourFormatter, DateTime24HourFormatter, PendingThunk, FulfilledThunk, CrudFulfilledThunk, GetCookie,
  ParseDBDate, SanitizeInput,
  RoomStatus, BackendType, SeverityLevel };

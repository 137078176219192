import { configureStore } from '@reduxjs/toolkit';
// App
import appReducer from './slices/appSlice';
// bookroom
import timeslotReducer from './slices/timeslotSlice';
import meetingReducer from './slices/meetingSlice';
import roomReducer from './slices/roomSlice';
import reasonReducer from './slices/reasonSlice';
import exceptionDashboardReducer from './slices/exceptionDashboardSlice';
import fsmDashboardReducer from './slices/fsmDashboardSlice';
import fsmTableBankCodeReducer from './slices/fsmTableBankCodeSlice';
import fsmTableRecordReducer from './slices/fsmTableRecordSlice';
import exceptionSystemsReducer from './slices/exceptionSystemsSlice';
import exceptionSystemExceptionsReducer from './slices/exceptionSystemExceptionsSlice';
import exceptionSettingsSmsReducer from './slices/exceptionSettingsSmsSlice';
import exceptionSettingsMailReducer from './slices/exceptionSettingsMailSlice';
import exceptionSettingsNotificationReducer from './slices/exceptionSettingsNotificationSlice';
import fsmPendingJobReducer from './slices/fsmPendingJobSlice';
import fsmPreviewInfoReducer from './slices/fsmPreviewInfoSlice';
import fsmDetailPreviewReducer from './slices/fsmDetailPreviewSlice';
import tempLoginReducer from './slices/tempLoginSlice';
import chartSliceReducer from './slices/chartSlice';
import fsmReportSliceReducer from './slices/fsmReportSlice';
import fsmXLSReportSliceReducer from './slices/fsmXLSReportSlice';
import fsmProductReviewReducer from './slices/fsmProductReviewSlice';
import fsmOneProductReviewReducer from './slices/fsmOneProductReviewSlice';
import fsmManualJobReducer from './slices/fsmManualJobSlice';
import fsmFollowupEmailReducer from './slices/fsmFollowupSlice';
import fsmFollowupEmailDetailsReducer from './slices/fsmFollowupDetailsSlice';
import fsmManualJobTestReducer from './slices/fsmManualJobTestSlice';
import fsmSreSummaryReducer from './slices/fsmSreSummarySlice';
import fsmMachPartsHistoryReducer from './slices/fsmMachPartsHistorySlice';

export default configureStore({
    reducer: {
        // App
        app: appReducer,
        // bookroom
        timeslot: timeslotReducer,
        meeting: meetingReducer,
        room: roomReducer,
        reason: reasonReducer,
        // monitoring
        exceptionDashboard: exceptionDashboardReducer,
        fsmDashboard: fsmDashboardReducer,
        fsmTableBankCode: fsmTableBankCodeReducer,
        fsmTableRecord: fsmTableRecordReducer,
        exceptionSystems: exceptionSystemsReducer,
        exceptionSystemExceptions: exceptionSystemExceptionsReducer,
        exceptionSettingsSms: exceptionSettingsSmsReducer,
        exceptionSettingsMail: exceptionSettingsMailReducer,
        exceptionSettingsNotification: exceptionSettingsNotificationReducer,
        fsmPendingJob: fsmPendingJobReducer,
        fsmPreviewInfo: fsmPreviewInfoReducer,
        fsmDetailPreview: fsmDetailPreviewReducer,
        tempLogin: tempLoginReducer,
        charts:chartSliceReducer,
        fsmReport: fsmReportSliceReducer,
        fsmXLSReport: fsmXLSReportSliceReducer,
        fsmProductReview: fsmProductReviewReducer,
        fsmOneProductReview: fsmOneProductReviewReducer,
        fsmManualJob: fsmManualJobReducer,
        fsmFollowupEmails: fsmFollowupEmailReducer,
        fsmFollowupEmailDetails: fsmFollowupEmailDetailsReducer,
        fsmFollowupEmailDetails: fsmFollowupEmailDetailsReducer,
        fsmManualJobTest: fsmManualJobTestReducer,

        fsmSreSummary: fsmSreSummaryReducer,
        fsmMachPartsHistory: fsmMachPartsHistoryReducer
    }
});
import React from "react";

export default function useEffectNoMount (action, dependencies = undefined) {
    const isMounted = React.useRef(false);

    React.useEffect(() => {
        if (isMounted.current)
        {
            if (action !== undefined) action();
        }
        else
        {
            setTimeout(() => {
                isMounted.current = true;
            });
        }
    }, [isMounted.current].concat(dependencies).filter(d => d !== undefined));
}

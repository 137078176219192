import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType } from "../../Utils";

const sliceName = "meeting";

const fetchNextMeeting = createAsyncThunk('getMeeting', async (roomId) => {
    const resp = await APICall(BackendType.RoomBooking, `bookroom/getMeeting?room=${roomId}`);
    return resp.response;
});

export const meetingSlice = createSlice({
    name: sliceName,
    initialState: {
        fetching: false,
        meeting: {}
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchNextMeeting.pending, (state) => {
            state.fetching = true;
        })
        .addCase(fetchNextMeeting.fulfilled, (state, action) => {
            state.meeting = action.payload?.data ?? {};
            state.fetching = false;
        })
        .addCase(fetchNextMeeting.rejected, (state, action) => {
            state.fetching = false;
            state.meeting = {};
        })
    }
});

const selectMeetingFetching = (state) => {
    return state[sliceName].fetching;
}

const selectNextMeeting = (state) => {
    return state[sliceName].meeting;
}

export { fetchNextMeeting, selectMeetingFetching, selectNextMeeting };
export default meetingSlice.reducer;
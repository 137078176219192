import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchFsmTableBankCode = createAsyncThunk("fsmTableBankCode", async ({table}) => {
  return await APICall(BackendType.MnC, `fsm/bankCodes/${table}`);
});

export const fsmTableBankCodeSlice = createSlice({
  name: Slices.FsmTableBankCode,
  initialState: DefaultThunkState(),
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmTableBankCode.pending, PendingThunk())
      .addCase(fetchFsmTableBankCode.fulfilled, FulfilledThunk())
      .addCase(fetchFsmTableBankCode.rejected, FulfilledThunk());
  },
});

const selectFSMTableBankCodes = createSelector(
  [ state => state[Slices.FsmTableBankCode].data ], (d) => d
);

const selectFSMTableBankCodesFetching = createSelector(
  [ state => state[Slices.FsmTableBankCode].fetching ], (d) => d
);

export { fetchFsmTableBankCode, selectFSMTableBankCodes, selectFSMTableBankCodesFetching };
export default fsmTableBankCodeSlice.reducer;

import { FormControl, MenuItem, Select } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import React from "react";

// valueOptions always expects [{Id: , Label: }]
export default function EditColumnSelect({id, value, field, hasFocus, multiple, valueOptions, renderCell}) {
    const apiRef = useGridApiContext();
    const ref = React.useRef();

    React.useLayoutEffect(() => {
        if (hasFocus) ref.current.focus();
    }, [hasFocus]);

    const OnValueChanged = (e) => {
        const newVal = Array.isArray(e.target.value) === true
            ? e.target.value.sort()
            : e.target.value;

        apiRef.current.setEditCellValue({ id, field, value: newVal });
    }

    return (
        <FormControl fullWidth size={"small"}>
            <Select ref={ref} multiple={multiple ?? false} value={value} onChange={OnValueChanged} renderValue={renderCell}>
                {
                    valueOptions.map((v) => {
                        return (
                            <MenuItem key={`edit-row-${id}-${field}-${v.Id}`} value={v.Id}>{v.Label}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}
import { Button, Stack, darken, styled } from "@mui/material";

const Btn = styled(Button, { shouldForwardProp: (props) => props !== "color"})(({ theme, color }) => `
    background: ${color ?? "primary"};

    &:hover {
        background: ${darken(color ?? theme.palette.primary.main, 0.2)};
    }
`);

export default function ContainedButton({children, onClick, borderColor, background, icon, size}) {
    let sx = {
        border: `1px solid ${borderColor}`,
    }

    if (icon !== null && icon !== undefined)
    {
        sx["paddingLeft"] = 1;
    }

    return (
        <Btn color={background} sx={sx} variant={"contained"} onClick={onClick} size={size ?? "medium"}>
            <Stack spacing={1} direction={"row"} alignItems={"center"}>
                {icon}
                <span>
                    {children}
                </span>
            </Stack>
        </Btn>
    )
}
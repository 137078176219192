import React from "react";
import AppContainer from "../AppContainer";
import NewTableBox from "../NewTableBox";
import { Button, Divider, Stack } from "@mui/material";
import CollapseWrap from "../CollapseWrap";
import MonitoringSearchCriteria, { MonitoringSearchCheckbox, MonitoringSearchDate, MonitoringSearchInput, MonitoringSearchRow, MonitoringSearchText } from "../MonitoringSearchCriteria";
import { addDays, format } from "date-fns";
import ChipStack from "../ChipStack";
import NewTable from "../NewTable";
import { fetchFsmSreSummary, selectFSMSreSummary, selectFSMSreSummaryFetching } from "../../redux/slices/fsmSreSummarySlice";
import { DefaultTableCellStyle } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { SanitizeInput } from "../../Utils";

const SearchGridSizes = [6, 4, 2];
const MobileSearchGridSizes = [6, 6];

const TableStyle = {
    padding: "8px",
    align: "center"
};

const GenerateTableFields = (labels) => {
    return labels.map((l) => {
        return { label: l, ...TableStyle };
    });
}

const Columns = [
    { ...DefaultTableCellStyle, label: 'Resolved', sortable: true },
    { ...DefaultTableCellStyle, label: 'Reviewed', sortable: true },
    { ...DefaultTableCellStyle, label: 'SRE ID', sortable: true },
    { ...DefaultTableCellStyle, label: 'Job ID', sortable: true },
    { ...DefaultTableCellStyle, label: 'Job Type', sortable: true },
    { ...DefaultTableCellStyle, label: 'Job Received Time',  sortable: true, type: Date, dateFormat: "ddMMMyyyy hh:mm:ss a" },
    { ...DefaultTableCellStyle, label: 'Job Start Date/Time',  sortable: true, type: Date, dateFormat: "ddMMMyyyy hh:mm:ss a" },
    { ...DefaultTableCellStyle, label: 'Job End Date/Time',  sortable: true, type: Date, dateFormat: "ddMMMyyyy hh:mm:ss a" },
    { ...DefaultTableCellStyle, label: 'Kiosk ID', sortable: true },
    { ...DefaultTableCellStyle, label: 'Branch Name', sortable: true },
    { ...DefaultTableCellStyle, label: 'Reported Job Issue', sortable: true },
    { ...DefaultTableCellStyle, label: 'CE Name', sortable: true },
];

export default function FSMSreSummaryList({ isMobile }) {
    const dispatch = useDispatch();

    const [gridSize, setGridSize] = React.useState(SearchGridSizes);

    const [startDate, setStartDate] = React.useState(new Date()); 
    const [endDate, setEndDate] = React.useState(new Date());
    const [sreId, setSreId] = React.useState("");

    const [showFilters, setShowFilters] = React.useState(false);

    const [rows, setRows] = React.useState([]);

    const [showUnresolved, setShowUnresolved] = React.useState(false);
    const [showUnreviewed, setShowUnreviewed] = React.useState(false);

    const data = useSelector(selectFSMSreSummary);
    const dataFetching = useSelector(selectFSMSreSummaryFetching);

    React.useEffect(() => {
        let _rows = [];

        data.forEach((d) => {
            _rows.push(
                GenerateTableFields([d.Resolved, d.Reviewed, d.SreId, d.JobId, d.JobType, d.JobReceivedDt, d.JobStartDt, d.JobEndDt, d.KioskId, d.BranchName, d.ReportedJobIssue, d.CEName])
            )
        });

        setRows(_rows);
    }, [data]);

    React.useEffect(() => {
        setGridSize(isMobile === true ? MobileSearchGridSizes : SearchGridSizes);
    }, [isMobile]);

    const ShowFilter = (val) => () => {
        setShowFilters(val);
    }

    const GetDateFilter = () => {
        return [`${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}`];
    }

    const OnSubmit = () => {
        const payload = {
            startDate: startDate,
            endDate: endDate,
            sreId: SanitizeInput(sreId),
            unresolved: showUnresolved,
            unreviewed: showUnreviewed
        };

        dispatch(fetchFsmSreSummary(payload));
    }

    return (
        <>
            <AppContainer>
                <NewTableBox title={"SRE Summary"} height={"100%"}>
                    <Stack spacing={1} height={"100%"}>
                        <CollapseWrap defaultShow onShow={ShowFilter(false)} onHide={ShowFilter(true)}>
                            <MonitoringSearchCriteria isMobile={isMobile}>
                                <MonitoringSearchRow gridSizes={gridSize}>
                                    <MonitoringSearchDate label={"Date Range"} dateLabel={"Start Date"} value={startDate} setValue={setStartDate} />
                                    <MonitoringSearchDate value={endDate} dateLabel={"End Date"} setValue={setEndDate} maxDate={addDays(startDate, 30)} />
                                    {
                                        isMobile !== true &&
                                        <MonitoringSearchText color={"blue"} fontStyle={"italic"}>*limit up to 30 days only!</MonitoringSearchText>
                                    }
                                </MonitoringSearchRow>
                                <MonitoringSearchRow gridSizes={gridSize}>
                                    <MonitoringSearchInput label={"SRE ID"} value={sreId} setValue={setSreId} />
                                    <Button fullWidth variant={"contained"} onClick={OnSubmit}>Submit</Button>
                                </MonitoringSearchRow>
                                <MonitoringSearchRow gridSizes={gridSize}>
                                    <MonitoringSearchCheckbox label={"Unresolved"} value={showUnresolved} setValue={setShowUnresolved} padStart />
                                    <MonitoringSearchCheckbox label={"Unreviewed"} value={showUnreviewed} setValue={setShowUnreviewed} />
                                </MonitoringSearchRow>
                            </MonitoringSearchCriteria>
                        </CollapseWrap>
                        {
                            showFilters === true &&
                            <ChipStack direction={"row"} chips={GetDateFilter()} size={"small"} />
                        }
                        <Divider />
                        <NewTable columns={Columns} rows={rows} pageSizeOptions={[10, 20, 30, 40, 50]} loading={dataFetching} autoHeight />
                    </Stack>
                </NewTableBox>
            </AppContainer>
        </>
    )
}
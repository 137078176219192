import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchFsmFollowUpEmailDetails = createAsyncThunk("fsmFollowupEmailDetails", async (sreid) => {
  return await APICall(BackendType.MnC, `fsm/followupEmailDetails?sreid=${sreid}`);
});

const PendingAction = (state, action) => {
  PendingThunk()(state, action);
  state.sreId = ""; 
}

const FulfilledAction = (state, action) => {
  FulfilledThunk()(state, action);
}

export const fsmFollowupDetailsSlice = createSlice({
  name: Slices.FsmFollowupEmailDetails,
  initialState: {
    ...DefaultThunkState(),
    sreId: ""
  },
  reducers: {
      setFollowupDetailSreId: (state, action) => {
          state.sreId = action.payload;
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmFollowUpEmailDetails.pending, PendingThunk())
      .addCase(fetchFsmFollowUpEmailDetails.fulfilled, FulfilledThunk())
      .addCase(fetchFsmFollowUpEmailDetails.rejected, FulfilledThunk())
  },
});

const selectFsmFollowupEmailDetailsFetching = createSelector(
  [ state => state[Slices.FsmFollowupEmailDetails].fetching ], (d) => d
);

const selectFsmFollowupEmailDetailsData = createSelector(
  [ state => state[Slices.FsmFollowupEmailDetails].data ], (d) => d
);

const selectFsmFollowupEmailDetailsSreId = createSelector(
  [ state => state[Slices.FsmFollowupEmailDetails].sreId ], (d) => d
)

export { fetchFsmFollowUpEmailDetails, selectFsmFollowupEmailDetailsFetching, selectFsmFollowupEmailDetailsData, selectFsmFollowupEmailDetailsSreId };
export const { setFollowupDetailSreId } = fsmFollowupDetailsSlice.actions;
export default fsmFollowupDetailsSlice.reducer;

import React from "react";
import AppContainer from "../AppContainer";
import NewTableBox from "../NewTableBox";
import { Button, Divider, Stack } from "@mui/material";
import CollapseWrap from "../CollapseWrap";
import MonitoringSearchCriteria, { MonitoringSearchCheckbox, MonitoringSearchDate, MonitoringSearchInput, MonitoringSearchRow, MonitoringSearchText } from "../MonitoringSearchCriteria";
import { addYears, format, subYears } from "date-fns";
import ChipStack from "../ChipStack";
import NewTable from "../NewTable";
import { DefaultTableCellStyle } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { SanitizeInput } from "../../Utils";
import { fetchFsmMachPartsHistory, selectFSMMachPartsHistory, selectFSMMachPartsHistoryFetching } from "../../redux/slices/fsmMachPartsHistorySlice";

const SearchGridSizes = [6, 4, 2];
const MobileSearchGridSizes = [6, 6];

const TableStyle = {
    padding: "8px",
    align: "center"
};

const GenerateTableFields = (labels) => {
    return labels.map((l) => {
        return { label: l, ...TableStyle };
    });
}

const Columns = [
    { ...DefaultTableCellStyle, label: 'Date', sortable: true, type: Date, dateFormat: "ddMMMyyyy hh:mm:ss a" },
    { ...DefaultTableCellStyle, label: 'Part Number', sortable: true },
    { ...DefaultTableCellStyle, label: 'Part Description', sortable: true },
    { ...DefaultTableCellStyle, label: 'Quantity', sortable: true },
    { ...DefaultTableCellStyle, label: 'Job #', sortable: true },
    { ...DefaultTableCellStyle, label: 'Service Form #', sortable: true },
    { ...DefaultTableCellStyle, label: 'Serial #', sortable: true },
    { ...DefaultTableCellStyle, label: 'Kiosk ID', sortable: true },
    { ...DefaultTableCellStyle, label: 'CE', sortable: true }
];

export default function FSMMachPartsHistoryList({ isMobile }) {
    const dispatch = useDispatch();

    const [gridSize, setGridSize] = React.useState(SearchGridSizes);

    const [startDate, setStartDate] = React.useState(subYears(new Date(), 1)); 
    const [endDate, setEndDate] = React.useState(new Date());
    const [machineSerial, setMachineSerial] = React.useState("");
    const [partNo, setPartNo] = React.useState("");

    const [showFilters, setShowFilters] = React.useState(false);

    const [rows, setRows] = React.useState([]);

    const data = useSelector(selectFSMMachPartsHistory);
    const dataFetching = useSelector(selectFSMMachPartsHistoryFetching);

    React.useEffect(() => {
        let _rows = [];

        data.forEach((d) => {
            _rows.push(
                GenerateTableFields([d.Date, d.PartNo, d.PartDesc, d.Quantity, d.JobNo, d.ServiceFormNo, d.SerialNo, d.KioskId, d.CE])
            )
        });

        setRows(_rows);
    }, [data]);

    React.useEffect(() => {
        setGridSize(isMobile === true ? MobileSearchGridSizes : SearchGridSizes);
    }, [isMobile]);

    const ShowFilter = (val) => () => {
        setShowFilters(val);
    }

    const GetFilters = () => {
        let filters = [`${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}`];

        if (machineSerial.length > 0) filters.push(machineSerial);
        if (partNo.length > 0) filters.push(partNo);

        return filters;
    }

    const OnSubmit = () => {
        const payload = {
            startDate: startDate,
            endDate: endDate,
            machineSerial: SanitizeInput(machineSerial),
            partNo: SanitizeInput(partNo),
        };

        dispatch(fetchFsmMachPartsHistory(payload));
    }

    return (
        <>
            <AppContainer>
                <NewTableBox title={"Machine Parts History"} height={"100%"}>
                    <Stack spacing={1} height={"100%"}>
                        <CollapseWrap defaultShow onShow={ShowFilter(false)} onHide={ShowFilter(true)}>
                            <MonitoringSearchCriteria isMobile={isMobile}>
                                <MonitoringSearchRow gridSizes={gridSize}>
                                    <MonitoringSearchDate label={"Date Range"} dateLabel={"Start Date"} value={startDate} setValue={setStartDate} />
                                    <MonitoringSearchDate value={endDate} dateLabel={"End Date"} setValue={setEndDate} maxDate={addYears(startDate, 1)} />
                                    {
                                        isMobile !== true &&
                                        <MonitoringSearchText color={"blue"} fontStyle={"italic"}>*limit up to 1 year only!</MonitoringSearchText>
                                    }
                                </MonitoringSearchRow>
                                <MonitoringSearchRow gridSizes={gridSize}>
                                    <MonitoringSearchInput label={"Machine Serial No"} value={machineSerial} setValue={setMachineSerial} />
                                </MonitoringSearchRow>
                                <MonitoringSearchRow gridSizes={gridSize}>
                                    <MonitoringSearchInput label={"Part No"} value={partNo} setValue={setPartNo} />
                                    <Button fullWidth variant={"contained"} onClick={OnSubmit}>Submit</Button>
                                </MonitoringSearchRow>
                            </MonitoringSearchCriteria>
                        </CollapseWrap>
                        {
                            showFilters === true &&
                            <ChipStack direction={"row"} chips={GetFilters()} size={"small"} />
                        }
                        <Divider />
                        <NewTable columns={Columns} rows={rows} pageSizeOptions={[10, 20, 30, 40, 50]} loading={dataFetching} autoHeight />
                    </Stack>
                </NewTableBox>
            </AppContainer>
        </>
    )
}
import styled from "@emotion/styled";
import { ArrowCircleDown, ArrowCircleUp } from "@mui/icons-material";
import { Box, Collapse, IconButton } from "@mui/material";
import React from "react";

const StyledCollapse = styled(Collapse)`
    &.MuiCollapse-entered {
        min-height: auto !important;
    }
`;

export default function CollapseWrap ({defaultShow, onShow, onHide, children}) {
    const [show, setShow] = React.useState(defaultShow);

    const OnCollapseClick = () => {
        setShow(!show);
    }

    React.useEffect(() => {
        if (show === true)
        {
            if (onShow !== null && onShow !== undefined) onShow();
        }
        else
        {
            if (onHide != null && onHide !== undefined) onHide();
        }
    }, [show]);

    return (
        <>
            <StyledCollapse in={show}>
                {children}
            </StyledCollapse>
            <Box display={"flex"} justifyContent={"center"} width={"100%"}>
                <IconButton sx={{color: 'black'}} size={"small"} onClick={OnCollapseClick}>
                    {
                        show === true &&
                        <ArrowCircleUp />
                    }
                    {
                        show !== true &&
                        <ArrowCircleDown />
                    }
                </IconButton>
            </Box>
        </>
    )
}
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";
import { format } from "date-fns";

const fetchFsmSreSummary = createAsyncThunk("fsmSreSummary", async ({startDate, endDate, sreId, unresolved, unreviewed}) => {
  let url = `fsm/sreSummary?startDt=${format(startDate, "yyyy-MM-dd")}&endDt=${format(endDate, "yyyy-MM-dd")}&unresolved=${unresolved}&unreviewed=${unreviewed}`;

  if (sreId.length > 0) {
    url += `&sreId=${sreId}`;
  }

  return await APICall(BackendType.MnC, url);
});

export const fsmSreSummarySlice = createSlice({
  name: Slices.FsmSreSummary,
  initialState: DefaultThunkState(),
  reducers: {
      clearFsmSreSummaryData: (state, action) => {
          state.data = [];
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmSreSummary.pending, PendingThunk())
      .addCase(fetchFsmSreSummary.fulfilled, FulfilledThunk())
      .addCase(fetchFsmSreSummary.rejected, FulfilledThunk());
  },
});

const selectFSMSreSummary = createSelector(
  [ state => state[Slices.FsmSreSummary].data ], (d) => d
);

const selectFSMSreSummaryFetching = createSelector(
  [ state => state[Slices.FsmSreSummary].fetching ], (d) => d
);

export { fetchFsmSreSummary, selectFSMSreSummary, selectFSMSreSummaryFetching };
export const { clearFsmSreSummaryData } = fsmSreSummarySlice.actions;
export default fsmSreSummarySlice.reducer;

import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchFsmDashboard = createAsyncThunk("fsmDashboard", async (bank) => {
  return await APICall(BackendType.MnC, `fsm/dashboard?bank=${bank}`);
});

export const fsmDashboardSlice = createSlice({
  name: Slices.FsmDashboard,
  initialState: DefaultThunkState(),
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmDashboard.pending, PendingThunk())
      .addCase(fetchFsmDashboard.fulfilled, FulfilledThunk())
      .addCase(fetchFsmDashboard.rejected, FulfilledThunk());
  },
});

const selectFsmDashboardFetching = createSelector(
  [ state => state[Slices.FsmDashboard].fetching ], (d) => d
);

const selectFsmDashboardData = createSelector(
  [ state => state[Slices.FsmDashboard].data ], (d) => d
);

export { fetchFsmDashboard, selectFsmDashboardFetching, selectFsmDashboardData };
export default fsmDashboardSlice.reducer;

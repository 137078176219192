import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import "./App.css";
import "./open-sans.css";
import "./sodo-sans.css";
import BookingPage from "./Components/bookroom/BookingPage";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Home from "./Components/home/Home";
import AppNavBar from "./Components/AppNavBar";
import ExceptionDashboard from "./Components/exception/ExceptionDashboard";
import ExceptionList from "./Components/exception/ExceptionList";
import ExceptionNotifications from "./Components/exception/ExceptionNotifications";
import ExceptionSettings from "./Components/exception/ExceptionSettings";
import FSMTableReports from "./Components/fsm/FSMTableReports";
import { NavPaths } from "./constants";
import React, { useRef } from "react";
import { GetCookie } from "./Utils";
import { useDispatch, useSelector } from "react-redux";
import { extendExpiration, logoutUser, selectExpiresIn, selectHasShownTimeout, selectShowTimeout, selectUsername, setHasShownTimeout, setLoginIntervalId, setShowTimeout, setUsername } from "./redux/slices/tempLoginSlice";
import Login from "./Components/home/Login";
import FSMServiceCallReport from "./Components/fsm/FSMServiceCallReport";
import SessionTimeoutModal from "./Components/home/SessionTimeoutModal";
import { differenceInSeconds } from "date-fns";
import FSMManualJobTicket from "./Components/fsm/FSMManualJobTicket";
import { closeSnackbar } from "./redux/slices/appSlice";
import FSMInventoryReport from "./Components/fsm/FSMInventoryReport";
import MachineInfoReport from "./Components/fsm/MachineInfoReport";
import FSMManualJobTicketTest from "./Components/fsm/FSMManualJobTicketTest";
import FSMSreSummaryList from "./Components/fsm/FSMSreSummaryList";
import FSMMachPartsHistoryList from "./Components/fsm/FSMMachPartsHistoryList";

const AppBox = styled(Box, {
  shouldForwardProp: (props) => props !== "isMobile",
})(
  ({ isMobile }) => `

  height: 100%;
  overflow: ${isMobile === true ? "auto" : "hidden"};
`
);

const RouterBox = React.memo(function RouterBox({username}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const appBarRef = useRef();

  const [appBarHeight, setAppBarHeight] = React.useState(0);

  React.useLayoutEffect(() => {
    if (appBarRef.current)
    {
      setAppBarHeight(appBarRef.current.offsetHeight);
    }
  }, [isMobile]);

  const OnInteractionResetTimeout = () => {
    if (username !== undefined)
    {
      dispatch(extendExpiration());
    }
  }
  
  return (
    <AppBox isMobile={isMobile} onMouseDown={OnInteractionResetTimeout} onKeyDown={OnInteractionResetTimeout}>
      <BrowserRouter>
        <Box ref={appBarRef}>
          <AppNavBar isMobile={isMobile} />
        </Box>
        <Box pt={2} pb={2} height={`calc(100% - ${theme.spacing(4)} - ${appBarHeight}px)`} overflow={"auto"}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path={`/${NavPaths.BookRoom}`} element={<BookingPage isMobile={isMobile} />} />
            <Route path={`/${NavPaths.FSMTables}`} element={<TempLoginCheck page={<FSMTableReports isMobile={isMobile} />} />} />
            <Route path={`/${NavPaths.ExceptionDashboard}`} element={<TempLoginCheck page={<ExceptionDashboard isMobile={isMobile} />} />} />
            <Route path={`/${NavPaths.ExceptionList}`} element={<TempLoginCheck page={<ExceptionList isMobile={isMobile} />} />} />
            <Route path={`/${NavPaths.Login}`} element={<Login />} />

            <Route path={`/${NavPaths.FSMReport}` } element={<TempLoginCheck page={<FSMServiceCallReport isMobile={isMobile} />} />} />
            <Route path={`/${NavPaths.FSMInventoryReport}` } element={<TempLoginCheck page={<FSMInventoryReport isMobile={isMobile} />} />} />
            {/* <Route path={`/${NavPaths.MachInfoReport}` } element={<TempLoginCheck page={<MachineInfoReport isMobile={isMobile} />} />} /> */}

            <Route path={`/${NavPaths.FSMManualJob}` } element={<TempLoginCheck page={<FSMManualJobTicket isMobile={isMobile} />} />} />
            <Route path={`/${NavPaths.FSMManualJobTest}` } element={<TempLoginCheck page={<FSMManualJobTicketTest isMobile={isMobile} />} />} />
            
            <Route path={`/${NavPaths.FSMSreSummary}` } element={<TempLoginCheck page={<FSMSreSummaryList isMobile={isMobile} />} />} />
            <Route path={`/${NavPaths.FSMMachPartsHistory}` } element={<TempLoginCheck page={<FSMMachPartsHistoryList isMobile={isMobile} />} />} />
            {/* <Route path="/exception/notifications" element={<ExceptionNotifications isMobile={isMobile} />} />
            <Route path="/exception/settings" element={<ExceptionSettings isMobile={isMobile} />} /> */}
          </Routes>
        </Box>
      </BrowserRouter>
    </AppBox>
  )
});

const TempLoginCheck = ({page}) => {
  const navigate = useNavigate();

  const username = useSelector(selectUsername);

  React.useEffect(() => {
    if (username === undefined)
    {
      if (window.location.pathname !== `/${NavPaths.Login}`)
      {
        navigate(`/${NavPaths.Login}?redirect=${window.location.pathname}`);
      }
    }
  }, [username]);

  return (
    <>
      {
        username !== undefined &&
        page
      }
    </>
  )
}

function App() {
  const dispatch = useDispatch();

  const [secDiff, setSecDiff] = React.useState(0);

  const username = useSelector(selectUsername);
  const showTimeout = useSelector(selectShowTimeout);
  const expires_in = useSelector(selectExpiresIn);
  const setHasShownTimeout = useSelector(selectHasShownTimeout);

  React.useEffect(() => {
    let firstAuth = GetCookie("localAuth");
    if (firstAuth !== undefined)
    {
      dispatch(setUsername(firstAuth));
    }

    if (username !== undefined)
    {
      let intId = window.setInterval(() => {
        if (GetCookie("localAuth") === undefined && username !== undefined)
        {
          dispatch(logoutUser());
        }
  
        if (setHasShownTimeout !== true && username !== undefined)
        {
          let _secDiff = differenceInSeconds(new Date(expires_in), new Date());
          setSecDiff(_secDiff);
          if (_secDiff <= 30 && showTimeout !== true)
          {
            dispatch(setShowTimeout(true));
          }
        }
      }, 1 * 1000);
  
      dispatch(setLoginIntervalId(intId));
    }
    else
    {
      dispatch(closeSnackbar());
    }
  }, [username, showTimeout, expires_in, setHasShownTimeout]);

  const OnSessionTimeoutClose = () => {
    dispatch(setShowTimeout(false))
  }

  return (
    <>
      <RouterBox username={username} />
      <SessionTimeoutModal open={Boolean(showTimeout)} onClose={OnSessionTimeoutClose} seconds={secDiff} />
    </>
  );
}
export default App;

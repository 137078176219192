import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType } from "../../Utils";
import { Slices } from "../../constants";

const fetchFsmXLSReport = createAsyncThunk("fsmXLSReport", async ({bankName, dateType, startDate, endDate}) => {
  let url = `fsm/reportExcel?bank=${bankName}&dateType=${dateType}&startDate=${startDate}&endDate=${endDate}`;
  return await APICall(BackendType.MnC, url, "GET", {}, 'blob');
});

const fetchFsmXLSInventoryReport = createAsyncThunk("fsmXLSReport/inventory", async ({bankName, dateType, startDate, endDate}) => {
  let url = `fsm/reportExcel/inventory?bank=${bankName}&dateType=${dateType}&startDate=${startDate}&endDate=${endDate}`;
  return await APICall(BackendType.MnC, url, "GET", {}, 'blob');
});

const Fulfilled = (state, action) => {
  if (action.payload?.response === undefined)
  {
    state.data = undefined;
  }
  else
  {
    let file = new Blob([action.payload.response], { type: 'application/octet-stream' });
    state.data = {
      url: URL.createObjectURL(file),
      filename: action.payload.headers["content-disposition"].split(";").map((x) => x.trim()).find((x) => x.startsWith("filename=")).split("=")[1]
    }
  }
  
  state.fetching = false; 
}

export const fsmXLSReportSlice = createSlice({
  name: Slices.FsmXLSReport ,
  initialState: {
    data: undefined,
    fetching: false
  },
  reducers: {
      clearFsmXLSReportData: (state, action) => {
          state.data = undefined;
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmXLSReport.pending, (state, action) => { state.data = undefined; state.fetching = true; })
      .addCase(fetchFsmXLSReport.fulfilled, Fulfilled)
      .addCase(fetchFsmXLSReport.rejected, (state, action) => { state.data = undefined; state.fetching = false; })

      .addCase(fetchFsmXLSInventoryReport.pending, (state, action) => { state.data = undefined; state.fetching = true; })
      .addCase(fetchFsmXLSInventoryReport.fulfilled, Fulfilled)
      .addCase(fetchFsmXLSInventoryReport.rejected, (state, action) => { state.data = undefined; state.fetching = false; });
  },
});

const selectFsmXLSReport = createSelector(
  [ state => state[Slices.FsmXLSReport ].data ], (d) => d
);

const selectFsmXLSReportFetching = createSelector(
  [ state => state[Slices.FsmXLSReport ].fetching ], (d) => d
);

export { fetchFsmXLSReport, fetchFsmXLSInventoryReport, selectFsmXLSReport, selectFsmXLSReportFetching };
export const { clearFsmXLSReportData } = fsmXLSReportSlice.actions;
export default fsmXLSReportSlice.reducer;

import React from "react"
import { addDays, endOfMonth, format, startOfMonth } from "date-fns"
import { fetchFsmInventoryReport, selectFSMReport } from "../../redux/slices/fsmReportSlice"
import { fetchFsmXLSInventoryReport } from "../../redux/slices/fsmXLSReportSlice"
import { useSelector } from "react-redux"
import { MonitoringSearchDate, MonitoringSearchRow, MonitoringSearchSelect, MonitoringSearchText } from "../MonitoringSearchCriteria"
import FSMReportBox from "./FSMReportBox"

const TableStyles = {
    padding: "8px",
    align: "center"
};

const ColumnStyles = {
    ...TableStyles,
    sortable: true
};

const GenerateTableFields = (labels) => {
    return labels.map((l) => {
        return { label: l, ...TableStyles };
    });
}

const Columns = [
    { ...ColumnStyles, label: "Job No" },
    { ...ColumnStyles, label: "Customer" },
    { ...ColumnStyles, label: "Report No" },
    { ...ColumnStyles, label: "Internal Doc" },
    { ...ColumnStyles, label: "Arrival Date" },
    { ...ColumnStyles, label: "Review Date" },
    { ...ColumnStyles, label: "Machine Id" },
    { ...ColumnStyles, label: "Serial No" },
    { ...ColumnStyles, label: "Machine Id-Serial No" },
    { ...ColumnStyles, label: "Part No" },
    { ...ColumnStyles, label: "Part Description" },
    { ...ColumnStyles, label: "Quantity" },
    { ...ColumnStyles, label: "Chargeable" }
];

const BankName = {
    "ALL": "All",
    "HSBC": "004",
    "HASE": "024",
    "BOC": "012",
    "NCB": "043",
    "CYB": "039"  
  };

const SelectBankName = Object.entries(BankName).map(([k, v]) => {
    return {
        label: k,
        value: v
    }
});

const DateType = {
    "Arrival Date": "Arrival",
    "Review Date": "Review",
};

const SelectDateType = Object.entries(DateType).map(([k, v]) => {
    return {
        label: k,
        value: v
    }
});

const SearchGridSizes = [6, 4, 2];
const MobileSearchGridSizes = [6, 6];

export default function FSMInventoryReport({isMobile}) {
    const [startDate, setStartDate] = React.useState(startOfMonth(new Date())); 
    const [endDate, setEndDate] = React.useState(endOfMonth(new Date())); 
    const [bank, setBank] = React.useState(Object.values(BankName)[0]);
    const [dateType, setDateType] = React.useState(Object.values(DateType)[0]); 

    const [rows, setRows] = React.useState([]);
    const [gridSize, setGridSize] = React.useState(SearchGridSizes);

    const [filterChips, setFilterChips] = React.useState([]);

    const data = useSelector(selectFSMReport);

    React.useEffect(() => {
        setGridSize(isMobile === true ? MobileSearchGridSizes : SearchGridSizes);
    }, [isMobile]);

    React.useEffect(() => {
        const _rows = data.map((d) => {
            return GenerateTableFields([
                d.JobNo, d.Customer, d.ReportNo, d.IntDoc, d.ArrivalDate, d.ReviewDate, d.MachineId, d.SerialNo, d.MachineIdSerialNo, d.PartNo, d.PartDesc, d.Quantity, d.Chargeable
            ]);
        });

        setRows(_rows);
    }, [data]);

    React.useEffect(() => {
        let _stack = [];

        _stack.push(SelectBankName.find(x => x.value === bank).label);
        _stack.push(SelectDateType.find(x => x.value === dateType).label);
        _stack.push(`${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}`);

        setFilterChips(_stack);
    }, [bank, dateType, startDate, endDate]);

    const GetSelectors = () => {
        const payload = {
            bankName: bank,
            dateType: dateType,
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd"),
        };

        return {
            fetch: fetchFsmInventoryReport(payload),
            fetchExcel: fetchFsmXLSInventoryReport(payload)
        };
    }

    return (
        <FSMReportBox title={"FSM Inventory Report List"} columns={Columns} rows={rows} filterChips={filterChips} selectors={GetSelectors()} isMobile={isMobile}>
            <MonitoringSearchRow gridSizes={isMobile === true ? [12] : [6]}>
                <MonitoringSearchSelect label={"Bank Name"} value={bank} setValue={setBank} items={SelectBankName} />
            </MonitoringSearchRow>
            <MonitoringSearchRow gridSizes={isMobile === true ? [12] : [6]}>
                <MonitoringSearchSelect label={"Date Type"} value={dateType} setValue={setDateType} items={SelectDateType} />
            </MonitoringSearchRow>
            <MonitoringSearchRow gridSizes={gridSize}>
                <MonitoringSearchDate label={"Date Range"} dateLabel={"Start Date"} value={startDate} setValue={setStartDate} />
                <MonitoringSearchDate dateLabel={"End Date"} value={endDate} setValue={setEndDate} maxDate={addDays(startDate, 30)} />
                <MonitoringSearchText color={"blue"} fontStyle={"italic"} >*limit up to 30 days only!</MonitoringSearchText>
            </MonitoringSearchRow>
        </FSMReportBox>
    )
}
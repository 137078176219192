import { Refresh } from "@mui/icons-material";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useTime } from "react-timer-hook";
import InfoTooltip from "./InfoTooltip";
import { Classes } from "../constants";

const DashboardTableTimer = ({refreshAction, resetTimer, maxSeconds = 30}) => {
    const { seconds } = useTime();

    const [timer, setTimer] = React.useState(maxSeconds);
    const [lastUpdated, setLastUpdated] = React.useState(new Date());

    const ResetTimer = () => {
        setTimer(maxSeconds);
        setLastUpdated(new Date());
    }

    const RefreshAction = () => {
        ResetTimer();
        refreshAction();
    }

    React.useEffect(() => {
        if (resetTimer !== true) return;
        ResetTimer();
    }, [resetTimer]);

    React.useEffect(() => {
        if (timer > 0)
        {
            setTimer(timer-1);
        }
        else
        {
            RefreshAction();
        }
    }, [seconds]);

    return (
        <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"baseline"}>
            <Typography className={Classes.opensans} variant={"body2"}>[Last Update: {format(lastUpdated, "hh:mm:ssa")}]</Typography>
            <Typography className={Classes.opensans} variant={"body2"}>
                Refreshing in {timer}&nbsp;
                <IconButton size={"small"} onClick={RefreshAction}>
                    <Refresh />
                </IconButton>
            </Typography>
        </Stack>
    )
}

export default function NewTableBox({title, children, background, height, width, refreshAction, resetTimer, fontSx, headerComponents, seconds = 30, tooltip = ""}) {
    return (
        <Paper sx={{border: "1px solid #C7C8CA", borderTopLeftRadius: 16, borderTopRightRadius: 16, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, height: "max-content", width: width ?? "100%", height: height ?? "max-content"}}>
            <Paper sx={{background: background ?? "#DBDBDB", borderTopLeftRadius: 16, borderTopRightRadius: 16, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, padding: 1}}>
                <Typography sx={fontSx}>
                    {title}
                    {
                        tooltip.length > 0 &&
                        <InfoTooltip title={tooltip} color={"rgba(0, 0, 0, 0.54)"} />
                    }
                    <span>
                        {
                            Array.isArray(headerComponents) === true &&
                            headerComponents.map(h => h)
                        }
                        {
                            Array.isArray(headerComponents) !== true &&
                            headerComponents
                        }
                    </span>
                </Typography>
            </Paper>
            <Box height={"calc(100% - 54px)"} p={1}>
                <Stack spacing={1} height={"100%"}>
                    {
                        refreshAction &&
                        <DashboardTableTimer refreshAction={refreshAction} resetTimer={resetTimer} maxSeconds={seconds} />
                    }
                    {children}
                </Stack>
            </Box>
        </Paper>
    )
}
import React from "react";

export default function useDownloadFile(data)
{
    React.useEffect(() => {
        if (data === undefined) return;
        if (data === null) return;

        if (data.url === undefined || data.url === null)
        {
            console.error("URL for download not present");
            return;
        }

        if (data.filename === undefined || data.filename === null)
        {
            console.error("Filename for download not present");
            return;
        }

        let a = document.createElement("a");

        a.href = data.url;
        a.download = data.filename;
        document.body.appendChild(a);
        a.click();

        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(data.url);
        }, 0);
    }, [data]);
}
import { Button, Divider, Stack, Typography, Tooltip, Chip, ClickAwayListener, styled, tooltipClasses } from "@mui/material";
import { DefaultTableCellStyle, ExceptionSeverity, SiteColors, Slices } from "../../constants";
import ContainedButton from "../ContainedButton";
import { AssignmentTurnedInOutlined, Timelapse } from "@mui/icons-material";
import React from "react";
import { FormatDateTime24Hour } from "../../Utils";
import AppContainer from "../AppContainer";
import useThunk from "../../CustomHooks/useThunk";
import { fetchExceptionSystems } from "../../redux/slices/exceptionSystemsSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearSystemExceptions, fetchExceptionSystemExceptions, resolveSystemException, selectSystemExceptionsData, selectSystemExceptionsFetching, selectSystemExceptionsRefetch } from "../../redux/slices/exceptionSystemExceptionsSlice";
import NewTableBox from "../NewTableBox";
import NewTable from "../NewTable";
import ConfirmationDialog from "../ConfirmationDialog";
import MonitoringSearchCriteria, { MonitoringSearchCheckbox, MonitoringSearchRow, MonitoringSearchSelect } from "../MonitoringSearchCriteria";
import CollapseWrap from "../CollapseWrap";
import ChipStack from "../ChipStack";

const ExceptionTypes = [
    { "label": "All" }, { "label": "Database" }, { "label": "File" }, { "label": "Mail" }, { "label": "Other" }
];
const GridSizes = [8, 4];

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        whiteSpace: "pre-wrap",
        overflowX: 'clip',
        overflowY: 'auto',
        maxHeight: '100px',
        fontFamily: "Open Sans",
        fontSize: "0.75rem",
        fontWeight: "530"
    }
}))

const ResolveButton = ({OnClick}) => {
    return (
        <ContainedButton background={SiteColors.Blue} borderColor={SiteColors.DarkBlue} icon={<AssignmentTurnedInOutlined fontSize={"small"} />} size={"small"} onClick={OnClick}>Resolve</ContainedButton>
    )
}

const ExTimestamp = ({timestamp, futureTimestamps}) => {
    const [open, setOpen] = React.useState(false);

    const OnClick = () => {
        setOpen(!open);
    }

    const OnClose = () => {
        setOpen(false);
    }

    return (
        <Typography>
            {FormatDateTime24Hour(timestamp)}
            {
                futureTimestamps.length > 0 &&
                <ClickAwayListener onClickAway={OnClose}>
                    <StyledTooltip title={`${futureTimestamps.map(x => FormatDateTime24Hour(x)).join("\r\n")}`} placement={"right"} open={open} arrow>
                        <Chip icon={<Timelapse />} label={"View Timestamps"} onClick={OnClick} color={"primary"} />
                    </StyledTooltip>
                </ClickAwayListener>
            }
        </Typography>
    )
}

const exceptionListColumns = [
    { ...DefaultTableCellStyle, label: 'Module', width: "120px", sortable: true },
    { ...DefaultTableCellStyle, label: 'Exception', sortable: true },
    { ...DefaultTableCellStyle, label: 'Exception Type', width: "100px", sortable: true },
    { ...DefaultTableCellStyle, label: 'Exception Time', width: "140px", sortable: true, type: Date, dateFormat: "dd/MM/yyyy HH:mm:ss" },
    { ...DefaultTableCellStyle, label: 'Resolution Time', width: "140px", sortable: true, type: Date, dateFormat: "dd/MM/yyyy HH:mm:ss" },
    { ...DefaultTableCellStyle, label: 'Action', width: "100px" }
]

export default function ExceptionList (props) {
    const { isMobile } = props;

    const dispatch = useDispatch();
    const { data: systems } = useThunk(Slices.ExceptionSystems, fetchExceptionSystems());
    const systemExceptions = useSelector(selectSystemExceptionsData);
    const fetchingExceptions = useSelector(selectSystemExceptionsFetching);
    const refetch = useSelector(selectSystemExceptionsRefetch);

    const [selectedSystem, setSelectedSystem] = React.useState(-1);
    const [selectedExceptionType, setSelectedExceptionType] = React.useState(ExceptionTypes[0].label);

    const [showUnresolved, setShowUnresolved] = React.useState(false);
    const [filteredRows, setFilteredRows] = React.useState([]);

    const [firstLoad, setFirstLoad] = React.useState(true);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [resolveId, setResolveId] = React.useState(-1);
    const [dialogMsg, setDialogMsg] = React.useState("");

    const [selectSystems, setSelectSystems] = React.useState([]);
    const [filterChips, setFilterChips] = React.useState([]);

    const OnCloseDialog = () => {
        setOpenDialog(false);
    }

    const OnOpenDialog = (id) => () => {
        const ex = systemExceptions.find((s) => s.ExcepId === id);

        setOpenDialog(true);
        setResolveId(id);
        setDialogMsg(`Are you sure you want to resolve the following exception?\r\n\r\n${ex.Module}\r\n${ex.Message}`);
    }

    const OnResolveConfirmed = () => {
        dispatch(resolveSystemException({id: resolveId}));
        OnCloseDialog();
    }

    React.useEffect(() => {
        return () => {
            dispatch(clearSystemExceptions());
        }
    }, []);

    React.useEffect(() => {
        if (systems.length <= 0) 
        {
            setSelectSystems([]);
            return;
        }

        let _sel = systems.map((s) => {
            return {
                label: s.Name,
                value: s.Id
            }
        });

        setSelectSystems(_sel);

        if (firstLoad === true)
        {
            setFirstLoad(false);

            const url = new URL(window.location);
            const filterSystem = url.searchParams.get("system");
            const filterExType = url.searchParams.get("exType");

            const fullFilter = [filterSystem, filterExType].every(x => x !== null && x.length > 0);

            setShowUnresolved(fullFilter);

            const paramSystem = systems.find(s => s.Name === filterSystem);
            const paramExType = ExceptionTypes.find(s => s.label === filterExType);

            if (paramSystem !== undefined && paramSystem !== null)
            {
                setSelectedSystem(paramSystem.Id);
            }
            else
            {
                setSelectedSystem(systems[0].Id);
            }

            if (paramExType !== undefined && paramExType !== null)
            {
                setSelectedExceptionType(paramExType.label);
            }
            else
            {
                setSelectedExceptionType("All");
            }

            if (fullFilter === true)
            {
                dispatch(fetchExceptionSystemExceptions({id: paramSystem.Id}));
            }
        }
    }, [systems]);

    React.useEffect(() => {
        if (refetch !== true) return;
        dispatch(fetchExceptionSystemExceptions({id: selectedSystem}));
    }, [refetch]);

    React.useEffect(() => {
        let _rows = [...systemExceptions];

        _rows = _rows.sort((a, b) => {
            return ExceptionSeverity[b?.Severity] - ExceptionSeverity[a?.Severity]
        }).sort((a, b) => {
            if (a.ResolveTimestamp === null && b.ResolveTimestamp === null) return 0;
            return a.ResolveTimestamp === null ? -1 : 1;
        });

        _rows = _rows.map((r) => {
            const unresolved = r.ResolveTimestamp === null;

            let extraOptions = {};
            let actionOptions = {};

            if (unresolved === true)
            {
                extraOptions.background = r.Severity === "High" ? SiteColors.Red : SiteColors.Gold;
                extraOptions.color = "white";

                actionOptions.render = <ResolveButton OnClick={OnOpenDialog(r.ExcepId)} />;
            }

            return [
                { ...DefaultTableCellStyle, ...extraOptions, label: r.Module },
                { ...DefaultTableCellStyle, ...extraOptions, label: r.Message },
                { ...DefaultTableCellStyle, ...extraOptions, label: r.ExceptionType },
                { ...DefaultTableCellStyle, ...extraOptions, render: <ExTimestamp timestamp={r.Timestamp} futureTimestamps={r.FutureExceptionTimestamps} /> },
                { ...DefaultTableCellStyle, ...extraOptions, label: unresolved ? "" : FormatDateTime24Hour(r.ResolveTimestamp) },
                { ...DefaultTableCellStyle, ...actionOptions },
            ];
        });

        setFilteredRows(_rows);
    }, [systemExceptions]);

    const OnDisplayRows = () => {
        dispatch(fetchExceptionSystemExceptions({id: selectedSystem, unresolved: showUnresolved, type: selectedExceptionType}));
    }

    const OnCollapsed = () => {
        let _stack = [];

        _stack.push(selectSystems.find(x => x.value === selectedSystem).label);
        _stack.push(selectedExceptionType);

        if (showUnresolved === true)
        {
            _stack.push("Unresolved Only");
        }

        setFilterChips(_stack);
    }

    const OnUncollapsed = () => {
        setFilterChips([]);
    }

    return (
        <>
            <AppContainer>
                <NewTableBox title={"System Exception List"} height={"100%"}>
                    <Stack spacing={1} height={"100%"}>
                        <CollapseWrap defaultShow onShow={OnUncollapsed} onHide={OnCollapsed}>
                            <MonitoringSearchCriteria isMobile={isMobile}>
                                <MonitoringSearchRow gridSizes={GridSizes}>
                                    <MonitoringSearchSelect label={"System Name"} value={selectedSystem} setValue={setSelectedSystem} items={selectSystems} />
                                    <MonitoringSearchCheckbox label={"Show Unresolved Only"} value={showUnresolved} setValue={setShowUnresolved} />
                                </MonitoringSearchRow>
                                <MonitoringSearchRow gridSizes={GridSizes}>
                                    <MonitoringSearchSelect label={"Exception Type"} value={selectedExceptionType} setValue={setSelectedExceptionType} items={ExceptionTypes} />
                                    <Button fullWidth onClick={OnDisplayRows} variant={"contained"}>Submit</Button>
                                </MonitoringSearchRow>
                            </MonitoringSearchCriteria>
                        </CollapseWrap>
                        <ChipStack direction={"row"} chips={filterChips} size={"small"} />
                        <Divider />
                        <NewTable columns={exceptionListColumns} rows={filteredRows} pageSizeOptions={[10, 20, 30, 40, 50]} loading={fetchingExceptions} autoHeight />
                    </Stack>
                </NewTableBox>
            </AppContainer>
            <ConfirmationDialog open={openDialog} onConfirm={OnResolveConfirmed} onClose={OnCloseDialog} confirmationMsg={dialogMsg} />
        </>
    )
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchDashboard = createAsyncThunk("exceptionDashboard", async () => {
  return await APICall(BackendType.MnC, "exception/dashboard");
});

export const exceptionDashboardSlice = createSlice({
  name: Slices.ExceptionDashboard,
  initialState: DefaultThunkState(),
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.pending, PendingThunk())
      .addCase(fetchDashboard.fulfilled, FulfilledThunk())
      .addCase(fetchDashboard.rejected, FulfilledThunk());
  },
});

export { fetchDashboard };
export default exceptionDashboardSlice.reducer;

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchFsmReport = createAsyncThunk("fsmReport", async ({bankName, dateType, startDate, endDate}) => {
  let url = `fsm/report?bank=${bankName}&dateType=${dateType}&startDate=${startDate}&endDate=${endDate}`;
  return await APICall(BackendType.MnC, url);
});

const fetchFsmInventoryReport = createAsyncThunk("fsmReport/inventory", async ({bankName, dateType, startDate, endDate}) => {
  let url = `fsm/report/inventory?bank=${bankName}&dateType=${dateType}&startDate=${startDate}&endDate=${endDate}`;
  return await APICall(BackendType.MnC, url);
});

export const fsmReportSlice = createSlice({
  name: Slices.FsmReport,
  initialState: DefaultThunkState(),
  reducers: {
      clearFsmReportData: (state, action) => {
          state.data = [];
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmReport.pending, PendingThunk())
      .addCase(fetchFsmReport.fulfilled, FulfilledThunk())
      .addCase(fetchFsmReport.rejected, FulfilledThunk())

      .addCase(fetchFsmInventoryReport.pending, PendingThunk())
      .addCase(fetchFsmInventoryReport.fulfilled, FulfilledThunk())
      .addCase(fetchFsmInventoryReport.rejected, FulfilledThunk());
  },
});

const selectFSMReport = createSelector(
  [ state => state[Slices.FsmReport].data ], (d) => d
);

const selectFSMReportFetching = createSelector(
  [ state => state[Slices.FsmReport].fetching ], (d) => d
);

export { fetchFsmReport, fetchFsmInventoryReport, selectFSMReport, selectFSMReportFetching };
export const { clearFsmReportData } = fsmReportSlice.actions;
export default fsmReportSlice.reducer;

import { Container } from "@mui/material";
import React from "react";

export default function AppContainer({display, justifyContent, overflow, children}) {
    const ref = React.useRef(null);
    const [sx, setSx] = React.useState({});

    React.useEffect(() => {
        if (!ref.current) return;
        
        const observer = new MutationObserver(() => {
            let _sx = {
                height: (ref.current?.scrollHeight > ref.current?.offsetParent?.clientHeight) ? "auto" : "100%"
            };
            
            if (display?.length > 0)
            {
                _sx.display = display;
            }
        
            if (justifyContent?.length > 0)
            {
                _sx.justifyContent = justifyContent;
            }
        
            if (overflow?.length > 0)
            {
                _sx.overflow = overflow;
            }

            setSx(_sx);
        });

        observer.observe(ref.current, { childList: true, subtree: true });
        return () => observer.disconnect();
    }, []);

    return (
        <Container ref={ref} sx={sx} maxWidth={"none"}>
            {children}
        </Container>
    )
}
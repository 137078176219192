import { Box, Chip, Stack } from "@mui/material";
import React from "react";

export default function ChipStack({direction = "column", spacing = 1, size = "medium", chips = []}) {
    const [sx, setSX] = React.useState({});

    React.useEffect(() => {
        let _sx = {};

        if (direction.includes("row"))
        {
            _sx.overflowX = "auto";
            _sx.overflowY = "hidden";
        }
        else
        {
            _sx.overflowX = "hidden";
            _sx.overflowY = "auto";
        }

        setSX(_sx);
    }, [direction]);
    
    return (
        <Box>
            <Stack sx={sx} direction={direction} spacing={spacing}>
                {
                    chips.map((c) => {
                        return (
                            <Chip key={`stack-chip-${c}`} label={c} size={size} />
                        )
                    })
                }
            </Stack>
        </Box>
    )
}
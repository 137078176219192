import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { extendExpiration, logoutUser, setHasShownTimeout } from "../../redux/slices/tempLoginSlice";
import React from "react";

const CancelButton = styled(Button)`
    background-color: white;
    color: black;

    &:hover {
        background-color: lightgray;
    }

    width: 100px;
`;

export default function SessionTimeoutModal({open, onClose, seconds}) {
    const dispatch = useDispatch();

    const OnClose = (e, r) => {
        if (r === "backdropClick") return;

        dispatch(setHasShownTimeout(true));
        onClose();
    }

    const OnOKClicked = () => {
        dispatch(extendExpiration());
        onClose();
    }

    const OnCancelClicked = () => {
        dispatch(logoutUser());
        onClose();
    }

    React.useEffect(() => {
        if (seconds < 0)
        {
            OnCancelClicked();   
        }
    }, [seconds]);

    return (
        <Dialog open={Boolean(open)} onClose={OnClose}>
            <DialogTitle sx={{background: 'black', color: 'white'}}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography>Session Timeout</Typography>
                    <IconButton onClick={OnClose}>
                        <Close sx={{color: "white"}} />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{p: 0}}>
                <Stack>
                    <Box p={4} bgcolor={"lightgray"}>
                        <Typography variant={'p'}>Your session will expire in 00:{seconds.toString().padStart(2, '0')}.</Typography>
                        <br />
                        <Typography variant={'p'}>Click "OK" to stay logged in or click "CANCEL" to log out</Typography>
                    </Box>
                    <Box p={2}>
                        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                            <CancelButton variant={"contained"} onClick={OnCancelClicked}>CANCEL</CancelButton>
                            <Button sx={{width: '100px', pt: 1, pb: 1}} variant={"contained"} color={"success"} onClick={OnOKClicked}>OK</Button>
                        </Stack>
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}
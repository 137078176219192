import { createSlice } from "@reduxjs/toolkit";
import { add } from "date-fns";

export const tempLoginSlice = createSlice({
  name: "tempLogin",
  initialState: {
    username: undefined,
    showTimeout: false,
    hasShownTimeout: false,
    expires_in: undefined,
    intervalId: undefined,
    remember: false
  },
  reducers: {
    loginUser: (state, action) => {
      const { payload: { username, remember } } = action;

      const expires = add(new Date(), remember !== true ? { minutes: 5 } : { days: 30 }).toUTCString();

      state.username = username;
      state.expires_in = expires;
      state.hasShownTimeout = false;
      state.remember = remember;
      document.cookie = `localAuth=${username};expires=${expires};path=/`;
    },
    logoutUser: (state, action) => {
      if (state.intervalId !== undefined)
      {
        window.clearInterval(state.intervalId);
      }

      state.username = undefined;
      state.showTimeout = false;
      state.hasShownTimeout = false;
      state.expires_in = undefined;
      state.intervalId = undefined;
      state.remember = false;
      document.cookie = `localAuth=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setShowTimeout: (state, action) => {
      if (state.username !== undefined)
      {
        state.showTimeout = action.payload;
      }
    },
    setHasShownTimeout: (state, action) => {
      state.hasShownTimeout = action.payload;
    },
    extendExpiration: (state, action) => {
      const expires = add(new Date(), state.remember === false ? { minutes: 5 } : { days: 30 } ).toUTCString();

      state.expires_in = expires;
      state.hasShownTimeout = false;
      document.cookie = `localAuth=${state.username};expires=${expires};path=/`;
    },
    setLoginIntervalId: (state, action) => {
      if (state.intervalId !== undefined)
      {
        window.clearInterval(state.intervalId);
      }

      state.intervalId = action.payload;
    }
  },
});

const selectUsername = (state) => {
    return state["tempLogin"].username;
}

const selectShowTimeout = (state) => {
  return state["tempLogin"].showTimeout;
}

const selectHasShownTimeout = (state) => {
  return state["tempLogin"].hasShownTimeout;
}

const selectExpiresIn = (state) => {
  return state["tempLogin"].expires_in;
}

export { selectUsername, selectShowTimeout, selectHasShownTimeout, selectExpiresIn };
export const { loginUser, logoutUser, setUsername, setShowTimeout, setHasShownTimeout, extendExpiration, setLoginIntervalId } = tempLoginSlice.actions;
export default tempLoginSlice.reducer;

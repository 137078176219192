import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Classes, MenuPropsHeightLimit, OpenSansStyled } from "../constants";
import NewTableBox from "./NewTableBox";
import styled from "@emotion/styled";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const StyledSelect = styled(Select)`
    .MuiSelect-select {
        ${OpenSansStyled};
    }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
    .MuiTypography-root {
        ${OpenSansStyled};
    }
`;

const DateBox = styled(Box)`
    height: 100%;

    .MuiStack-root {
        height: 100%;
    }

    .MuiInputBase-input {
        ${OpenSansStyled};
    }
`;

const TrimLabel = (lbl) => lbl.match(/[^:]+/)[0];

const MonitoringInput = ({label = "", children}) => {
    const hasLabel = label.length > 0;

    return (
        <Grid container>
            {
                hasLabel === true &&
                <Grid item xs={4} alignSelf={"center"}>
                    <Typography sx={{overflowWrap: "break-word"}} className={Classes.opensans}>{label}</Typography>
                </Grid>
            }
            <Grid item xs={hasLabel === true ? 8 : 12}>
                {children}
            </Grid>
        </Grid>
    )
}

const MonitoringSearchSelect = ({label, selectLabel = "", value, setValue, items}) => {
    const OnChange = (e) => {
        setValue(e.target.value);
    }

    return (
        <MonitoringInput label={label}>
            <FormControl fullWidth>
                {
                    selectLabel.length > 0 &&
                    <InputLabel id={`select-${selectLabel}-label`}>{selectLabel}</InputLabel>
                }
                <StyledSelect labelId={`select-${selectLabel}-label`} label={selectLabel} fullWidth size={"small"} value={value} onChange={OnChange} MenuProps={MenuPropsHeightLimit(196)} >
                    {
                        items.map((i) => {
                            return (
                                <MenuItem key={`${label}-${i.label}`} value={i.value ?? i.label}>{i.label}</MenuItem>
                            )
                        })
                    }
                </StyledSelect>
            </FormControl>
        </MonitoringInput>
    )
}

const MonitoringSearchCheckbox = ({label, value, setValue, labelPosition = "end", padStart = false, disabled = false}) => {
    const OnChange = (e, v) => {
        setValue(v);
    }
    
    return (
        <FormControl fullWidth>
            {
                labelPosition === "start" &&
                <MonitoringInput label={label}>
                    <Checkbox value={value} checked={value} onChange={OnChange} disabled={disabled} />
                </MonitoringInput>
            }
            {
                labelPosition === "end" && padStart === false &&
                <StyledFormControlLabel control={<Checkbox value={value} checked={value} onChange={OnChange} disabled={disabled} />} label={label} />
            }
            {
                labelPosition === "end" && padStart === true &&
                <MonitoringInput label={' '}>
                    <StyledFormControlLabel control={<Checkbox value={value} checked={value} onChange={OnChange} disabled={disabled} />} label={label} />
                </MonitoringInput>
            }
        </FormControl>
    )
}

const MonitoringSearchDate =({label, dateLabel, value, setValue, format = "dd/MM/yyyy", minDate = null, maxDate = null, disabled = false}) => {
    const OnChange = (v) => {
        setValue(v);
    }

    return (
        <MonitoringInput label={label}>
            <DateBox>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DemoContainer components={['DatePicker']}>
                        <FormControl fullWidth>
                            <DatePicker label={dateLabel ?? TrimLabel(label)} onChange={OnChange} value={value} format={format} minDate={minDate} maxDate={maxDate} slotProps={{textField: {size: "small"}}} disabled={disabled} />
                        </FormControl>
                    </DemoContainer>
                </LocalizationProvider>
            </DateBox>
        </MonitoringInput>
    )
}

const MonitoringSearchDateTime = ({label, dateLabel, value, setValue, format = "dd/MM/yyyy HH:mm:ss", minDate = null, maxDate = null, disabled = false}) => {
    const OnChange = (v) => {
        setValue(v);
    }

    return (
        <MonitoringInput label={label}>
            <DateBox>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DemoContainer components={['DateTimePicker']}>
                        <FormControl fullWidth>
                            <DateTimePicker label={dateLabel ?? TrimLabel(label)} onChange={OnChange} value={value} format={format} minDate={minDate} maxDate={maxDate} slotProps={{textField: {size: "small"}}} disabled={disabled} />
                        </FormControl>
                    </DemoContainer>
                </LocalizationProvider>
            </DateBox>
        </MonitoringInput>
    )
}

const MonitoringSearchInput = ({label, inputLabel, value, setValue, InputProps = undefined, max = 100, multiline = false, rows = 1, disabled = false}) => {
    const OnChange = (e) => {
        setValue(e.target.value);
    }

    return (
        <MonitoringInput label={label}>
            <TextField size={"small"} fullWidth variant={"outlined"} disabled={disabled} label={inputLabel ?? TrimLabel(label)} multiline={multiline} rows={rows} value={value} onChange={OnChange} InputProps={InputProps} inputProps={{maxLength: max}} />
        </MonitoringInput>
    )
}

const MonitoringSearchRow = ({gridSizes = [12], alignItems = "center", children}) => {
    return (
        <Grid container spacing={1} mt={0} ml={0} width={"100%"} alignItems={alignItems}>
            {
                gridSizes.map((s, i) => {
                    return (
                        <Grid item xs={s} key={`monitoring-search-row-${i}`}>
                            {
                                Array.isArray(children) === true
                                ? children[i]
                                : i <= 0 && children
                            }
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

const MonitoringSearchText = ({fontStyle = "normal", color = "black", children}) => {
    return (            
        <Typography className={Classes.opensans} color={color} fontStyle={fontStyle}>{children}</Typography>
    )
}

export default function MonitoringSearchCriteria({isMobile, children, title = "Search Criteria"})
{
    return (
        <NewTableBox title={title} background={"white"} width={isMobile ? "100%" : "50%"}>
            <Stack spacing={1}>
                {children}
            </Stack>
        </NewTableBox>
    )    
}

export { MonitoringSearchRow, MonitoringSearchSelect, MonitoringSearchCheckbox, MonitoringSearchDate, MonitoringSearchDateTime, MonitoringSearchInput, MonitoringSearchText }
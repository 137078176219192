import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchFsmDetailPreview = createAsyncThunk("fsmDetailPreview", async ({sreId}) => {
  return await APICall(BackendType.MnC, `fsm/detailPreview?sreId=${sreId}`);
});

export const fsmPreviewInfoSlice = createSlice({
  name: Slices.FsmDetailPreview,
  initialState: DefaultThunkState(),
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmDetailPreview.pending, PendingThunk())
      .addCase(fetchFsmDetailPreview.fulfilled, FulfilledThunk())
      .addCase(fetchFsmDetailPreview.rejected, FulfilledThunk());
  },
});

const selectFsmDetailPreviewFetching = createSelector(
  [ state => state[Slices.FsmDetailPreview].fetching ], (d) => d
);

const selectFsmDetailPreviewData = createSelector(
  [ state => state[Slices.FsmDetailPreview].data ], (d) => d
);

export { fetchFsmDetailPreview, selectFsmDetailPreviewData, selectFsmDetailPreviewFetching };
export default fsmPreviewInfoSlice.reducer;

import { Divider, Stack, Button, CircularProgress } from "@mui/material"
import AppContainer from "../AppContainer"
import NewTableBox from "../NewTableBox"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import NewTable from "../NewTable"
import CollapseWrap from "../CollapseWrap"
import ChipStack from "../ChipStack"
import useDownloadFile from "../../CustomHooks/useDownloadFile"
import { clearFsmReportData, selectFSMReportFetching } from "../../redux/slices/fsmReportSlice"
import { clearFsmXLSReportData, selectFsmXLSReport, selectFsmXLSReportFetching } from "../../redux/slices/fsmXLSReportSlice"
import MonitoringSearchCriteria, { MonitoringSearchRow } from "../MonitoringSearchCriteria"

const ReportButtonStack = ({OnSubmit, OnExport, disableExport, fetching}) => {
    return (
        <Stack spacing={1} direction={"row"} height={"100%"} alignItems={"flex-end"}>
            <Button fullWidth variant={"contained"} onClick={OnSubmit}>Submit</Button>
            <Button fullWidth variant={"contained"} onClick={OnExport} disabled={disableExport}>
                {
                    fetching !== true && 'Export XLS'
                }
                {
                    fetching === true &&
                    <CircularProgress size={24} />
                }
            </Button>
        </Stack>
    )
}

const SearchCriteria = ({rows, searchCriteria, filterChips, selectors, isMobile}) => {
    const dispatch = useDispatch();
    const [chips, setChips] = React.useState([]);

    const excelFetching = useSelector(selectFsmXLSReportFetching);

    const OnCollapse = () => {
        setChips(filterChips);
    }

    const OnUncollapse = () => {
        setChips([]);
    }
    
    const onSubmit = () => {
        if (selectors.fetch !== undefined) dispatch(selectors.fetch);
    }

    const onExportXLS = () => {
        if (selectors.fetchExcel !== undefined) dispatch(selectors.fetchExcel);
    }

    return (
        <>
            <CollapseWrap defaultShow onShow={OnUncollapse} onHide={OnCollapse}>
                <MonitoringSearchCriteria isMobile={isMobile}>
                    {searchCriteria}
                    <MonitoringSearchRow gridSizes={[12]}>
                        <ReportButtonStack OnSubmit={onSubmit} OnExport={onExportXLS} fetching={excelFetching} disableExport={rows.length <= 0 || excelFetching === true} />
                    </MonitoringSearchRow>
                </MonitoringSearchCriteria>
            </CollapseWrap>
            <ChipStack direction={"row"} size={"small"} chips={chips} />
            <Divider />
        </>
    )
}

export default function FSMReportBox({title, isMobile, columns = [], rows = [], children = undefined, filterChips = [], selectors = {}}) {
    const dispatch = useDispatch();

    const fetching = useSelector(selectFSMReportFetching);
    const excelData = useSelector(selectFsmXLSReport);

    useDownloadFile(excelData);
    
    React.useEffect(() => {
        return () => {
            dispatch(clearFsmReportData());
            dispatch(clearFsmXLSReportData());
        }
    }, []);

    return (
        <AppContainer>
            <NewTableBox title={title} height={"100%"}>
                <Stack spacing={1} height={"100%"}>
                    {
                        children !== undefined &&
                        <SearchCriteria searchCriteria={children} filterChips={filterChips} rows={rows} selectors={selectors} isMobile={isMobile} />
                    }
                    <NewTable columns={columns} rows={rows} pageSizeOptions={[10, 20, 30, 40, 50]} loading={fetching} autoHeight />
                </Stack>
            </NewTableBox>
        </AppContainer>
    )
}
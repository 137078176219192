import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, CrudFulfilledThunk, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const defData = {};

const fetchSms = createAsyncThunk("exception/settings/sms", async () => {
  return await APICall(BackendType.MnC, "exception/sms");
});

const saveSms = createAsyncThunk("exception/settings/sms/save", async(payload) => {
  return await APICall(BackendType.MnC, "exception/sms", "POST", payload);
});

export const exceptionSettingsSmsSlice = createSlice({
  name: Slices.ExceptionSettingsSms,
  initialState: DefaultThunkState(defData),
  extraReducers: (builder) => {
    builder
      .addCase(fetchSms.pending, PendingThunk(defData))
      .addCase(fetchSms.fulfilled, FulfilledThunk(defData))
      .addCase(fetchSms.rejected, FulfilledThunk(defData))

      .addCase(saveSms.pending, PendingThunk(defData))
      .addCase(saveSms.fulfilled, CrudFulfilledThunk(defData))
      .addCase(saveSms.rejected, CrudFulfilledThunk(defData));
  },
});

export { fetchSms, saveSms };
export default exceptionSettingsSmsSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, CrudFulfilledThunk, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchExceptionNotification = createAsyncThunk("exception/settings/notification", async () => {
  return await APICall(BackendType.MnC, "exception/notification");
});

const addExceptionNotification = createAsyncThunk("exception/settings/notification/add", async(payload) => {
  return await APICall(BackendType.MnC, "exception/notification", "POST", payload);
});

export const exceptionSettingsNotificationSlice = createSlice({
  name: Slices.ExceptionSettingsNotification,
  initialState: DefaultThunkState(),
  extraReducers: (builder) => {
    builder
      .addCase(fetchExceptionNotification.pending, PendingThunk())
      .addCase(fetchExceptionNotification.fulfilled, FulfilledThunk())
      .addCase(fetchExceptionNotification.rejected, FulfilledThunk())

      .addCase(addExceptionNotification.pending, PendingThunk())
      .addCase(addExceptionNotification.fulfilled, CrudFulfilledThunk())
      .addCase(addExceptionNotification.rejected, CrudFulfilledThunk());
  },
});

export { fetchExceptionNotification, addExceptionNotification };
export default exceptionSettingsNotificationSlice.reducer;

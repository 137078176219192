import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, CrudFulfilledThunk, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";
import { format } from "date-fns";

const fetchFsmManualJobs = createAsyncThunk("fsmViewManualJobs", async ({sreid, bank, start, end}) => {
    let endpoint = `fsm/manualJobs?bank=${bank}&startDt=${format(start, 'yyyy-MM-dd')}&endDt=${format(end, 'yyyy-MM-dd')}`;

    if (sreid !== null && sreid !== undefined && sreid.length > 0)
    {
        endpoint += `&sreid=${sreid}`;
    }
    
    return await APICall(BackendType.MnC, endpoint);
});

const submitFsmManualJob = createAsyncThunk("fsmSubmitManualTicket", async (payload) => {
  return await APICall(BackendType.MnC, `fsm/createManualJob`, "POST", payload);
});

export const fsmManualJobSlice = createSlice({
  name: Slices.FsmManualJob,
  initialState: DefaultThunkState(),
  reducers: {
      resetFsmManualJobState: (state, action) => {
          state = DefaultThunkState();
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmManualJobs.pending, PendingThunk())
      .addCase(fetchFsmManualJobs.fulfilled, FulfilledThunk())
      .addCase(fetchFsmManualJobs.rejected, FulfilledThunk())

      .addCase(submitFsmManualJob.pending, PendingThunk())
      .addCase(submitFsmManualJob.fulfilled, CrudFulfilledThunk())
      .addCase(submitFsmManualJob.rejected, CrudFulfilledThunk())
  },
});

const selectFsmManualJobs = createSelector(
  [ state => state[Slices.FsmManualJob].data ], (d) => d
);

const selectFsmManualJobsFetching = createSelector(
  [ state => state[Slices.FsmManualJob].fetching ], (d) => d
);

const selectFsmCreateManualJobCrudSuccess = createSelector(
  [ state => state[Slices.FsmManualJob].crudSuccess ], (d) => d
);

const selectFsmCreateManualJobMessage = createSelector(
  [ state => state[Slices.FsmManualJob].message ], (d) => d
);

export { fetchFsmManualJobs, submitFsmManualJob, selectFsmManualJobs, selectFsmManualJobsFetching, selectFsmCreateManualJobCrudSuccess, selectFsmCreateManualJobMessage };
export const { resetFsmManualJobState } = fsmManualJobSlice.actions;
export default fsmManualJobSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType } from "../../Utils";

const sliceName = "reason";

const fetchReasons = createAsyncThunk('reasons', async () => {
    const resp = await APICall(BackendType.RoomBooking, `bookroom/reasons`);
    return resp.response;
});

export const reasonSlice = createSlice({
    name: sliceName,
    initialState: {
        fetching: false,
        reasons: []
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchReasons.pending, (state) => {
            state.fetching = true;
        })
        .addCase(fetchReasons.fulfilled, (state, action) => {
            state.reasons = action.payload?.data ?? [];
            state.fetching = false;
        })
        .addCase(fetchReasons.rejected, (state, action) => {
            state.fetching = false;
            state.reasons = [];
        })
    }
});

const selectReasonsFetching = (state) => {
    return state[sliceName].fetching;
}

const selectReasons = (state) => {
    return state[sliceName].reasons;
}

export { fetchReasons, selectReasonsFetching, selectReasons };
export default reasonSlice.reducer;
import { Box, styled } from "@mui/material";

const ContainerFieldset = styled("fieldset", { shouldForwardProp: (props) => props !== "width" && props !== "fontsize" })(({ theme, width, fontsize }) => `
    height: 100%;
    width: ${width ?? "100%"};
    border-radius: ${theme.spacing(2)};
    min-height: 0;
    min-width: 0;
    overflow: auto;

    legend {
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
        font-weight: ${theme.typography.fontWeightMedium};
        ${
            fontsize && `font-size:${fontsize};`
        }
    }
`);

export default function LegendContainer({title, width, fontsize, children}) {
    return (
        <ContainerFieldset width={width} fontsize={fontsize}>
            <legend>{title}</legend>
            <Box width={"inherit"}>
                {children}
            </Box>
        </ContainerFieldset>
    )
}
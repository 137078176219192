import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, CrudFulfilledThunk, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchFsmViewProductReview = createAsyncThunk("fsmViewProductReview", async ({sreid, jobid}) => {
  return await APICall(BackendType.MnC, `fsm/viewProductReview?sreid=${sreid}&jobid=${jobid}`);
});

const resolveFsmProductReview = createAsyncThunk("fsmResolveProductReview", async (payload) => {
  return await APICall(BackendType.MnC, `fsm/resolveProductReview`, "POST", payload);
});

const PendingAction = (state, action) => {
  PendingThunk({})(state, action);
  state.refetch = false;
}

const FulfilledAction = (state, action) => {
  FulfilledThunk({})(state, action);
  state.refetch = false;
}

const CrudFulfilledAction = (state, action) => {
  CrudFulfilledThunk({})(state, action);
  state.refetch = action.payload.statusCode === 200;
}

export const fsmOneProductReviewSlice = createSlice({
  name: Slices.FsmOneProductReview,
  initialState: {
    ...DefaultThunkState({}),
    refetch: false
  },
  reducers: {
      setOneProductReviewData: (state, action) => {
          state.data = action.payload;
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmViewProductReview.pending, PendingAction)
      .addCase(fetchFsmViewProductReview.fulfilled, FulfilledAction)
      .addCase(fetchFsmViewProductReview.rejected, FulfilledAction)

      .addCase(resolveFsmProductReview.pending, PendingAction)
      .addCase(resolveFsmProductReview.fulfilled, CrudFulfilledAction)
      .addCase(resolveFsmProductReview.rejected, CrudFulfilledAction)
  },
});

const selectFsmOneProductReviewData = createSelector(
  [ state => state[Slices.FsmOneProductReview].data ], (d) => d
);

const selectFsmOneProductReviewRefetch = createSelector(
  [ state => state[Slices.FsmOneProductReview].refetch ], (d) => d
);

export { fetchFsmViewProductReview, resolveFsmProductReview, selectFsmOneProductReviewData, selectFsmOneProductReviewRefetch };
export const { setOneProductReviewData } = fsmOneProductReviewSlice.actions;
export default fsmOneProductReviewSlice.reducer;

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchFsmFollowUpEmails = createAsyncThunk("fsmFollowupEmails", async ({month, year}) => {
  return await APICall(BackendType.MnC, `fsm/followupEmails?month=${month}&year=${year}`);
});

export const fsmFollowupSlice = createSlice({
  name: Slices.FsmFollowupEmails,
  initialState: DefaultThunkState(),
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmFollowUpEmails.pending, PendingThunk())
      .addCase(fetchFsmFollowUpEmails.fulfilled, FulfilledThunk())
      .addCase(fetchFsmFollowUpEmails.rejected, FulfilledThunk())
  },
});

const selectFsmFollowupEmailsFetching = createSelector(
  [ state => state[Slices.FsmFollowupEmails].fetching ], (d) => d
);

const selectFsmFollowupEmailsData = createSelector(
  [ state => state[Slices.FsmFollowupEmails].data ], (d) => d
);

export { fetchFsmFollowUpEmails, selectFsmFollowupEmailsFetching, selectFsmFollowupEmailsData };
export default fsmFollowupSlice.reducer;

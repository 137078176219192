import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchFsmInfoPreview = createAsyncThunk("fsmPreviewInfo", async ({type}) => {
  return await APICall(BackendType.MnC, `fsm/infoPreview/${type}`);
});

export const fsmPreviewInfoSlice = createSlice({
  name: Slices.FsmPreviewInfo,
  initialState: DefaultThunkState(),
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmInfoPreview.pending, PendingThunk())
      .addCase(fetchFsmInfoPreview.fulfilled, FulfilledThunk())
      .addCase(fetchFsmInfoPreview.rejected, FulfilledThunk());
  },
});

const selectFsmInfoPreviewFetching = createSelector(
  [ state => state[Slices.FsmPreviewInfo].fetching ], (d) => d
);

const selectFsmInfoPreviewData = createSelector(
  [ state => state[Slices.FsmPreviewInfo].data ], (d) => d
);

export { fetchFsmInfoPreview, selectFsmInfoPreviewData, selectFsmInfoPreviewFetching };
export default fsmPreviewInfoSlice.reducer;

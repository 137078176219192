import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, CrudFulfilledThunk, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const defData = {};

const fetchExceptionMail = createAsyncThunk("exception/settings/mail", async () => {
  return await APICall(BackendType.MnC, "exception/mail");
});

const saveExceptionMail = createAsyncThunk("exception/settings/mail/save", async(payload) => {
  return await APICall(BackendType.MnC, "exception/mail", "POST", payload);
});

export const exceptionSettingsMailSlice = createSlice({
  name: Slices.ExceptionSettingsMail,
  initialState: DefaultThunkState(defData),
  extraReducers: (builder) => {
    builder
      .addCase(fetchExceptionMail.pending, PendingThunk(defData))
      .addCase(fetchExceptionMail.fulfilled, FulfilledThunk(defData))
      .addCase(fetchExceptionMail.rejected, FulfilledThunk(defData))

      .addCase(saveExceptionMail.pending, PendingThunk(defData))
      .addCase(saveExceptionMail.fulfilled, CrudFulfilledThunk(defData))
      .addCase(saveExceptionMail.rejected, CrudFulfilledThunk(defData));
  },
});

export { fetchExceptionMail, saveExceptionMail };
export default exceptionSettingsMailSlice.reducer;

import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography, styled } from "@mui/material";
import LegendContainer from "../LegendContainer";
import SeverityTag from "../SeverityTag";
import React from "react";
import { DateTime24HourFormatter, DateTimeValueGetter } from "../../Utils";
import { Cancel } from "@mui/icons-material";
import AppContainer from "../AppContainer";
import ViewDataGrid from "../DataGrid/ViewDataGrid";

const NotificationType = [
    {
        id: 1,
        type: "SMS"
    },
    {
        id: 2,
        type: "EMAIL"
    },
    {
        id: 3,
        type: "WHATSAPP"
    }
];

const Severity = [
    {
        id: 1,
        type: "Low"
    },
    {
        id: 2,
        type: "Medium"
    },
    {
        id: 3,
        type: "High"
    }
];

const ExceptionType = [
    {
        id: 1,
        type: "Database"
    },
    {
        id: 2,
        type: "Mail"
    },
    {
        id: 3,
        type: "File"
    },
    {
        id: 4,
        type: "Others"
    }
]

const SeverityIdTag = (params) => {
    if (!params.value) return params.value;

    return (
        <SeverityTag severity={Severity.find(x => x.id === params.value).type} />
    )
}

const NotificationCell = (params) => {
    if (!params.value) return params.value;

    return (
        <Typography>{NotificationType.find(x => x.id === params.value).type}</Typography>
    )
}

const columns = [
    { field: 'Staff', headerName: 'Staff', width: 140 },
    { field: 'NotificationId', headerName: 'Notified via', width: 200, renderCell: NotificationCell },
    { field: 'ExceptionMessage', headerName: 'Exception Message', width: 280 },
    { field: 'SeverityId', headerName: 'Severity', width: 100, renderCell: SeverityIdTag },
    { field: 'ExceptionType', headerName: 'Exception Type', width: 140 },
    { field: 'NotifiedTime', headerName: 'Notified Time', width: 180, valueGetter: DateTimeValueGetter, valueFormatter: DateTime24HourFormatter },
    { field: 'NotifiedStatus', headerName: 'Status', width: 100 },
    { field: 'Remark', headerName: 'Remark', width: 200 }
]

const rows = [
    {id: 1, "Staff":"vincent","NotificationId":1,"ExceptionMessage":"something went wrong somehwere","SeverityId":3,"ExceptionType":"Other","NotifiedTime":"2023-06-22T11:05:10","NotifiedStatus":"sent","Remark":""},
    {id: 2, "Staff":"tan","NotificationId":2,"ExceptionMessage":"unable to connect","SeverityId":1,"ExceptionType":"Database","NotifiedTime":"2023-06-22T11:05:10","NotifiedStatus":"sent","Remark":""},
    {id: 3, "Staff":"tan","NotificationId":2,"ExceptionMessage":"unable to read","SeverityId":2,"ExceptionType":"Database","NotifiedTime":"2023-06-22T11:05:10","NotifiedStatus":"failed","Remark":""},
    {id: 4, "Staff":"tan","NotificationId":2,"ExceptionMessage":"invalid json content","SeverityId":3,"ExceptionType":"File","NotifiedTime":"2023-06-22T11:05:10","NotifiedStatus":"sent","Remark":""},
    {id: 5, "Staff":"wee liang","NotificationId":3,"ExceptionMessage":"unable to send out","SeverityId":2,"ExceptionType":"Mail","NotifiedTime":"2023-06-22T11:05:10","NotifiedStatus":"sent","Remark":""}
];

const MobileFormControlLabel = styled(FormControlLabel)`
    margin-right: 0;

    .MuiButtonBase-root {
        padding: 4px;
    }

    .MuiTypography-root {
        font-size: 0.7rem;
        text-wrap: wrap;
    }
`;

const ChipSelectItem = ({selected, menuItems, setDeleteChip}) => {
    const OnDeleteChip = (v) => (e) => {
        e.preventDefault();
        setDeleteChip(v);
    }

    return(
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {
                selected.map((v) => {
                    return (
                        <Chip size={"small"}
                            onDelete={OnDeleteChip(v)} deleteIcon={<Cancel onMouseDown={(event) => event.stopPropagation()} />}
                            key={`selected-notify-type-${v}`} label={menuItems.find(x => x.id === v).type} />
                    )
                })
            }
        </Box>
    )
}

const ChipSelect = ({label, menuItems, value, setValue}) => {
    const [deleteId, setDeleteId] = React.useState(-1);

    const OnValueChanged = (e) => {
        setValue(e.target.value);
    }

    React.useEffect(() => {
        if (deleteId <= 0) return;

        setValue(value.filter(x => x !== deleteId));
        setDeleteId(-1);
    }, [deleteId]);

    return (
        <FormControl size={"small"}>
            <InputLabel id={`chip-select-${label}-label`}>{label}</InputLabel>
            <Select labelId={`chip-select-${label}-label`} multiple value={value} onChange={OnValueChanged} 
                input={<OutlinedInput label={label} />} renderValue={(selected) => <ChipSelectItem selected={selected} setDeleteChip={setDeleteId} menuItems={menuItems} />} >
                {
                    menuItems.map((v) => {
                        return (
                            <MenuItem key={`filter-${label}-${v.id}`} value={v.id}>{v.type}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}

const MobileFilter = ({filterOptions, title, filters, setFilters}) => {
    const OnCheckChange = (opt) => (e, v) => {
        if (v === true)
        {
            setFilters([...filters, opt.id]);
        }
        else
        {
            setFilters(filters.filter(f => f !== opt.id));
        }
    }

    return (
        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <LegendContainer fontsize={"0.8rem"} title={title}>
                <FormGroup>
                    {
                        filterOptions.map((opt) => {
                            return (
                                <MobileFormControlLabel key={`mobile-${title}-checkbox-${opt.type}`} control={<Checkbox size="small" value={filters.includes(opt.id)} onChange={OnCheckChange(opt)} />} label={opt.type} />
                            )
                        })
                    }
                </FormGroup>
            </LegendContainer>
        </Grid>
    )
}

export default function ExceptionNotifications({isMobile}) {
    // Filters
    const [notifyTypes, setNotifyTypes] = React.useState([]);
    const [severities, setSeverities] = React.useState([]);
    const [excepTypes, setExcepTypes] = React.useState([]);

    const [filteredRows, setFilteredRows] = React.useState(rows);
    
    React.useEffect(() => {
        let _rows = rows;

        if (notifyTypes.length > 0) _rows = _rows.filter(r => notifyTypes.includes(r.NotificationId));
        if (severities.length > 0) _rows = _rows.filter(r => severities.includes(r.SeverityId));
        if (excepTypes.length > 0) _rows = _rows.filter(r => excepTypes.map(e => ExceptionType.find(x => x.id === e).type).includes(r.ExceptionType));

        setFilteredRows(_rows);
    }, [notifyTypes, severities, excepTypes]);

    return (
        <>
            <AppContainer>
                <Stack spacing={isMobile === true ? 6 : 2} alignItems={"center"}>
                    <>
                        {
                            isMobile === true &&
                            <Grid container width={"100%"}>
                                <MobileFilter filterOptions={NotificationType} title={"Notification Type"} filters={notifyTypes} setFilters={setNotifyTypes} />
                                <MobileFilter filterOptions={Severity} title={"Severity"} filters={severities} setFilters={setSeverities} />
                                <MobileFilter filterOptions={ExceptionType} title={"Exception Type"} filters={excepTypes} setFilters={setExcepTypes} />
                            </Grid>
                        }
                        {
                            isMobile !== true &&
                            <LegendContainer title={"Filters"}>
                                <Stack spacing={2}>
                                    <ChipSelect label={"Notification Type"} menuItems={NotificationType} value={notifyTypes} setValue={setNotifyTypes} />
                                    <ChipSelect label={"Severity"} menuItems={Severity} value={severities} setValue={setSeverities} />
                                    <ChipSelect label={"Exception Type"} menuItems={ExceptionType} value={excepTypes} setValue={setExcepTypes} />
                                </Stack>
                            </LegendContainer>
                        }
                    </>
                    <Box width={"100%"}>
                        <ViewDataGrid columns={columns} rows={filteredRows} pageSize={15} />
                    </Box>
                </Stack>
            </AppContainer >
        </>
    )
}
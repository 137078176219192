import { OutlinedInput, Tooltip, styled, tooltipClasses } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import React from "react";

const ErrorTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}/>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginTop: '-14px !important',
  }
}));

export default function EditInput({id, value, field, errorText, isErrorCallback}) {
    const apiRef = useGridApiContext();

    const [hasError, setHasError] = React.useState(isErrorCallback(value));

    const OnValueChanged = (e) => {
        const _hasError = isErrorCallback(e.target.value);
        setHasError(_hasError);

        apiRef.current.setEditCellValue({ id, field, error: _hasError, value: e.target.value });
    }

    return (
        <ErrorTooltip open={hasError} title={errorText ?? "Invalid"} placement="bottom">
            <OutlinedInput size={"small"} value={value} onChange={OnValueChanged} />
        </ErrorTooltip>
    )
}
import { Button, Stack } from '@mui/material';
import React from 'react';
import { NavPaths } from '../../constants';

const Home = () => {
  return (
    <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
      <Button variant="contained" href={`/${NavPaths.BookRoom}`} >Go To Bookroom</Button>
      <Button variant="contained" href={`/${NavPaths.ExceptionDashboard}`} >Go To Exception Dashboard</Button>
      <Button variant="contained" href={`/${NavPaths.ExceptionList}`} >Go To Exception List</Button>
    </Stack>
  )
}

export default Home
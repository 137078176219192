import { Button, Stack } from "@mui/material";
import AppContainer from "../AppContainer";
import NewTableBox from "../NewTableBox";
import MonitoringSearchCriteria, { MonitoringSearchDateTime, MonitoringSearchInput, MonitoringSearchRow, MonitoringSearchSelect } from "../MonitoringSearchCriteria";
import React from "react";
import { add, format } from "date-fns";
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from "../../redux/slices/appSlice";
import ConfirmationDialog from "../ConfirmationDialog";
import { resetFsmManualJobTestState, selectFsmCreateManualJobTestCrudSuccess, selectFsmCreateManualJobTestMessage, submitFsmManualJobTest } from "../../redux/slices/fsmManualJobTestSlice";

const gridSizes = [9, 3];

const BankNames = [
    { label: "HSBC", value: "HSBC" },
    { label: "HASE", value: "HASE" },
    { label: "BOC", value: "BOC" },
    { label: "NCB", value: "NCB" },
    { label: "CYB", value: "CYB" },
    { label: "CHB", value: "CHB" },
];

const DTFormat = "dd/MM/yyyy HH:mm";
const PayloadDTFormat = "yyyy-MM-dd HH:mm:ss";

export default function FSMManualJobTicketTest({isMobile}) {
    const dispatch = useDispatch();

    const [bank, setBank] = React.useState(BankNames[0].value);
    const [sreId, setSreId] = React.useState("");
    const [ticketType, setTicketType] = React.useState("");
    const [createDt, setCreateDt] = React.useState(new Date());
    const [machId, setMachId] = React.useState("");
    const [errMsg, setErrMsg] = React.useState("");
    const [slaDt, setSlaDt] = React.useState(add(new Date(), { minutes: 30 }));
    const [resDt, setResDt] = React.useState(new Date());

    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const crudSuccess = useSelector(selectFsmCreateManualJobTestCrudSuccess);
    const msg = useSelector(selectFsmCreateManualJobTestMessage);

    React.useEffect(() => {
        return () => {
            dispatch(resetFsmManualJobTestState());
        }
    }, []);

    React.useEffect(() => {
        setResDt(add(slaDt, { hours: 2 }));
    }, [slaDt]);

    React.useEffect(() => {
        setSlaDt(add(createDt, { minutes: 30 }));
    }, [createDt]);

    React.useEffect(() => {
        if (crudSuccess === false)
        {
            if (msg.length > 0)
            {
                dispatch(setSnackbar({
                    open: true,
                    message: msg,
                    severity: "error"
                }));
            }
        }
        else if (crudSuccess === true)
        {
            dispatch(setSnackbar({
                open: true,
                message: `Test Manual Mail sent for ${sreId}`,
                severity: "success"
            }));

            setBank(BankNames[0].value);
            setSreId("");
            setTicketType("");
            setCreateDt(new Date());
            setMachId("");
            setErrMsg("");
        }
    }, [crudSuccess]);

    const OnCreateJobClicked = () => {
        let _errMsg = "";

        if (sreId.length <= 0)
        {
            _errMsg = "SRE ID cannot be empty";
        }
        else if (machId.length <= 0)
        {
            _errMsg = "Machine ID cannot be empty";
        }
        else if (errMsg.length <= 0)
        {
            _errMsg = "Error Message cannot be empty";
        }

        if (_errMsg.length > 0)
        {
            let payload = {
                open: true,
                message: _errMsg,
                severity: "error"
            };

            dispatch(setSnackbar(payload));
            return;
        }

        setOpenConfirmation(true);
    }

    const OnConfirmClose = () => {
        setOpenConfirmation(false);
    }

    const OnConfirmSubmit = () => {
        const payload = {
            bank: bank,
            sreId: sreId,
            ticketType: ticketType,
            createDT: format(createDt, PayloadDTFormat),
            machId: machId,
            errMsg: errMsg,
            slaDT: format(slaDt, PayloadDTFormat),
            resolutionDT: format(resDt, PayloadDTFormat)
        }

        setOpenConfirmation(false);
        dispatch(submitFsmManualJobTest(payload));
    }

    return (
        <>
            <AppContainer>
                <NewTableBox title={"FSM Create Test Manual Job Ticket"} height={"100%"}>
                    <Stack spacing={1} height={"100%"}>
                        <MonitoringSearchCriteria isMobile={isMobile} title={"Create Test Job Ticket"}>
                            <MonitoringSearchRow gridSizes={gridSizes}>
                                <MonitoringSearchSelect label={"Bank Name:"} value={bank} setValue={setBank} items={BankNames} />
                            </MonitoringSearchRow>
                            <MonitoringSearchRow gridSizes={gridSizes}>
                                <MonitoringSearchInput label={"SRE ID:"} value={sreId} setValue={setSreId} max={16} />
                            </MonitoringSearchRow>
                            <MonitoringSearchRow gridSizes={gridSizes}>
                                <MonitoringSearchInput label={"Ticket Type:"} value={ticketType} setValue={setTicketType} />
                            </MonitoringSearchRow>
                            <MonitoringSearchRow gridSizes={gridSizes}>
                                <MonitoringSearchDateTime label={"Create Date Time:"} format={DTFormat} value={createDt} setValue={setCreateDt} />
                            </MonitoringSearchRow>
                            <MonitoringSearchRow gridSizes={gridSizes}>
                                <MonitoringSearchInput label={"Machine ID:"} value={machId} setValue={setMachId} />
                            </MonitoringSearchRow>
                            <MonitoringSearchRow gridSizes={gridSizes}>
                                <MonitoringSearchInput label={"Error Message:"} value={errMsg} setValue={setErrMsg} multiline rows={2} />
                            </MonitoringSearchRow>
                            <MonitoringSearchRow gridSizes={gridSizes}>
                                <MonitoringSearchDateTime label={"SLA Start Time:"} format={DTFormat} value={slaDt} setValue={setSlaDt} />
                            </MonitoringSearchRow>
                            <MonitoringSearchRow gridSizes={gridSizes} alignItems={"flex-end"}>
                                <MonitoringSearchDateTime label={"Resolution Time:"} format={DTFormat} value={resDt} setValue={setResDt} disabled />
                                <Button variant={"contained"} onClick={OnCreateJobClicked} disabled={sreId.length <= 0} fullWidth>Submit</Button>
                            </MonitoringSearchRow>
                        </MonitoringSearchCriteria>
                    </Stack>
                </NewTableBox>
            </AppContainer>
            <ConfirmationDialog open={openConfirmation} onClose={OnConfirmClose} confirmationMsg={"Confirm to create test job ticket?"} onConfirm={OnConfirmSubmit} />
        </>
    )
}
import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Stack, Typography, DialogTitle } from '@mui/material';
import { WarningTwoTone } from '@mui/icons-material';

export default function ConfirmationDialog ({ open, onClose, title, onConfirm, confirmationMsg }){
    return (
      <Dialog open={Boolean(open)} onClose={onClose}>
        {
          title &&
          <DialogTitle sx={{borderBottom: "1px solid lightgray"}} pl={1} pr={1} ml={1} mr={1} textAlign={"center"}>{title}</DialogTitle>
        }
        <DialogContent>
          <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
            <WarningTwoTone sx={{fontSize: "5rem"}} color={"warning"} />
            <Typography whiteSpace={"pre-wrap"} textAlign={"center"}>
              {confirmationMsg}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{justifyContent: "center"}}>
          <Button variant={"contained"} onClick={onConfirm} autoFocus>Yes</Button>
          <Button variant={"contained"} onClick={onClose}>No</Button>
        </DialogActions>
      </Dialog>
    );
};
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Paper, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/slices/tempLoginSlice";
import { useNavigate } from "react-router-dom";
import { NavPaths } from "../../constants";
import { GetCookie } from "../../Utils";

const GetRedirectPath = (defaultPath = "") => {
  let url = new URL(window.location);
  return url.searchParams.get("redirect") ?? defaultPath;
}

export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [show, setShow] = React.useState(false);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [rememberMe, setRememberMe] = React.useState(false);
    const [goPartsHistory, setGoPartsHistory] = React.useState(false);
    const [invalidCred, setInvalidCred] = React.useState(false);

    React.useEffect(() => {
        if (GetCookie("localAuth") !== undefined)
        {
          let redirPath = GetRedirectPath();
          if (redirPath !== "")
          {
            navigate(redirPath);
          }
        }
        else
        {
          setShow(true);
        }
    }, []);
  
    const OnUsernameChanged = (e) => {
      setUsername(e.target.value);
    }
  
    const OnPasswordChanged = (e) => {
      setPassword(e.target.value);
    }
    
    const OnLoginClicked = () => {
      let usernames = process.env.REACT_APP_TEMP_LOGIN.split('|');
  
      if (usernames.indexOf(username) >= 0 && password === process.env.REACT_APP_TEMP_PW)
      {
        dispatch(loginUser({ username: username, remember: rememberMe }));

        const path = goPartsHistory === true ? NavPaths.FSMMachPartsHistory : NavPaths.ExceptionDashboard;
        navigate(GetRedirectPath(`/${path}`));
      }
      else
      {
        setInvalidCred(true);
      }
    }
  
    const OnRememberMeChanged = (e, v) => {
      setRememberMe(v);
    }

    const OnGoPartsHistoryChanged = (e, v) => {
      setGoPartsHistory(v);
    }
  
    const OnKeyDown = (e) => {
      if (e.code.includes("Enter"))
      {
        OnLoginClicked();
      }
    }

    return (
        <>
          {
            show === true &&
            <Box display={"flex"} justifyContent={"center"}>
                <Paper sx={{width: '500px', padding: 3, borderRadius: 4, boxShadow: "0 0 8px rgba(0, 0, 0, 0.4)", margin: "3em auto"}}>
                    <Stack spacing={2} alignItems={"center"}>
                        <Typography variant={"h4"} fontFamily={"Helvetica, Arial, sans-serif"}>Sign in to RI FSM Dashboard</Typography>
                        <Divider sx={{width: '100%'}} />
                        <FormControl>
                            <Typography textAlign={"center"} fontFamily={"Helvetica, Arial, sans-serif"} fontWeight={"bold"} fontSize={"20px"}>Username</Typography>
                            <TextField size={"small"} value={username} onChange={OnUsernameChanged} />
                        </FormControl>
                        <FormControl>
                            <Typography textAlign={"center"} fontFamily={"Helvetica, Arial, sans-serif"} fontWeight={"bold"} fontSize={"20px"}>Password</Typography>
                            <TextField type={"password"} size={"small"} value={password} onChange={OnPasswordChanged} onKeyDown={OnKeyDown} />
                        </FormControl>
                        {
                            invalidCred === true &&
                            <Typography variant="p" textAlign={"center"} color={"red"}>Invalid Username or Password</Typography>
                        }
                        <Divider sx={{width: '100%'}} />
                        <Stack spacing={1} direction={"row"}>
                        <FormControlLabel control={<Checkbox value={goPartsHistory} checked={goPartsHistory} onChange={OnGoPartsHistoryChanged} />} label={"Go Parts History"} />
                        <FormControlLabel control={<Checkbox value={rememberMe} checked={rememberMe} onChange={OnRememberMeChanged} />} label={"Remember Me"} />
                        <Button color={"success"} variant={"contained"} onClick={OnLoginClicked}>Login</Button>
                        </Stack>
                    </Stack>
                </Paper>
            </Box>
          } 
        </>
    )
}
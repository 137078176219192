import { Button, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { add, format, isAfter, isBefore, isToday, startOfDay, startOfMinute } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReasons, selectReasons, selectReasonsFetching } from "../../redux/slices/reasonSlice";
import { bookRoom, selectRefresh } from "../../redux/slices/timeslotSlice";
import { selectRooms } from "../../redux/slices/roomSlice";
import BaseRoomBookingModal from "./BaseRoomBookingModal";
import BookingInputLine from "./BookingInputLine";

export default function RoomBookingModal(props)
{
    const { open, onClose, room, date } = props;
    const dispatch = useDispatch();

    const reasonsFetching = useSelector(selectReasonsFetching);
    const reasons = useSelector(selectReasons);

    const refresh = useSelector(selectRefresh);

    const rooms = useSelector(selectRooms) ?? [];

    const [purpose, setPurpose] = React.useState(-1);
    const [bookedBy, setBookedBy] = React.useState("");
    const [startTime, setStartTime] = React.useState(date);
    const [endTime, setEndTime] = React.useState(date);

    const [minTime, setMinTime] = React.useState(date);
    const [maxTime, setMaxTime] = React.useState(date);

    const [alertMessage, setAlertMessage] = React.useState("");

    const roomName = rooms.find(r => r.room_id === room)?.room_desc;

    React.useEffect(() => {
        if (reasonsFetching !== true)
        {
            dispatch(fetchReasons());
        }
    }, []);

    React.useEffect(() => {
        const max = add(startOfDay(date), { hours: 20 });

        const _minTime = isToday(date)
            ? date
            : add(startOfDay(date), { hours: 9 });

        setMinTime(_minTime);
        setMaxTime(max);
    }, [date]);

    React.useEffect(() => {
        if (refresh === true) OnClose();
    }, [refresh]);

    React.useEffect(() => {
        setPurpose(reasons[0]?.reason_id);
    }, [reasons]);

    React.useEffect(() => {
        if (open !== true) return;

        let startDate = new Date();

        if (!isToday(date))
        {
            startDate = add(startOfDay(date), { hours: 9 });
        }

        let endDate = add(startDate, { hours: 1 });

        setStartTime(startDate);
        setEndTime(endDate);
    }, [open]);

    const OnClose = () => {
        setPurpose(reasons[0].reason_id);
        setBookedBy("");
        setAlertMessage("");
        onClose();
    }

    const OnPurposeChanged = (e) => {
        setPurpose(e.target.value);
    }

    const OnBookedByChanged = (e) => {
        setBookedBy(e.target.value);
    }

    const OnStartTimeChanged = (v) => {
        setStartTime(startOfMinute(v));
    }
    
    const OnEndTimeChanged = (v) => {
        setEndTime(startOfMinute(v));
    }

    const OnSaveClicked = () => {
        setAlertMessage("");

        if (purpose <= 0) return;
        if (bookedBy.length <= 0) return;

        if (isBefore(startTime, minTime) && !isToday(date))
        {
            setAlertMessage("Start Time cannot be before 9AM");
            return;
        }

        if (isAfter(endTime, maxTime))
        {
            setAlertMessage("End Time cannot be after 8PM");
            return;
        }

        if (isToday(date))
        {
            if (isBefore(startOfMinute(startTime), startOfMinute(date)))
            {
                setAlertMessage("Start Time cannot be in the past.");
                return;
            }
        }

        if (isAfter(startTime, endTime))
        {
            setAlertMessage("Start Time must be before End Time.");
            return;
        }
        
        const payload = {
            roomId: room,
            reasonId: purpose,
            bookedBy: bookedBy,
            startTime: format(startTime, "yyyy-MM-dd HH:mm:ss"),
            endTime: format(endTime, "yyyy-MM-dd HH:mm:ss")
        };

        dispatch(bookRoom(payload));
    }

    return (
        <BaseRoomBookingModal open={Boolean(open)} OnClose={OnClose} title={`Booking ${roomName} @ ${format(date ?? new Date(), "dd/MM/yyyy")}`} alert={alertMessage}>
            <Stack spacing={2}>
                <BookingInputLine text={"Purpose of Booking"} small>
                    <InputLabel id="purpose-label">Purpose of Booking</InputLabel>
                    <Select labelId="purpose-label" label="Purpose of Booking" value={purpose} onChange={OnPurposeChanged}>
                        {
                            reasons.map((r) => {
                                return (
                                    <MenuItem key={`reason-${r.reason_id}`} value={r.reason_id}>{r.reason_desc}</MenuItem>
                                )
                            })
                        }
                    </Select>   
                </BookingInputLine>
                <BookingInputLine text={"Booked By"}>
                    <TextField size="small" value={bookedBy} onChange={OnBookedByChanged} error={bookedBy.length <= 0} helperText={bookedBy.length > 0 ? "" : "Booked By must not be empty"}/>
                </BookingInputLine>
                <BookingInputLine text={"Start Time"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DemoContainer components={['TimePicker']}>
                            <TimePicker label="Start Time" onChange={OnStartTimeChanged} value={startTime} minTime={minTime} maxTime={maxTime} />
                        </DemoContainer>
                    </LocalizationProvider>
                </BookingInputLine>
                <BookingInputLine text={"End Time"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DemoContainer components={['TimePicker']}>
                            <TimePicker label="End Time" onChange={OnEndTimeChanged} value={endTime} minTime={minTime} maxTime={maxTime} />
                        </DemoContainer>
                    </LocalizationProvider>
                </BookingInputLine>
                <Button variant="contained" color="success" onClick={OnSaveClicked}>Save</Button>
            </Stack>
        </BaseRoomBookingModal>
    )
}
import { createAsyncThunk, createSlice,} from "@reduxjs/toolkit";
import { APICall, BackendType,FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchChartData = createAsyncThunk("charts", async (indicator) => {
    return await APICall(BackendType.MnC, `fsm/charts/${indicator}`)
})

export const chartSlice = createSlice({
    name: Slices.Charts,
    initialState: DefaultThunkState(),
    extraReducers: (builder) => {
        builder
        .addCase(fetchChartData.pending, PendingThunk())
        .addCase(fetchChartData.fulfilled,FulfilledThunk())
        .addCase(fetchChartData.rejected, FulfilledThunk())

    }
})

export { fetchChartData,}
export default chartSlice.reducer
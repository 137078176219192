import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectRefresh, extendMeeting } from "../../redux/slices/timeslotSlice";
import { add, format, isAfter, isBefore, startOfDay, startOfMinute } from "date-fns";
import { Button, Stack } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import BaseRoomBookingModal from "./BaseRoomBookingModal";
import BookingInputLine from "./BookingInputLine";

export default function ExtendMeetingModal(props) {
    const {open, onClose , room, date, nextMeeting} = props;
    const dispatch = useDispatch();
    const refresh = useSelector(selectRefresh);

    //setter
    const [startTime, setStartTime] = React.useState(date);
    const [endTime,setEndTime] = React.useState(date);
    const [newEndTime,setNewEndTime] = React.useState(date);
    const [minTime, setMinTime] = React.useState(date);
    const [maxTime, setMaxTime] = React.useState(date);
    const [alertMessage, setAlertMessage] = React.useState("");
    
    //To Close on refresh 
    React.useEffect(() => {
        if (refresh === true) OnClose();
    },[refresh]);

    //Time selection
    React.useEffect(() => {
        const max = add(startOfDay(date),{hours: 20});

        setMinTime(new Date(nextMeeting.End));
        setMaxTime(max);
    },[date]);

    //when it opens
    React.useEffect(() => {
        if (open !== true) return;

        let startDate = new Date(nextMeeting.Start);
        let endDate = new Date(nextMeeting.End);

        setStartTime(startDate);
        setEndTime(endDate);
        setNewEndTime(endDate);
    }, [open]);

    const OnClose = () => {
        setAlertMessage("");
        onClose();
    }

    const OnNewEndTimeChanged = (v) => {
        setNewEndTime(startOfMinute(v));
    }
    
    const OnSaveClicked = () => {
        setAlertMessage("");

        if (isAfter(newEndTime, maxTime))
        {
            setAlertMessage("End Time cannot be after 8PM");
            return;
        }

        if (isBefore(newEndTime, endTime))
        {
            setAlertMessage("New End Time cannot be before original end time");
            return;
        }
        
        const payload = {
            roomId: room,
            startTime: format(startTime, "yyyy-MM-dd HH:mm:ss"),
            endTime: format(endTime, "yyyy-MM-dd HH:mm:ss"),
            newEndTime: format(newEndTime, "yyyy-MM-dd HH:mm:ss")
        };

        dispatch(extendMeeting(payload));
    }

    return(
        <BaseRoomBookingModal open={Boolean(open)} title={`Extending Meeting from ${format(endTime, "dd/MM/yyyy HH:mm:ss")}`} alert={alertMessage} OnClose={OnClose} >
            <Stack spacing={2}>
                <BookingInputLine text={"New End Time"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DemoContainer components={['TimePicker']}>
                            <TimePicker label="New End Time" onChange={OnNewEndTimeChanged} value={newEndTime} minTime={minTime} maxTime={maxTime} />
                        </DemoContainer>
                    </LocalizationProvider>
                </BookingInputLine>
                <Button variant="contained" color="success" onClick={OnSaveClicked}>Save</Button>
            </Stack>
        </BaseRoomBookingModal>
    )
}
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";
import { format } from "date-fns";

const fetchFsmMachPartsHistory = createAsyncThunk("fsmSreSummary", async ({startDate, endDate, machineSerial, partNo }) => {
  let url = `fsm/machinePartsHistory?startDt=${format(startDate, "yyyy-MM-dd")}&endDt=${format(endDate, "yyyy-MM-dd")}`;

  if (machineSerial.length > 0) {
    url += `&machineSerial=${machineSerial}`;
  }

  if (partNo.length > 0) {
    url += `&partNum=${partNo}`;
  }

  return await APICall(BackendType.MnC, url);
});

export const fsmMachPartsHistorySlice = createSlice({
  name: Slices.FsmMachPartsHistory,
  initialState: DefaultThunkState(),
  reducers: {
      clearFsmMachPartsHistoryData: (state, action) => {
          state.data = [];
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmMachPartsHistory.pending, PendingThunk())
      .addCase(fetchFsmMachPartsHistory.fulfilled, FulfilledThunk())
      .addCase(fetchFsmMachPartsHistory.rejected, FulfilledThunk());
  },
});

const selectFSMMachPartsHistory = createSelector(
  [ state => state[Slices.FsmMachPartsHistory].data ], (d) => d
);

const selectFSMMachPartsHistoryFetching = createSelector(
  [ state => state[Slices.FsmMachPartsHistory].fetching ], (d) => d
);

export { fetchFsmMachPartsHistory, selectFSMMachPartsHistory, selectFSMMachPartsHistoryFetching };
export const { clearFsmMachPartsHistoryData } = fsmMachPartsHistorySlice.actions;
export default fsmMachPartsHistorySlice.reducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux"
import { setSnackbar } from "../redux/slices/appSlice";
import { createSelector } from "@reduxjs/toolkit";

export default function useThunk(sliceName, thunk, dataName) {
    const dispatch = useDispatch();

    const selectFetching = createSelector(
        [ state => state[sliceName].fetching ], (f) => f
    );

    const selectMessage = createSelector(
        [ state => state[sliceName].message ], (m) => m
    );

    const selectData = createSelector(
        [ state => state[sliceName][`${dataName ?? "data"}`] ], (d) => d
    );

    const selectCrudSuccess = createSelector(
        [ state => state[sliceName].crudSuccess ], (c) => c
    );

    const fetching = useSelector((state) => selectFetching(state));
    const message = useSelector((state) => selectMessage(state));
    const data = useSelector((state) => selectData(state));
    const crudSuccess = useSelector((state) => selectCrudSuccess(state));

    React.useEffect(() => {
        if (thunk === undefined) return;
        if (thunk === null) return;
        
        if (fetching !== true)
        {
            dispatch(thunk);
        }
    }, []);

    React.useEffect(() => {
        if (message?.length > 0)
        {
            dispatch(setSnackbar({
                open: true,
                message: message,
                severity: "error"
            }));
        }
    }, [message]);

    React.useEffect(() => {
        if (crudSuccess === null) return;
        if (crudSuccess === undefined) return;
        if (crudSuccess !== true) return;

        dispatch(setSnackbar({
            open: true,
            message: "Success",
            severity: "success"
        }));
    }, [crudSuccess]);

    return {
        fetching: fetching,
        message: message,
        data: data
    };
}
import { Button, Divider, Stack } from "@mui/material";
import AppContainer from "../AppContainer";
import NewTableBox from "../NewTableBox";
import CollapseWrap from "../CollapseWrap";
import MonitoringSearchCriteria, { MonitoringSearchCheckbox, MonitoringSearchDate, MonitoringSearchDateTime, MonitoringSearchInput, MonitoringSearchRow, MonitoringSearchSelect, MonitoringSearchText } from "../MonitoringSearchCriteria";
import ChipStack from "../ChipStack";
import NewTable from "../NewTable";
import React from "react";
import { add, endOfMonth, format, parse, startOfMonth } from "date-fns";
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from "../../redux/slices/appSlice";
import ConfirmationDialog from "../ConfirmationDialog";
import { fetchFsmManualJobs, resetFsmManualJobState, selectFsmCreateManualJobCrudSuccess, selectFsmCreateManualJobMessage, selectFsmManualJobs, selectFsmManualJobsFetching, submitFsmManualJob } from "../../redux/slices/fsmManualJobSlice";
import { DefaultTableCellStyle } from "../../constants";
import { ParseDBDate, SanitizeInput } from "../../Utils";

const gridSizes = [9, 3];

const manualJobColumns = [
    { ...DefaultTableCellStyle, label: 'Created On', width: "140px", sortable: true, type: Date, dateFormat: "dd/MM/yyyy HH:mm:ss" },
    { ...DefaultTableCellStyle, label: 'Customer', width: "80px", sortable: true },
    { ...DefaultTableCellStyle, label: 'SRE ID', sortable: true },
    { ...DefaultTableCellStyle, label: 'Ticket Type', sortable: true },
    { ...DefaultTableCellStyle, label: 'Machine ID', sortable: true },
    { ...DefaultTableCellStyle, label: 'Error Message', sortable: true },
    { ...DefaultTableCellStyle, label: 'SLA Start Time', width: "140px", sortable: true, type: Date, dateFormat: "dd/MM/yyyy HH:mm:ss" },
    { ...DefaultTableCellStyle, label: 'Resolution Time', width: "140px", sortable: true, type: Date, dateFormat: "dd/MM/yyyy HH:mm:ss" },
    { ...DefaultTableCellStyle, label: 'SLM/PM Meet Time', width: "140px", sortable: true, type: Date, dateFormat: "dd/MM/yyyy HH:mm:ss" },
    { ...DefaultTableCellStyle, label: 'Action', width: "100px" }
];

const BankNames = [
    { label: "HSBC", value: "HSBC" },
    { label: "HASE", value: "HASE" },
    { label: "BOC", value: "BOC" },
    { label: "NCB", value: "NCB" },
    { label: "CYB", value: "CYB" },
    { label: "CHB", value: "CHB" },
];

const TicketTypes = [
    { label: "Second Line Maintenance", value: "Second Line Maintenance" },
    { label: "Health Check", value: "Health Check" },
    { label: "Preventive Maintenance", value: "Preventive Maintenance" },
    { label: "Search Cheque", value: "Search Cheque" },
    { label: "Search Card", value: "Search Card" },
    { label: "Search Money", value: "Search Money" },
    { label: "Dump Log", value: "Dump Log" },
    { label: "Check Cover", value: "Check Cover" },
    { label: "Check Lock", value: "Check Lock" },
    { label: "Software Support", value: "Software Support" },
];

const DTFormat = "dd/MM/yyyy HH:mm";
const PayloadDTFormat = "yyyy-MM-dd HH:mm:ss";

export default function FSMManualJobTicket({isMobile}) {
    const dispatch = useDispatch();

    const [bank, setBank] = React.useState(BankNames[0].value);
    const [isReply, setIsReply] = React.useState(false);
    const [sreId, setSreId] = React.useState("");
    const [ticketType, setTicketType] = React.useState(TicketTypes[0].value);
    const [createDt, setCreateDt] = React.useState(new Date());
    const [machId, setMachId] = React.useState("");
    const [errMsg, setErrMsg] = React.useState("");
    const [slaDt, setSlaDt] = React.useState(new Date());
    const [resDt, setResDt] = React.useState(new Date());

    const [disableReply, setDisableReply] = React.useState(true);

    const [searchBank, setSearchBank] = React.useState(BankNames[0].value);
    const [searchStartDt, setSearchStartDt] = React.useState(startOfMonth(new Date()));
    const [searchEndDt, setSearchEndDt] = React.useState(endOfMonth(new Date()));
    const [searchSreId, setSearchSreId] = React.useState("");
    const [searchRows, setSearchRows] = React.useState([]);

    const [filterChips, setFilterChips] = React.useState([]);

    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const crudSuccess = useSelector(selectFsmCreateManualJobCrudSuccess);
    const msg = useSelector(selectFsmCreateManualJobMessage);

    const manualJobs = useSelector(selectFsmManualJobs);
    const manualJobsFetching = useSelector(selectFsmManualJobsFetching);

    React.useEffect(() => {
        return () => {
            dispatch(resetFsmManualJobState());
        }
    }, []);

    React.useEffect(() => {
        if (bank === "HSBC" || bank === "HASE")
        {
            setIsReply(false);
            setDisableReply(true);
        }
        else
        {
            setDisableReply(false);
        }
    }, [bank]);

    React.useEffect(() => {
        const FormatDt = (dt) => {
            return ParseDBDate(dt, "d/MM/yyyy hh:mm:ss a", "dd/MM/yyyy HH:mm:ss");
        }

        const _rows = manualJobs.map((r) => {
            return [
                { ...DefaultTableCellStyle, label: FormatDt(r.Timestamp)},
                { ...DefaultTableCellStyle, label: r.CustomerName },
                { ...DefaultTableCellStyle, label: r.SreId },
                { ...DefaultTableCellStyle, label: r.TicketType },
                { ...DefaultTableCellStyle, label: r.MachineId },
                { ...DefaultTableCellStyle, label: r.ErrorMessage },
                { ...DefaultTableCellStyle, label: FormatDt(r.SLAStartDt) },
                { ...DefaultTableCellStyle, label: FormatDt(r.ResolutionDt) },
                { ...DefaultTableCellStyle, label: FormatDt(r.SLMPMMeetDt) },
                { ...DefaultTableCellStyle, label: "" },
            ];
        });

        setSearchRows(_rows);
    }, [manualJobs]);

    React.useEffect(() => {
        setResDt(add(slaDt, { hours: 2 }));
    }, [slaDt]);

    React.useEffect(() => {
        setSlaDt(createDt);
    }, [createDt]);

    React.useEffect(() => {
        if (crudSuccess === false)
        {
            if (msg.length > 0)
            {
                dispatch(setSnackbar({
                    open: true,
                    message: msg,
                    severity: "error"
                }));
            }
        }
        else if (crudSuccess === true)
        {
            dispatch(setSnackbar({
                open: true,
                message: `Manual Mail sent for ${sreId}`,
                severity: "success"
            }));

            setBank(BankNames[0].value);
            setIsReply(false);
            setSreId("");
            setTicketType(TicketTypes[0].value);
            setCreateDt(new Date());
            setMachId("");
            setErrMsg("");
        }
    }, [crudSuccess]);

    const AutoGenerateSreId = () => {
        let replyNum = isReply === true ? '5' : '6';
        let now = format(new Date(), "yyMMddHHmmss");

        setSreId(`999${replyNum}${now}`);
    }

    const OnCollapse = () => {
        let _dt = `${format(searchStartDt, "dd/MM/yyyy")} - ${format(searchEndDt, "dd/MM/yyyy")}`;

        let _filter = [];
        _filter.push(searchBank);
        _filter.push(_dt);

        if (searchSreId.length > 0)
        {
            _filter.push(searchSreId);
        }

        setFilterChips(_filter);
    }

    const OnUncollapse = () => {
        setFilterChips([]);
    }

    const OnCreateJobClicked = () => {
        let _errMsg = "";

        if (sreId.length <= 0)
        {
            _errMsg = "SRE ID cannot be empty";
        }
        else if (machId.length <= 0)
        {
            _errMsg = "Machine ID cannot be empty";
        }
        else if (errMsg.length <= 0)
        {
            _errMsg = "Error Message cannot be empty";
        }

        if (_errMsg.length > 0)
        {
            let payload = {
                open: true,
                message: _errMsg,
                severity: "error"
            };

            dispatch(setSnackbar(payload));
            return;
        }

        setOpenConfirmation(true);
    }

    const OnSearchClicked = () => {
        const payload = {
            bank: searchBank,
            start: searchStartDt,
            end: searchEndDt,
            sreid: SanitizeInput(searchSreId)
        };

        dispatch(fetchFsmManualJobs(payload));
    }

    const OnConfirmClose = () => {
        setOpenConfirmation(false);
    }

    const OnConfirmSubmit = () => {
        const payload = {
            bank: bank,
            sreId: sreId,
            ticketType: ticketType,
            createDT: format(createDt, PayloadDTFormat),
            machId: machId,
            errMsg: errMsg,
            slaDT: format(slaDt, PayloadDTFormat),
            resolutionDT: format(resDt, PayloadDTFormat)
        }

        setOpenConfirmation(false);
        dispatch(submitFsmManualJob(payload));
    }

    return (
        <>
            <AppContainer>
                <NewTableBox title={"FSM Create & View Manual Job Ticket"} height={"100%"}>
                    <Stack spacing={1} height={"100%"}>
                        <CollapseWrap defaultShow onShow={OnUncollapse} onHide={OnCollapse}>
                            <Stack direction={isMobile === true ? "column" : "row"} spacing={1}>
                                <MonitoringSearchCriteria isMobile={isMobile} title={"Create Job Ticket"}>
                                    <MonitoringSearchRow gridSizes={gridSizes}>
                                        <MonitoringSearchSelect label={"Bank Name:"} value={bank} setValue={setBank} items={BankNames} />
                                    </MonitoringSearchRow>
                                    <MonitoringSearchRow gridSizes={gridSizes}>
                                        <MonitoringSearchCheckbox label={"Reply Mail:"} value={isReply} setValue={setIsReply} labelPosition={"start"} disabled={disableReply} />
                                    </MonitoringSearchRow>
                                    <MonitoringSearchRow gridSizes={gridSizes}>
                                        <MonitoringSearchInput label={"SRE ID:"} value={sreId} setValue={setSreId} disabled />
                                        <Button variant={"contained"} onClick={AutoGenerateSreId} fullWidth>Auto Generate</Button>
                                    </MonitoringSearchRow>
                                    <MonitoringSearchRow gridSizes={gridSizes}>
                                        <MonitoringSearchSelect label={"Ticket Type:"} value={ticketType} setValue={setTicketType} items={TicketTypes} />
                                    </MonitoringSearchRow>
                                    <MonitoringSearchRow gridSizes={gridSizes}>
                                        <MonitoringSearchDateTime label={"Create Date Time:"} format={DTFormat} value={createDt} setValue={setCreateDt} />
                                    </MonitoringSearchRow>
                                    <MonitoringSearchRow gridSizes={gridSizes}>
                                        <MonitoringSearchInput label={"Machine ID:"} value={machId} setValue={setMachId} />
                                    </MonitoringSearchRow>
                                    <MonitoringSearchRow gridSizes={gridSizes}>
                                        <MonitoringSearchInput label={"Error Message:"} value={errMsg} setValue={setErrMsg} multiline rows={2} />
                                    </MonitoringSearchRow>
                                    <MonitoringSearchRow gridSizes={gridSizes}>
                                        <MonitoringSearchDateTime label={"SLA Start Time:"} format={DTFormat} value={slaDt} setValue={setSlaDt} />
                                    </MonitoringSearchRow>
                                    <MonitoringSearchRow gridSizes={gridSizes} alignItems={"flex-end"}>
                                        <MonitoringSearchDateTime label={"Resolution Time:"} format={DTFormat} value={resDt} setValue={setResDt} disabled />
                                        <Button variant={"contained"} onClick={OnCreateJobClicked} disabled={sreId.length <= 0} fullWidth>Submit</Button>
                                    </MonitoringSearchRow>
                                </MonitoringSearchCriteria>
                                <MonitoringSearchCriteria isMobile={isMobile}>
                                    <MonitoringSearchRow gridSizes={gridSizes}> 
                                        <MonitoringSearchSelect label={"Bank Name:"} value={searchBank} setValue={setSearchBank} items={BankNames} />
                                    </MonitoringSearchRow>
                                    <MonitoringSearchRow gridSizes={gridSizes}>
                                        <MonitoringSearchDate label={"Start Date:"} value={searchStartDt} setValue={setSearchStartDt} />
                                    </MonitoringSearchRow>
                                    <MonitoringSearchRow gridSizes={gridSizes}>
                                        <MonitoringSearchDate label={"End Date:"} value={searchEndDt} setValue={setSearchEndDt} minDate={searchStartDt} maxDate={add(searchStartDt, { days: 30 })} />
                                        <MonitoringSearchText color={"red"} fontStyle={"italic"} >*limit up to 30 days only!</MonitoringSearchText>
                                    </MonitoringSearchRow>
                                    <MonitoringSearchRow gridSizes={gridSizes}>
                                        <MonitoringSearchInput label={"SRE ID:"} value={searchSreId} setValue={setSearchSreId} />
                                        <Button variant={"contained"} onClick={OnSearchClicked} fullWidth>Submit</Button>
                                    </MonitoringSearchRow>
                                </MonitoringSearchCriteria>
                            </Stack>
                        </CollapseWrap>
                        <ChipStack direction={"row"} size={"small"} chips={filterChips} />
                        <Divider />
                        <NewTable columns={manualJobColumns} rows={searchRows} pageSizeOptions={[10, 20, 30, 40, 50]} loading={manualJobsFetching} />
                    </Stack>
                </NewTableBox>
            </AppContainer>
            <ConfirmationDialog open={openConfirmation} onClose={OnConfirmClose} confirmationMsg={"Confirm to create job ticket?"} onConfirm={OnConfirmSubmit} />
        </>
    )
}
import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from "@mui/material";
import AppContainer from "../AppContainer";
import TableContainer from "../TableContainer";
import ContainedButton from "../ContainedButton";
import { SiteColors, Slices } from "../../constants";
import { Add, Save } from "@mui/icons-material";
import React from "react";
import CRUDDataGrid from "../DataGrid/CRUDDataGrid";
import SeverityTag from "../SeverityTag";
import EditColumnSelect from "../DataGrid/EditColumnSelect";
import useThunk from "../../CustomHooks/useThunk";
import { fetchSms, saveSms } from "../../redux/slices/exceptionSettingsSmsSlice";
import { fetchExceptionMail, saveExceptionMail } from "../../redux/slices/exceptionSettingsMailSlice";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/slices/appSlice";
import { fetchExceptionNotification } from "../../redux/slices/exceptionSettingsNotificationSlice";
import EditInput from "../DataGrid/EditInput";

const NotifyOptions = [{"Id":1,"Label":"SMS"}, {"Id": 2, "Label": "EMAIL"}, {"Id": 3, "Label": "WHATSAPP"}];
const SeverityOptions = [{"Id":1,"Label":"Low"},{"Id":2,"Label":"Medium"},{"Id":3,"Label":"High"}];

const notifyModeRenderer = (params) => {
    if (!params.value) return params.value;
    
    return (
        <Typography>
            {
                params.value.map((v) => NotifyOptions.find(x => x.Id === v).Label).join()
            }
        </Typography>
    )
}

const SeverityCellItem = ({severityIds, wrap = false}) => {
    return (
        <Box display={"flex"} flexWrap={wrap === true ? "wrap" : "nowrap"}>
            {
                severityIds.map((v) => {
                    const severity = SeverityOptions.find(s => s.Id === v);

                    return (
                        <Box key={`edit-severity-${v}`} sx={{mr: 1}}>
                            <SeverityTag severity={severity.Label} />
                        </Box>
                    )
                })
            }
        </Box>
    )
}

const severitiesCellRenderer = (params) => {
    if (!params.value) return params.value;

    return (
        <SeverityCellItem severityIds={params.value} />
    )
}

const RenderEditCell = (type) => (params) => {
    console.log(params);
    switch (type)
    {
        case "Notify":
            return ( <EditColumnSelect {...params} multiple valueOptions={NotifyOptions} /> );
        case "Severity":
            return ( <EditColumnSelect {...params} multiple valueOptions={SeverityOptions} renderCell={(selected) => <SeverityCellItem severityIds={selected} wrap />} /> );
        case "String":
            return ( <EditInput {...params} errorText={"Cannot be empty"} isErrorCallback={(val) => val.length <= 0} /> )
        case "Email":
            return ( <EditInput {...params} errorText={"Invalid format"} isErrorCallback={(val) => typeof(val) === "string" ? !val.match(/[\w+]@\w+[.]\w+/) : val.length <= 0} /> )
    }
}

const ComPorts = [1, 2, 3, 4, 5];
const BaudRates = ["9600", "19200", "38400"];

const columns = [
    { field: 'Username', headerName: 'Staff', type: "string", width: 200, editable: true,  renderEditCell: RenderEditCell("String") },
    { field: 'Mobile', headerName: 'Mobile No.', type: "string", width: 140, editable: true, renderEditCell: RenderEditCell("String") },
    { field: 'Email', headerName: 'Email Address', type: "string", width: 200, editable: true, renderEditCell: RenderEditCell("Email") },
    { field: 'NotifyModes', headerName: 'Notify Mode', width: 200, renderCell: notifyModeRenderer, renderEditCell: RenderEditCell("Notify") , editable: true },
    { field: 'ExceptionSeverities', headerName: 'Severity', width: 200, renderCell: severitiesCellRenderer, renderEditCell: RenderEditCell("Severity") , editable: true }
]

export default function ExceptionSettings({isMobile}) {
    const dispatch = useDispatch();

    const { data: smsData } = useThunk(Slices.ExceptionSettingsSms, fetchSms());
    const { data: mailData } = useThunk(Slices.ExceptionSettingsMail, fetchExceptionMail());
    const { data: notifyData } = useThunk(Slices.ExceptionSettingsNotification, fetchExceptionNotification());

    // SMS
    const [smsCom, setSMSCom] = React.useState(ComPorts[0]);
    const [smsBR, setSMSBr] = React.useState(BaudRates[0]);
    const [smsTimeout, setSMSTimeout] = React.useState(0);

    // Mail
    const [mailServer, setMailServer] = React.useState("");
    const [mailPort, setMailPort] = React.useState(0);
    const [mailAddress, setMailAddress] = React.useState("");
    const [mailUsername, setMailUsername] = React.useState("");
    const [mailPassword, setMailPassword] = React.useState("");

    const [mailServerErr, setMailServerErr] = React.useState(false);
    const [mailAddressErr, setMailAddressErr] = React.useState(false);
    const [mailUsernameErr, setMailUsernameErr] = React.useState(false);
    const [mailPasswordErr, setMailPasswordErr] = React.useState(false);

    // Notification
    const [notifyRows, setNotifyRows] = React.useState([]);
    const [isAdding, setIsAdding] = React.useState(false);

    React.useEffect(() => {
        const _rows = notifyData.map((n) => {
            return {
                id: n.UserId,
                Username: n.Username,
                Mobile: n.Mobile,
                Email: n.Email,
                NotifyModes: n.NotifyModes.map((nm) => nm.Id),
                ExceptionSeverities: n.ExceptionSeverities.map((es) => es.Id)
            }
        });

        setNotifyRows(_rows);
    }, [notifyData]);

    React.useEffect(() => {
        if (Object.keys(smsData).length <= 0) return;
        setSMSCom(smsData.ComPort);
        setSMSBr(smsData.BaudRate);
        setSMSTimeout(smsData.Timeout);
    }, [smsData]);

    React.useEffect(() => {
        if (Object.keys(mailData).length <= 0) return;
        setMailServer(mailData.MailServer);
        setMailPort(mailData.Port);
        setMailAddress(mailData.Address);
        setMailUsername(mailData.Username);
        setMailPassword(mailData.Password);
    }, [mailData]);

    React.useEffect(() => {
        setMailServerErr(mailServer.length <= 0);
    }, [mailServer]);

    React.useEffect(() => {
        setMailAddressErr(!mailAddress.match(/[\w+]@\w+[.]\w+/));
    }, [mailAddress])

    React.useEffect(() => {
        setMailUsernameErr(mailUsername.length <= 0);
    }, [mailUsername]);

    React.useEffect(() => {
        setMailPasswordErr(mailPassword.length <= 0);
    }, [mailPassword]);

    const OnInputChanged = (setState) => ({ target: { type, value, min, max } }) => {
        let newVal = value;

        if (type === "number")
        {
            newVal = Number(value);

            if (max && value > Number(max))
            {
                newVal = Number(max);
            }

            if (min && Number(min) > value)
            {
                newVal = Number(min);
            }
        }

        setState(newVal);
    }

    const OnSMSSave = () => {
        const payload = {
            "ComPort": smsCom,
            "BaudRate": smsBR,
            "Timeout": smsTimeout
        };

        dispatch(saveSms(payload));
    }

    const OnMailSave = () => {
        if (mailServerErr || mailAddressErr || mailUsernameErr || mailPasswordErr)
        {
            dispatch(setSnackbar({
                open: true,
                message: "Please fill in the necessary details",
                severity: "error"
            }))
            return;
        }

        const payload = {
            "MailServer": mailServer,
            "Port": mailPort,
            "Address": mailAddress,
            "Username": mailUsername,
            "Password": mailPassword
        };

        dispatch(saveExceptionMail(payload));
    }

    const OnNotificationAdd = () => {
        setIsAdding(true);
    }

    const OnSaveNotify = (e) => {
        console.log(e);
    }

    return (
        <>
            <AppContainer>
                <Stack spacing={2}>
                    <TableContainer title={"SMS Configuration"} button={<ContainedButton background={SiteColors.Blue} borderColor={SiteColors.DarkBlue} icon={<Save />} onClick={OnSMSSave}>Save</ContainedButton>}>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={6} md={4}>
                                <FormControl fullWidth size={"small"}>
                                    <InputLabel id={`sms-com-label`}>COM Port</InputLabel>
                                    <Select labelId={`sms-com-label`} value={smsCom} onChange={OnInputChanged(setSMSCom)} input={<OutlinedInput label={"COM Port"} />}>
                                        {
                                            ComPorts.map((com) => {
                                                return (
                                                    <MenuItem key={`sms-com-menu-item-${com}`} value={com}>{com}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <FormControl fullWidth size={"small"}>
                                    <InputLabel id={`sms-br-label`}>Baud Rate</InputLabel>
                                    <Select labelId={`sms-br-label`} value={smsBR} onChange={OnInputChanged(setSMSBr)} input={<OutlinedInput label={"Baud Rate"} />}>
                                        {
                                            BaudRates.map((br) => {
                                                return (
                                                    <MenuItem key={`sms-br-menu-item-${br}`} value={br}>{br}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <FormControl fullWidth>
                                    <TextField size={"small"} label={"Timeout"} type={"number"} inputProps={{max: 127, min: 0}} value={smsTimeout} onChange={OnInputChanged(setSMSTimeout)} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </TableContainer>
                    <TableContainer title={"Mail Configuration"} button={<ContainedButton background={SiteColors.Blue} borderColor={SiteColors.DarkBlue} icon={<Save />} onClick={OnMailSave}>Save</ContainedButton>}>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={6} md>
                                <FormControl fullWidth>
                                    <TextField size={"small"} label={"Mail Server"} error={mailServerErr} helperText={mailServerErr && "Cannot be empty"} value={mailServer} onChange={OnInputChanged(setMailServer)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md>
                                <FormControl fullWidth>
                                    <TextField size={"small"} type={"number"} label={"Port No"} inputProps={{min: 1}} value={mailPort} onChange={OnInputChanged(setMailPort)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md>
                                <FormControl fullWidth>
                                    <TextField size={"small"} label={"Mail Address"} value={mailAddress} error={mailAddressErr} helperText={mailAddressErr && "Invalid format"} onChange={OnInputChanged(setMailAddress)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md>
                                <FormControl fullWidth>
                                    <TextField size={"small"} label={"Username"} value={mailUsername} error={mailUsernameErr} helperText={mailUsernameErr && "Cannot be empty"} onChange={OnInputChanged(setMailUsername)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md>
                                <FormControl fullWidth>
                                    <TextField size={"small"} type={"password"} label={"Password"} error={mailPasswordErr} helperText={mailPasswordErr && "Cannot be empty"} value={mailPassword} onChange={OnInputChanged(setMailPassword)} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </TableContainer>
                    <TableContainer title={"Notification Settings"} button={<ContainedButton background={SiteColors.Green} borderColor={SiteColors.DarkGreen} icon={<Add />} onClick={OnNotificationAdd}>Add</ContainedButton>}>
                        <CRUDDataGrid data={notifyRows} columns={columns} focusColumn={"Username"} addingState={{isAdding, setIsAdding}} onSave={OnSaveNotify} />
                    </TableContainer>
                </Stack>
            </AppContainer>
        </>
    )
}
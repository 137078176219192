import styled from "@emotion/styled";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  List,
  ListItem as BaseListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Divider,
  Drawer,
  Menu,
  MenuItem,
  Collapse,
  Stack,
  Typography
} from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon, KeyboardArrowUp, KeyboardArrowDown, Person, ArrowDropDown, PowerSettingsNew, NumbersOutlined } from "@mui/icons-material";
import logo from "../Assets/ht.png";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Classes, NavItems, SodoSansStyled } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, selectUsername } from "../redux/slices/tempLoginSlice";

const LogoBtn = styled(Button)`
  padding: 0;
`;

const StyledToolbar = styled(Toolbar)`
  background-color: white;
  color: black;
`;

const DesktopMenuBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  .MuiButton-root {
    margin-left: 16px;
  }

  .MuiTypography-root {
    ${SodoSansStyled}
    text-transform: uppercase;
  }

  .header-nav {
    color: #606060;

    &:hover {
      color: black;
      background: none;

      .MuiTypography-root {
        font-weight: bold;
      }
    }
  }
`;

const StyledNavMenuItem = styled(MenuItem)`
  &:hover {
    background: #EDEBE9;
  }

  .nav-title {
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    color: black;
  }

  .nav-subtitle {
    font-size: 10px !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    color: black;
  }
`;

const NavBox = styled(Box)`
  white-space: nowrap;
`;

const NavBarButton = styled(Button, {
  shouldForwardProp: (props) => props !== "expanded",
})(
  ({ expanded }) => `
    color: ${expanded === "true" ? "blue" : "rgba(0, 0, 0, 0.87)"};
    text-transform: none;
    white-space: nowrap;

    .MuiBox-root {
        display: flex;

        ${expanded === "true" && "border-bottom: 1px blue solid;"}
    }

    .MuiSvgIcon-root {
        ${expanded === "true" && "transform: rotate(180deg);"}
    }
`
);

const MiddleClick = (e, it) => {
  if (e.button != 1) return;
  window.open(`${window.location.origin}/${it.path}`);
}

function BaseNavBar(props) {
  const { children } = props;

  // const navigate = useNavigate();

  const handleLogoClick = () => {
    // navigate("/");
  };

  return (
    <AppBar position="sticky">
      <StyledToolbar sx={{backgroundColor: "#f8f8f8 !important", border: "4px solid #e7e7e7"}}>
        <LogoBtn onClick={handleLogoClick}>
          <img src={logo} alt="Logo" />
        </LogoBtn>
        {children}
      </StyledToolbar>
    </AppBar>
  );
}

const NavItem = ({ item, handleClose, navClick, background }) => {
  const [expanded, setExpanded] = React.useState(false);

  const isExpandable = item.navItems?.length > 0;

  const OnClick = (it) => () => {
    handleClose();
    setExpanded(false);
    navClick(it.path);
  }

  const OnExpandableClick = (it) => () => {
    setExpanded(!expanded);
  }

  const OnMouseUp = (it) => (e, f) => {
    // Middle Mouse Click
    MiddleClick(e, it);
  }

  return (
    <>
      {
        isExpandable === true &&
        <>
          <MenuItem onClick={OnExpandableClick(item)}>
            <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
              <Typography>
                {item.text}
              </Typography>
              <Box>
                {
                  expanded === true 
                    ? <KeyboardArrowUp />
                    : <KeyboardArrowDown />
                }
              </Box>
            </Stack>
          </MenuItem>
          <Collapse in={expanded} sx={{background: background ?? 'lightgray'}} >
            {
              item.navItems.map((ni) => {
                return (
                  <MenuItem key={`nav-menu-${item.text}-${ni.text}`} onClick={OnClick(ni)} onMouseUp={OnMouseUp(ni)} sx={{borderBottom: '1px solid whitesmoke'}}>
                    <Box pl={1}>
                      {ni.text}
                    </Box>
                  </MenuItem>
                )
              })
            }
          </Collapse>
        </>
      }
      {
        isExpandable !== true &&
        <StyledNavMenuItem onClick={OnClick(item)} onMouseUp={OnMouseUp(item)}>
          <Stack>
            <Typography className={`nav-title ${Classes.sodosans}`}>{item.text}</Typography>
            <Typography className={`nav-subtitle ${Classes.sodosans}`}>{item.subtitle}</Typography>
          </Stack>
        </StyledNavMenuItem>
      }
    </>
  )
}

const UserButtonStyle = styled(Button)`
  text-transform: none;
  background: black;
  color: white;

  &:hover {
    background: dimgray;
  }
`;

const UserButton = (props) => {
  return (
    <UserButtonStyle {...props}></UserButtonStyle>
  )
}

function DesktopNavBar({hideNavItems}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navClick = (featureName) => {
    navigate(`/${featureName}`);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItems, setMenuItems] = React.useState([]);

  const [userAnchorEl, setUserAnchorEl] = React.useState(null);

  const username = useSelector(selectUsername);

  const handleClick = (navItems) => (event) => {
    setAnchorEl(event.currentTarget);
    setMenuItems(navItems);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserClick = (e) => {
    setUserAnchorEl(e.currentTarget);
  }

  const handleUserClose = () => {
    setUserAnchorEl(null);
  }

  const OnLogoutClicked = () => {
    dispatch(logoutUser());
    handleUserClose();
    navigate(`/login`);
  }

  const OnMouseUp = (it) => (e) => {
    if (it.path === undefined) return;
    MiddleClick(e, it);
  }

  React.useEffect(() => {
    if (username === undefined) {
      setAnchorEl(null);
      setMenuItems([]);
      setUserAnchorEl(null);
    }
  }, [username]);

  return (
    <>
      <BaseNavBar>
        <DesktopMenuBox>
          <NavBox>
            {
              hideNavItems !== true &&
              NavItems.map((item) => {
                return (
                  <NavBarButton
                    className={"header-nav"}
                    key={`navbar-button-${item.text}`}
                    onClick={
                      item.path === undefined ? handleClick(item.navItems) : () => navClick(item.path)
                    }
                    onMouseUp={OnMouseUp(item)}
                  >
                    <Typography>{item.text}</Typography>
                    {
                      item.path === undefined &&
                      <KeyboardArrowDown />
                    }
                  </NavBarButton>
                );
              })
            }
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {menuItems.map((item) => {
                return (
                  <NavItem key={`exception-nav-${item.text}`} item={item} handleClose={handleClose} navClick={navClick} />
                );
              })}
            </Menu>
          </NavBox>
          {
            username !== undefined &&
            <UserButton variant={"contained"} onClick={handleUserClick}>
              <Stack direction={"row"} spacing={1}>
                <Person />
                <Typography variant={"p"} sx={{textTransform: "none !important"}}>{username}</Typography>
                <ArrowDropDown />
              </Stack>
            </UserButton>
          }
        </DesktopMenuBox>
      </BaseNavBar>
      <Menu
        id="user-menu"
        anchorEl={userAnchorEl}
        open={Boolean(userAnchorEl)}
        onClose={handleUserClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={OnLogoutClicked}>
          <PowerSettingsNew />&nbsp;
          <Typography className={Classes.sodosans}>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

const MobileMenuBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .MuiIconButton-root {
    color: black;
  }
`;

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    z-index: 12;
  }

  .MuiListItem-root {
    padding-left: 0;
    padding-right: 0;
  }

  .MuiSvgIcon-root {
    color: black;
  }
`;

const DrawerHeader = styled(Box)`
  padding: 24px;
`;

const ListItem = styled(BaseListItem)`
  padding: 0;
`;

function MobileNavBar(props) {
  // Local States
  const [openDrawer, setOpenDrawer] = React.useState(false);
  
  const username = useSelector(selectUsername);

  // Event Handlers
  const OnDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const OnDrawerClose =() => {
    setOpenDrawer(false);
  }

  return (
    <>
      <BaseNavBar>
        <MobileMenuBox>
          {
            username !== undefined &&
            <Stack direction={"row"} spacing={1}>
              <Person />
              <Typography variant="p">{username}</Typography>
            </Stack>
          }
          <IconButton onClick={OnDrawerToggle}>
            {openDrawer === true ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </MobileMenuBox>
      </BaseNavBar>
      <MobileNavBarDrawer open={openDrawer} onClose={OnDrawerClose} hideNavItems={props.hideNavItems} />
    </>
  );
}

function MobileNavBarDrawer(props) {
  const { open, onClose, hideNavItems } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [expandText, setExpandText] = React.useState("");
  const [menuItems, setMenuItems] = React.useState([]);

  const username = useSelector(selectUsername);

  const navClick = (featureName) => {
    navigate(`/${featureName}`);
  };

  const OnClose = () => {
    setExpandText("");
    onClose();
  }

  const OnItemClicked = (item) => () => {
    const { text, path, navItems } = item;
    if (path === undefined)
    {
      expandText === text ? setExpandText("") : setExpandText(text);

      setMenuItems(navItems);
      return;
    }

    navClick(path);
  }
  
  const OnLogoutClicked = () => {
    dispatch(logoutUser());
    navigate('/login');
  }

  return (
    <StyledDrawer
      open={open}
      anchor="top"
      variant="persistent"
      PaperProps={{ elevation: 4 }}
    >
      <DrawerHeader />
      {
        hideNavItems !== true &&
        NavItems.map((item) => {
          return (
            <React.Fragment key={`mobile-nav-${item.text}`}>
              <List>
                <ListItem>
                  <ListItemButton onClick={OnItemClicked(item)}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                      <ListItemText primary={item.text} secondary={item.subtitle} />
                      {
                        item.path === undefined &&
                        <KeyboardArrowDown />
                      }
                    </Stack>
                  </ListItemButton>
                </ListItem>
                <Collapse in={expandText === item.text} sx={{background: "lightgray"}}>
                  {
                    menuItems.map((item) => {
                      return (
                        <Box key={`exception-nav-${item.text}`}  sx={{borderBottom: '2px solid whitesmoke'}}>
                          <NavItem item={item} navClick={navClick} handleClose={OnClose} background={ "gainsboro"} />
                        </Box>
                      )
                    })
                  }
                </Collapse>
              </List>
              <Divider />
            </React.Fragment>
          );
        })
      }
      {
        username !== undefined &&
        <List>
          <ListItem>
            <ListItemButton onClick={OnLogoutClicked}>
              <ListItemText>Logout</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      }
    </StyledDrawer>
  );
}

export default function AppNavBar(props) {
  const { isMobile } = props;
  const [isLogin, setIsLogin] = React.useState(false);

  const location = useLocation();

  React.useEffect(() => {
    setIsLogin(location.pathname === "/login");
  }, [location]);

  return (
    <>
      {isMobile ? <MobileNavBar hideNavItems={isLogin} /> : <DesktopNavBar hideNavItems={isLogin} />}
    </>
  );
}

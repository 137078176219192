import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { APICall, BackendType, FulfilledThunk, PendingThunk } from "../../Utils";
import { DefaultThunkState, Slices } from "../../constants";

const fetchFsmPendingJobs = createAsyncThunk("fsmPendingJobs", async (bank) => {
  return await APICall(BackendType.MnC, `fsm/pendingjobs?bank=${bank}`);
});

export const fsmPendingJobSlice = createSlice({
  name: Slices.FsmPendingJob,
  initialState: DefaultThunkState(),
  extraReducers: (builder) => {
    builder
      .addCase(fetchFsmPendingJobs.pending, PendingThunk())
      .addCase(fetchFsmPendingJobs.fulfilled, FulfilledThunk())
      .addCase(fetchFsmPendingJobs.rejected, FulfilledThunk());
  },
});

const selectFsmPendingJobsFetching = createSelector(
  [ state => state[Slices.FsmPendingJob].fetching ], (d) => d
);

const selectFsmPendingJobsData = createSelector(
  [ state => state[Slices.FsmPendingJob].data ], (d) => d
);


export { fetchFsmPendingJobs, selectFsmPendingJobsFetching, selectFsmPendingJobsData };
export default fsmPendingJobSlice.reducer;

import { Badge, Box, Button, CircularProgress, Grid, Divider, IconButton, LinearProgress, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, keyframes, styled } from "@mui/material";
import { DynamicFeed, MailOutline, VolumeUp, VolumeOff } from "@mui/icons-material";
import { Classes, DefaultTableCellStyle, NavPaths, OpenSansStyled, SiteColors, Slices } from "../../constants";
import { format } from "date-fns";
import { FormatDateTime12Hour, ParseDBDate, SanitizeInput } from "../../Utils";
import { useNavigate } from "react-router-dom";
import React from "react";
import AppContainer from "../AppContainer";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboard } from "../../redux/slices/exceptionDashboardSlice";
import useThunk from "../../CustomHooks/useThunk";
import { GoDatabase } from "react-icons/go";
import { FiFileText } from "react-icons/fi";
import WarningAudio from "../../Assets/StatusWarning.mp3";
import NewTableBox from "../NewTableBox";
import NewTable from "../NewTable";
import { fetchFsmDashboard, selectFsmDashboardData, selectFsmDashboardFetching } from "../../redux/slices/fsmDashboardSlice";
import { fetchFsmPendingJobs, selectFsmPendingJobsData, selectFsmPendingJobsFetching } from "../../redux/slices/fsmPendingJobSlice";
import { fetchFsmInfoPreview, selectFsmInfoPreviewData, selectFsmInfoPreviewFetching } from "../../redux/slices/fsmPreviewInfoSlice";
import { fetchFsmDetailPreview, selectFsmDetailPreviewData, selectFsmDetailPreviewFetching } from "../../redux/slices/fsmDetailPreviewSlice";
import InformationDialog from "../InformationDialog";
import ChartData from "../fsm/ChartData";
import { fetchFsmProductReview } from "../../redux/slices/fsmProductReviewSlice";
import { fetchFsmViewProductReview, resolveFsmProductReview, selectFsmOneProductReviewData, selectFsmOneProductReviewRefetch, setOneProductReviewData } from "../../redux/slices/fsmOneProductReviewSlice";
import InfoTooltip from "../InfoTooltip";
import ConfirmationDialog from "../ConfirmationDialog";
import NavigateLink from "../NavigateLink";
import { fetchFsmFollowUpEmails } from "../../redux/slices/fsmFollowupSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { fetchFsmFollowUpEmailDetails, selectFsmFollowupEmailDetailsData, selectFsmFollowupEmailDetailsFetching, selectFsmFollowupEmailDetailsSreId, setFollowupDetailSreId } from "../../redux/slices/fsmFollowupDetailsSlice";
import useEffectNoMount from "../../CustomHooks/useEffectNoMount";

const criticalBlink = keyframes`
    0% { background: none;  }
    100% { background: ${SiteColors.Red}; }
`;

const LinkTableCell = styled(TableCell)`
    a {
        color: black;
    }
`;

const StyledTableContainer = styled(TableContainer)`
    th, td, p {
        ${OpenSansStyled}
    }
`;

const StyledInput = styled(TextField)`
    input {
        ${OpenSansStyled}
    }
`;

const StyledSelect = styled(Select)`
    .MuiSelect-select {
        ${OpenSansStyled}
    }
`;

const SystemExceptions = {
    "DB": {
        type: "Database",
        icon: <GoDatabase size={"1.5em"} />
    },
    "Mail": {
        type: "Mail",
        icon: <MailOutline />
    },
    "File": {
        type: "File",
        icon: <FiFileText size={"1.5em"} />
    },
    "Other": {
        type: "Other",
        icon: <DynamicFeed />
    }
}

const PreviewInfoType = {
    "Mails": "mail",
    "Jobs": "job",
    "Parts": "part"
};

const PreviewInfoTypeRows = {
    "Mails": [
        {
            label: "Received from Bank",
            property: "BankReceivedCount"
        },
        {
            label: "Forwarded to SC",
            property: "SCForwardCount"
        },
        {
            label: "Responded to Bank",
            property: "BankRespondCount"
        },
        {
            label: "Problems Reported",
            property: "ProblemsCount"
        }
    ],
    "Jobs": [
        {
            label: "Created in FSM",
            property: "CreatedCount"
        },
        {
            label: "Completed by CE",
            property: "CompletedCount"
        },
        {
            label: "Reviewed by RIHK",
            property: "ReviewedCount"
        }
    ],
    "Parts": [ 
        {
            label: "Changed by CE",
            property: "ChangedCount"
        },
        {
            label: "Reviewed by RIHK",
            property: "ReviewedCount"
        }
    ]
};

const FsmTableDesc = {
    "mail_ack": "mails received from bank",
    "fsm_complete_ticket": "mails responded to bank",
    "job_details": "problems reported",
    "fsm_complete_details": "job complete reviewed",
    "fsm_pm_details": "pm related job reviewed",
    "fsm_product_incoming": "parts changed by CE",
    "fsm_product_complete": "changed parts reviewed"
};

const urgencyColumns = [
    { ...DefaultTableCellStyle, label: "System", sortable: true  },
    { ...DefaultTableCellStyle, label: "Downtime", sortable: true, type: Date  },
    { ...DefaultTableCellStyle, label: "Exceptions" }
];

const pendingColumns = [
    { ...DefaultTableCellStyle, label: "Received Time", width: "60px", sortable: true, type: Date },
    { ...DefaultTableCellStyle, label: "SRE ID", width: "122px", sortable: true },
    { ...DefaultTableCellStyle, label: "FSM ID", width: "50px", sortable: true },
    { ...DefaultTableCellStyle, label: "Machine ID", width: "50px", sortable: true },
    { ...DefaultTableCellStyle, label: "Reported Issue", sortable: true }
];

const productReviewColumn = [
    { ...DefaultTableCellStyle, label: "SRE ID", sortable: true },
    { ...DefaultTableCellStyle, label: "FSM ID", sortable: true },
    { ...DefaultTableCellStyle, label: "" }
];

const oneProductReviewColumns = [
    { ...DefaultTableCellStyle, label: "Completed Date" },
    { ...DefaultTableCellStyle, label: "Customer" },
    { ...DefaultTableCellStyle, label: "Machine SN" },
    { ...DefaultTableCellStyle, label: "SRE ID" },
    { ...DefaultTableCellStyle, label: "Service Form" },
    { ...DefaultTableCellStyle, label: "Job Report" },
    { ...DefaultTableCellStyle, label: "Product Code" },
    { ...DefaultTableCellStyle, label: "Quantity" },
    { ...DefaultTableCellStyle, label: "Product Name" },
];

const followUpEmailColumns = [
    { ...DefaultTableCellStyle, label: "SRE ID" },
    { ...DefaultTableCellStyle, label: "BANK NAME" },
    { ...DefaultTableCellStyle, label: "" }
];

const followUpEmailDetailsColumns = [
    { ...DefaultTableCellStyle, label: "MAIL RECV DATE", sortable: true, type: Date, defaultSort: true },
    { ...DefaultTableCellStyle, label: "TYPE", sortable: true },
    { ...DefaultTableCellStyle, label: "MAIL SENT DATE", sortable: true, type: Date },
    { ...DefaultTableCellStyle, label: "SC RECV DATE", sortable: true, type: Date },
];

const AllBanks = ["HSBC", "HASE", "BOC", "NCB", "CYB", "All"];

const ExceptionIcon = (props) => {
    const { icon, amount, type, system } = props;

    const navigate = useNavigate();

    const OnPanelClicked = () => {
        navigate(`/${NavPaths.ExceptionList}?system=${system}&exType=${type}`);
    }

    return (
        <>
            {
                amount > 0 &&
                <Tooltip title={`View ${amount} ${type} exceptions for ${system}`} arrow placement="top">
                    <Paper sx={{border: '1px solid black', borderRadius: 2}}>
                        <Badge badgeContent={amount} color={"error"}>
                            <Button sx={{minHeight: '28px', minWidth: '28px', color: "black", padding: 0}} onClick={OnPanelClicked}>
                                {icon}
                            </Button>
                        </Badge>
                    </Paper>
                </Tooltip>
            }
        </>
    )
}

const defaultPanelNumbers = {
    Critical: 0,
    Warning: 0,
    Normal: 0
};

const DashboardTable = ({loading, table}) => {
    return (
        <>
            {
                loading === true &&
                <LinearProgress />
            }
            {
                loading !== true &&
                <StyledTableContainer>
                    {table}
                </StyledTableContainer>
            }
        </>
    )
}

const StatusTable = ({data}) => {
    const navigate = useNavigate();

    const OnSystemClick = (system) => () => {
        navigate(`/${NavPaths.ExceptionList}?system=${system}`);
    }

    return (
        <Table size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell>System <InfoTooltip color={"rgba(0, 0, 0, 0.54)"} title={"fsm-webapi\r\nDeployed in FSM App Server in the cloud. Web API called by SC upon receiving mail ticket and job completion/review. \r\n\r\nfsm-fileproc\r\nDeployed in FSM App Server in the cloud. Background service (invoked by Procmail) to rename mail files to distinguish initial/follow up mail. \r\n\r\nfsm-mailproc\r\nDeployed in FSM App Server in the cloud. Background service (cronjob every minute) to send email to SC to create/update jobs in FSM. \r\n\r\nfsm-mailcheck\r\nDeployed in FSM App Server in the cloud. Background service (cronjob every minute) to re-send email to SC if mail acknowledgement not received in specified time. \r\n\r\nfsm-dbproc\r\nDeployed in FSM App Server in the cloud. Background service (cronjob every minute) that performs DB activities. \r\n\r\nfsm-hsekeep\r\nDeployed in FSM App Server in the cloud. Background service (cronjob every minute) that performs housekeep of files. \r\n\r\nhtcqne-webapi\r\nDeployed in HTC Interim Server. Web API called by fsm-webapi when receive job reviewed to store in Interim Server.\r\n\r\nhtcexcep-set-webapi\r\nDeployed in HTC App Server. Web API called by fsm web api/background services to store exceptions in HTC DB.\r\n\r\nhtcexcep-get-webapi\r\nDeployed in FSM App Server in the cloud. Web API called by HTC dashboard to get data from FSM DB.\r\n\r\nhtc-qne\r\nDeployed in HTC Interim Server. Background service (cronjob once a day) to store changed products in QnE Accounting Server."} /></TableCell>
                    <TableCell>Last Run Time</TableCell>
                    <TableCell align={"center"}>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    data.map((r, rInd) => {
                        const statusColor = r.OverallStatus === "High"
                            ? SiteColors.Red
                            : r.OverallStatus === "Medium"
                                ? SiteColors.Gold
                                : SiteColors.Green;

                        return (
                            <TableRow key={`status-table-${rInd}`}>
                                <LinkTableCell sx={{textTransform: "lowercase"}}>
                                    <NavigateLink onClick={OnSystemClick(r.System)}>{r.System}</NavigateLink>
                                </LinkTableCell>
                                <TableCell>{r.Time}</TableCell>
                                <TableCell>
                                    <Box sx={{background: statusColor, border: '1px solid black'}}>
                                        &nbsp;
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    )
}

const TableStatusTable = ({data}) => {
    const navigate = useNavigate();

    const sortedData = [];
    const tables = Object.keys(FsmTableDesc);

    data.forEach((d) => {
        sortedData[tables.indexOf(d.Table)] = { ...d, DisplayTable: FsmTableDesc[d.Table] };
    });

    const OnTableClick = (table) => () => {
        navigate(`/${NavPaths.FSMTables}?table=${table}`);
    }

    return (
        <Table size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell>FSM Info <InfoTooltip color={"rgba(0, 0, 0, 0.54)"} title={"mails received from bank\r\nmail_ack table that stores the mail received from the bank and status of mail forwarded to SC. \r\n\r\nmails responded to bank\r\nfsm_complete_ticket table that stores the job completed by CE and replies to the bank. \r\n\r\nproblems reported\r\njob_details table that stores the initial mail received from the bank and the ticket details given by the bank. \r\n\r\njob complete reviewed\r\nfsm_complete_details table that stores the job reviewed by Senior RI staff. \r\n\r\npm related job reviewed\r\nfsm_pm_details table that stores the information of pm associated with the job reviewed by Senior RI staff.\r\n\r\nparts changed by CE\r\nfsm_product_incoming table that stores the changed product done by CE during job completion.\r\n\r\nchanged parts reviewed\r\nfsm_product_complete table that stored the changed product associated with the job reviewed by Senior RI staff."}/></TableCell>
                    <TableCell align={"center"}>Today's Count</TableCell>
                    <TableCell align={"right"}>Total Count</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    sortedData.map((r, rInd) => {
                        return (
                            <TableRow key={`table-status-table${rInd}`}>
                                <LinkTableCell>
                                    <NavigateLink onClick={OnTableClick(r.Table)} >{r.DisplayTable}</NavigateLink>
                                </LinkTableCell>
                                <TableCell align={"center"}>{r.TodayRecords.toLocaleString()}</TableCell>
                                <TableCell align={"right"}>{r.TotalRecords.toLocaleString()}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    )
}

const SystemUrgencyException = ({exceptions, system}) => {
    return (
        <Stack direction={"row"} spacing={2}>
            {
                Object.entries(exceptions).map(([k, v]) => {
                    return (
                        <ExceptionIcon key={`${system}-${k}-exception`} icon={SystemExceptions[k].icon} amount={v} system={system} type={SystemExceptions[k].type} />
                    )
                })
            }
        </Stack> 
    )
}

const SystemUrgencyTable = ({data}) => {
    const rows = React.useMemo(() => {
        let _rows = [];

        if (data.length > 0)
        {
            _rows = data.map((d) => {
                return [
                    { align: "center", background: d.Color, label: d.System, padding: "8px" },
                    { align: "center", label: d.Downtime, padding: "8px" },
                    { render: <SystemUrgencyException exceptions={d.Exceptions} system={d.System} />, padding: "8px" }
                ]
            });
        }
        else
        {
            _rows = [ [ { align: "center", colspan: urgencyColumns.length, label: "Nothing in the System Urgency Queue List" } ] ]
        }
        
        return _rows;
    }, [data]);

    return (
        <NewTable columns={urgencyColumns} rows={rows} disablePagination />
    )
}

const PendingJobsTable = ({data, page, OnPageChanged}) => {
    const [rows, length] = React.useMemo(() => {
        let _rows = [];

        if (data.length > 0)
        {
            _rows = data.map((d) => {
                return [
                    { align: "center", label: d.ReceivedTime, padding: "8px" },
                    { align: "center", label: d.OriginSreId, padding: "8px" },
                    { align: "center", label: d.Fsm_Job_Id, padding: "8px" },
                    { align: "center", label: d.Machine_Id, padding: "8px" },
                    { align: "center", label: d.Error_Message, padding: "8px" },
                ]
            });
        }
        else
        {
            _rows = [ [ { align: "center", colspan: pendingColumns.length, label: "No Pending Jobs in past 7 days" } ] ]
        }
        
        return [_rows, _rows.length];
    }, [data]);

    return (
        <NewTable columns={pendingColumns} rows={rows} sibling={0} boundary={0} page={page} OnPageChanged={OnPageChanged} disableFooter rightHeader={<Typography sx={{margin: 0, alignSelf: "center"}}>Total Pending Jobs: {length}</Typography>} />
    )
}

const ProductReviewActions = ({sreid, jobid, dispatch, setConfirmOpen, setConfirmPayload}) => {    
    const OnViewClicked = () => {
        dispatch(fetchFsmViewProductReview({
            sreid: sreid,
            jobid: jobid
        }));
    }

    const OnResolveClicked = () => {
        setConfirmOpen(true);
        setConfirmPayload({ sreid: sreid, jobid: jobid });
    }

    return (
        <Stack direction={"row"} spacing={1} justifyContent={"center"}>
            <Button variant={"contained"} size={"small"} onClick={OnViewClicked}>View Detail</Button>
            <Button variant={"contained"} size={"small"} onClick={OnResolveClicked}>Resolve</Button>
        </Stack> 
    )
}

const ProductReviewAgainTable = ({dispatch, data, setConfirmOpen, setConfirmPayload}) => {
    const rows = React.useMemo(() => {
        let _rows = [];

        if (data.length > 0)
        {
            _rows = data.map((d) => {
                return [
                    { align: "center", label: d.SreId, padding: "8px" },
                    { align: "center", label: d.JobId, padding: "8px" },
                    { render: <ProductReviewActions sreid={d.SreId} jobid={d.JobId} dispatch={dispatch} setConfirmOpen={setConfirmOpen} setConfirmPayload={setConfirmPayload} />, padding: "8px" }
                ]
            });
        }
        else
        {
            _rows = [ [ { align: "center", colspan: productReviewColumn.length, label: "Nothing in the Product Reviewed Again List" } ] ]
        }
        
        return _rows;
    }, [data]);

    return (
        <NewTable columns={productReviewColumn} rows={rows} disablePagination />
    )
}

const SystemStatusCircle = ({number, color, loadingColor, fill, loading}) => {
    let sx = {};

    if (color === SiteColors.Red && number > 0)
    {
        sx.animation = `${criticalBlink} 0.4s infinite linear alternate`;
    }
    else if (fill && loading !== true)
    {
        sx.background = color;
    }

    return (
        <Box sx={sx} display={"flex"} alignItems={"center"} justifyContent={"center"} width={"90px"} height={"90px"} border={`4px solid ${color}`} borderRadius={16}>
            {
                loading === true &&
                <CircularProgress size={100} sx={{color: loadingColor}} />
            }
            {
                loading !== true &&
                <Typography variant={"h4"}>
                    {
                        number > 0 &&
                        number
                    }
                </Typography>
            }
        </Box>
    )
}

const GenUrgentData = (r) => {
    return {
        Severity: r.OverallStatus === "Medium" ? 2 : 3,
        Color: r.OverallStatus === "Medium" ? SiteColors.Gold : SiteColors.Red,
        System: r.SystemName,
        Downtime: format(Date.parse(r.DownTime), "dd-MMM HH:mm:ss"),
        Exceptions: r.Exceptions
    };
}

const StatusBox = ({fetching, refreshAction, resetTimer, data}) => {
    return (
        <NewTableBox title={"Status"} refreshAction={refreshAction} resetTimer={resetTimer} seconds={90} tooltip={"This panel displays the list of Web APIs and background services (categorised as system) built for FSM along with its last execution time."}>
            <DashboardTable loading={fetching} table={<StatusTable data={data} />} />
        </NewTableBox>
    )
}

const SystemUrgencyBox = ({fetching, refreshAction, resetTimer, data}) => {
    return (
        <NewTableBox title={"System Urgency Queue List"} refreshAction={refreshAction} resetTimer={resetTimer} seconds={90} tooltip={"This panel displays the list of systems which are either in Error or Warning status that require to be resolved."}>
            <DashboardTable loading={fetching} table={<SystemUrgencyTable data={data} />} />
        </NewTableBox>
    )
}

const SystemStatusBox = ({panelNumbers, onMuteClicked, muteAlarm}) => {
    return (
        <NewTableBox title={"Overall Status for All Systems"} tooltip={"This panel displays the health of the systems built for FSM categorised as Error (Red), Warning (Orange), and Normal (Green)."}>
            <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                <SystemStatusCircle color={SiteColors.Red} loadingColor={SiteColors.DarkRed} number={panelNumbers.Critical} />
                <SystemStatusCircle color={SiteColors.Gold} loadingColor={SiteColors.DarkGold} number={panelNumbers.Warning} />
                <SystemStatusCircle color={SiteColors.Green} loadingColor={SiteColors.DarkGreen} number={panelNumbers.Normal} fill />
            </Stack>
            <Box display={"flex"} justifyContent={"flex-end"}>
                <IconButton onClick={onMuteClicked}>
                    {
                        muteAlarm === true &&
                        <VolumeOff />
                    }
                    {
                        muteAlarm !== true &&
                        <VolumeUp />
                    }
                </IconButton>
            </Box>
        </NewTableBox>
    )
}

const InformationBox = ({ dispatch }) => {
    const [refreshFsmDashboard, setRefreshFsmDashboard] = React.useState(false);
    const [infoBank, setInfoBank] = React.useState("All");

    const data = useSelector(selectFsmDashboardData);
    const fetching = useSelector(selectFsmDashboardFetching);

    React.useEffect(() => {
        if (refreshFsmDashboard === true)
        {
            dispatch(fetchFsmDashboard(infoBank));
            setRefreshFsmDashboard(false);
        }
    }, [refreshFsmDashboard]);

    useEffectNoMount(() => {
        dispatch(fetchFsmDashboard(infoBank));
    }, [infoBank]);

    const OnRefreshFsmDashboardClicked = () => {
        setRefreshFsmDashboard(true);
    }

    return (
        <NewTableBox title={"Information Status"} 
            refreshAction={OnRefreshFsmDashboardClicked} resetTimer={refreshFsmDashboard} seconds={90} 
            tooltip={"This panel displays the list of all tables in the FSM database and its rows count by count of today and overall total."}
            headerComponents={<BankSelect prefix={"info-status"} value={infoBank} setValue={setInfoBank} />} >
            <DashboardTable loading={fetching} table={<TableStatusTable data={data} />} />
        </NewTableBox>
    )
}

const ProblemsTodayBox = ({}) => {
    return (
        <NewTableBox title={"Top 5 Problems Today"} tooltip={"This panel displays the top 5 highest reported issues by the bank for today. Hover to the bar to display the reported issue."}> 
            <ChartData daysOrWeeks= "day"/>
        </NewTableBox>
    )
}

const ProblemsThisWeekBox = ({}) => {
    return (
        <NewTableBox title={"Top 5 Problems past 7 days"} tooltip={"This panel displays the top 5 highest reported issues by the bank in the past 7 days. Hover to the bar to display the reported issue."}>
            <ChartData daysOrWeeks= "week"/>
        </NewTableBox>
    )
}

const BankSelect = ({ prefix, value, setValue }) => {
    const OnChange = (e, v) => {
        setValue(e.target.value);
    }

    return (
        <Select size={"small"} value={value} onChange={OnChange} sx={{background: 'white'}}>
            {
                AllBanks.map((x) => {
                    return (
                        <MenuItem key={`${prefix}-bank-${x}`} value={x}>{x}</MenuItem>
                    )
                })
            }
        </Select>
    )
}

const PendingJobsBox = ({ dispatch }) => {
    const [page, setPage] = React.useState(1);
    const [pendingBank, setPendingBank] = React.useState("All");
    const [refreshFsmPendingJobs, setRefreshFsmPendingJobs] = React.useState(false);

    const data = useSelector(selectFsmPendingJobsData);
    const fetching = useSelector(selectFsmPendingJobsFetching);

    React.useEffect(() => {
        if (refreshFsmPendingJobs === true)
        {
            dispatch(fetchFsmPendingJobs(pendingBank));
            setRefreshFsmPendingJobs(false);
        }
    }, [refreshFsmPendingJobs]);

    useEffectNoMount(() => {
        dispatch(fetchFsmPendingJobs(pendingBank));
    }, [pendingBank]);

    const OnRefreshFsmPendingJobsClicked = () => {
        setRefreshFsmPendingJobs(true);
    }

    const OnPageChanged = (v) => {
        setPage(v);
    }

    return (
        <NewTableBox title={"Pending Jobs past 7 days"} refreshAction={OnRefreshFsmPendingJobsClicked} resetTimer={refreshFsmPendingJobs} seconds={90} headerComponents={<BankSelect prefix={"pending"} value={pendingBank} setValue={setPendingBank} />}
        tooltip={"This panel displays the registered jobs in FSM and pending for completion by CE for the past 7 days."}>
            <DashboardTable loading={fetching} table={<PendingJobsTable data={data} page={page} OnPageChanged={OnPageChanged} />} />
        </NewTableBox>
    )
}

const FSMInfoPreviewTable = ({type, data}) => {
    const [columns, rows] = React.useMemo(() => {
        let _col = [{...DefaultTableCellStyle, label: type}];
        let _rows = [];

        PreviewInfoTypeRows[type]?.forEach((rt, rtI) => {
            let _row = [{...DefaultTableCellStyle, label: rt.label}];
            let _prop = rt.property;

            data.forEach((d) => {
                if (rtI === 0)
                {
                    _col.push({...DefaultTableCellStyle, label: d.Date});
                }

                _row.push({...DefaultTableCellStyle, label: d[_prop]});
            });

            _rows.push(_row);
        });

        return [_col, _rows];
    }, [type, data]);
    
    return (
        <NewTable columns={columns} rows={rows} disablePagination />
    )
}

const FSMInfoPreviewBox = ({dispatch}) => {
    const [previewInfoType, setPreviewInfoType] = React.useState(PreviewInfoType.Mails);
    const [previewInfoTypeName, setPreviewInfoTypeName] = React.useState("");

    const fetching = useSelector(selectFsmInfoPreviewFetching);
    const data = useSelector(selectFsmInfoPreviewData);

    const OnPreviewInfoTypeChanged = (e) => {
        setPreviewInfoType(e.target.value);
    }

    const OnSubmitClicked = () => {
        dispatch(fetchFsmInfoPreview({type: previewInfoType}));

        let _name = Object.entries(PreviewInfoType).find(x => x[1] === previewInfoType)[0];
        setPreviewInfoTypeName(_name);
    }

    return (
        <NewTableBox title={"FSM Information Preview past 7 days"} tooltip={"This panel displays a table of job count of past 7 days for registered jobs stored in the FSM database that are categorised into three sections (mail, job, parts)."}>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Typography className={Classes.opensans}>Preview Item</Typography>
                <StyledSelect size="small" value={previewInfoType} onChange={OnPreviewInfoTypeChanged} sx={{width: "80px"}}>
                    {
                        Object.entries(PreviewInfoType).map(([k, v]) => {
                            return (
                                <MenuItem key={`preview-info-${k}`} value={v}>{v}</MenuItem>
                            )
                        })
                    }
                </StyledSelect>
                <Button variant={"contained"} onClick={OnSubmitClicked} className={Classes.opensans}>Submit</Button>
            </Stack>
            {
                (fetching !== true && data.length > 0) &&
                <DashboardTable loading={fetching} table={<FSMInfoPreviewTable type={previewInfoTypeName} data={data} />} />
            }
        </NewTableBox>
    )
}

const FSMDetailPreviewInfoLine = ({label, value}) => {
    return (
        <TableRow>
            <TableCell sx={{borderLeft: "1px solid rgba(224, 224, 224, 1)", borderRight: "1px solid rgba(224, 224, 224, 1)", borderTop: "1px solid rgba(224, 224, 224, 1)"}}>
                <Grid container>
                    <Grid item xs={5}>
                        <Typography className={Classes.opensans}>{label}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography className={Classes.opensans}>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={Classes.opensans}>{value}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    )
}

const FollowUpEmailActions = ({sreid, dispatch}) => {    
    const OnViewClicked = () => {
        dispatch(fetchFsmFollowUpEmailDetails(sreid));
        dispatch(setFollowupDetailSreId(sreid));
    }

    return (
        <Stack direction={"row"} spacing={1} justifyContent={"center"}>
            <Button variant={"contained"} size={"small"} onClick={OnViewClicked}>View Detail</Button>
        </Stack> 
    )
}

const FollowupDetailsBox = ({data}) => {
    return (
        <Stack spacing={2}>
            <StyledTableContainer>
                <Table size={"small"} sx={{ '.MuiTableCell-root': { borderBottom: "none", paddingTop: 0, paddingBottom: 0 } }}>
                    <TableHead>
                        <TableRow sx={{ '.MuiTableCell-root': { fontWeight: "bold" } }}>
                            <TableCell>Customer:</TableCell>
                            <TableCell>Machine:</TableCell>
                            <TableCell>Job Id:</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{data.Customer ?? "N/A"}</TableCell>
                            <TableCell>{data.Machine ?? "N/A"}</TableCell>
                            <TableCell>{data.JobId ?? "N/A"}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <Divider />
            <FollowUpDetailsTable data={data.DetailsData} />
        </Stack>
    )
}

const FollowUpDetailsTable = ({data}) => {
    const DateFormat = (val) => {
        return ParseDBDate(val, "d/M/yyyy h:mm:ss a", "ddMMMyyyy hh:mm:ss a");
    }

    const rows = React.useMemo(() => {
        let _rows = [];

        if (data.length > 0)
        {
            _rows = data.map((d) => {
                return [
                    { align: "center", label: DateFormat(d.RecvDt) },
                    { align: "center", label: d.Type },
                    { align: "center", label: DateFormat(d.SentDt) },
                    { align: "center", label: DateFormat(d.SCRecvDt) }
                ]
            });
        }
        
        return _rows;
    }, [data]);

    return (
        <NewTable disablePageSizes disableFooter columns={followUpEmailDetailsColumns} rows={rows} />
    )
}

const FollowupTable = ({data, dispatch}) => {
    const rows = React.useMemo(() => {
        let _rows = [];

        if (data.length > 0)
        {
            _rows = data.map((d) => {
                return [
                    { align: "center", label: d.SreId },
                    { align: "center", label: d.Bank },
                    { render: <FollowUpEmailActions sreid={d.SreId} dispatch={dispatch} />, padding: "8px" }
                ]
            });
        }
        else
        {
            _rows = [ [ { align: "center", colspan: followUpEmailColumns.length, label: "Nothing in the Followup Email Received First List" } ] ]
        }
        
        return _rows;
    }, [data]);

    return (
        <NewTable columns={followUpEmailColumns} rows={rows} />
    )
}

const FollowupDateTime = ({date, onChange, onClose}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DemoContainer components={['DatePicker']} sx={{display: "inline-flex", width: "min-content", pl: 1, pt: 0}}>
                <DatePicker sx={{ '.MuiOutlinedInput-root': { background: 'white' } }} views={['month', 'year']} slotProps={{textField: {size: "small"}}} format={"MMM yyyy"} value={date} onChange={onChange} onClose={onClose} />
            </DemoContainer>
        </LocalizationProvider>
    )
}

const FSMFollowUpEmailBox = ({dispatch}) => {
    const { data, fetching } = useThunk(Slices.FsmFollowupEmails, fetchFsmFollowUpEmails({ month: new Date().getMonth() + 1, year: new Date().getFullYear() }));

    const detailSreId = useSelector(selectFsmFollowupEmailDetailsSreId);
    const detailFetching = useSelector(selectFsmFollowupEmailDetailsFetching); 
    const detailData = useSelector(selectFsmFollowupEmailDetailsData); 

    const [refreshFollowupEmails, setRefreshFollowupEmails] = React.useState(false);
    const [originalFollowupMonth, setOriginalFollowupMonth] = React.useState(new Date());
    const [followupMonth, setFollowupMonth] = React.useState(new Date());

    const [infoOpen, setInfoOpen] = React.useState(false);

    const OnDateChanged = (v) => {
        setFollowupMonth(v);
    }

    React.useEffect(() => {
        return () => {
            OnInfoClosed();
        }
    }, []);

    const OnDatePickerClosed = () => {
        if (originalFollowupMonth.getMonth() != followupMonth.getMonth() || originalFollowupMonth.getFullYear() != followupMonth.getFullYear())
        {
            setOriginalFollowupMonth(followupMonth);
            dispatch(fetchFsmFollowUpEmails({ month: followupMonth.getMonth() + 1, year: followupMonth.getFullYear() }));
        }
    }

    React.useEffect(() => {
        if (refreshFollowupEmails === true)
        {
            dispatch(fetchFsmFollowUpEmails({ month: followupMonth.getMonth() + 1, year: followupMonth.getFullYear() }));
            setRefreshFollowupEmails(false);
        }
    }, [refreshFollowupEmails]);

    React.useEffect(() => {
        if (detailSreId.length > 0)
        {
            setInfoOpen(true);
        }
    }, [detailSreId]);

    const OnRefreshFollowupEmailsClicked = () => {
        setRefreshFollowupEmails(true);
    }

    const OnInfoClosed = () => {
        setInfoOpen(false);
        dispatch(setFollowupDetailSreId(""));
    }

    return (
        <>
            <NewTableBox title="Followup Received First / Initial Received Twice" 
                refreshAction={OnRefreshFollowupEmailsClicked} resetTimer={refreshFollowupEmails} seconds={90}
                headerComponents={<FollowupDateTime date={followupMonth} onChange={OnDateChanged} onClose={OnDatePickerClosed} />}>
                <DashboardTable loading={fetching} table={<FollowupTable data={data} dispatch={dispatch} />} />
            </NewTableBox>
            <InformationDialog open={infoOpen} onClose={OnInfoClosed} title={`Followup Received First / Initial Received Twice for SRE ID - ${detailSreId}`}>
                <Stack spacing={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                    {
                        detailFetching === true &&
                        <CircularProgress />
                    }
                    {
                        detailFetching !== true &&
                        <FollowupDetailsBox data={detailData} />
                    }
                </Stack>
            </InformationDialog>
        </>
    )
}

const FSMDetailPreviewBox = ({dispatch}) => {
    const [sreId, setSreId] = React.useState("");
    const [infoOpen, setInfoOpen] = React.useState(false);

    const fetching = useSelector(selectFsmDetailPreviewFetching);
    const data = useSelector(selectFsmDetailPreviewData);

    const OnSreIdChanged = (e) => {
        setSreId(e.target.value);
    }

    const OnSubmitClicked = () => {
        if (sreId.length <= 0) return;
        dispatch(fetchFsmDetailPreview({ sreId: SanitizeInput(sreId) }));
        setInfoOpen(true);
    }

    const OnInfoClosed = () => {
        setInfoOpen(false);
    }

    return (
        <>
            <NewTableBox title="Quick FSM Detail Preview" tooltip={"This panel allows a quick search on the summary information of the job registered in FSM based on sre id."}>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                    <Typography className={Classes.opensans}>
                        SRE ID
                    </Typography>
                    <StyledInput size={"small"} value={sreId} onChange={OnSreIdChanged} />
                    <Button variant={"contained"} onClick={OnSubmitClicked} className={Classes.opensans}>Quick View</Button>
                </Stack>
            </NewTableBox>
            <InformationDialog open={infoOpen} onClose={OnInfoClosed} title={`FSM Details for SRE ID - ${SanitizeInput(sreId)}`}>
                <Stack spacing={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                    {
                        fetching === true &&
                        <CircularProgress />
                    }
                    {
                        fetching !== true &&
                        <TableContainer>
                            <Table size={"small"}>
                                <TableBody>
                                    <FSMDetailPreviewInfoLine label={"Received Time"} value={data.ReceivedTime} />
                                    <FSMDetailPreviewInfoLine label={"FSM Registered Time"} value={data.RegisteredTime} />
                                    <FSMDetailPreviewInfoLine label={"Job ID"} value={data.JobId} />
                                    <FSMDetailPreviewInfoLine label={"Job Type"} value={data.JobType} />
                                    <FSMDetailPreviewInfoLine label={"Completed Time"} value={data.CompletedTime} />
                                    <FSMDetailPreviewInfoLine label={"Reported Issue"} value={data.ReportedIssue} />
                                    <FSMDetailPreviewInfoLine label={"Machine ID"} value={data.MachId} />
                                    <FSMDetailPreviewInfoLine label={"Bank"} value={data.Bank} />
                                    <FSMDetailPreviewInfoLine label={"CE"} value={data["CE"]} />
                                    <FSMDetailPreviewInfoLine label={"Action Taken"} value={data.ActionTaken} />
                                    <FSMDetailPreviewInfoLine label={"Reviewer"} value={data.Reviewer} />
                                    <FSMDetailPreviewInfoLine label={"Reviewed Time"} value={data.ReviewedTime} />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Stack>
            </InformationDialog>
        </>
    )
}

const FSMProductReviewBox = ({dispatch, setConfirmOpen, setConfirmPayload}) => {
    const { data, fetching } = useThunk(Slices.FsmProductReview, fetchFsmProductReview());

    const [infoOpen, setInfoOpen] = React.useState(false);
    const [viewData, setViewData] = React.useState(undefined);
    const [oldData, setOldData] = React.useState([]);
    const [newData, setNewData] = React.useState([]);
    const [selectedSreId, setSelectedSreId] = React.useState("");
    const [refreshFsmProductReview, setRefreshFsmProductReview] = React.useState(false);

    const _viewData = useSelector(selectFsmOneProductReviewData);
    const refetchOneProductReview = useSelector(selectFsmOneProductReviewRefetch);

    const OnRefreshFsmProductReviewClicked = () => {
        setRefreshFsmProductReview(true);
    }

    React.useEffect(() => {
        if (refreshFsmProductReview === true)
        {
            dispatch(fetchFsmProductReview());
            setRefreshFsmProductReview(false);
        }
    }, [refreshFsmProductReview]);
    
    React.useEffect(() => {
        setRefreshFsmProductReview(refetchOneProductReview);
    }, [refetchOneProductReview]);

    React.useEffect(() => {
        let _data = Object.entries(_viewData).length > 0 ? _viewData : undefined;
        setViewData(_data);
    }, [_viewData]);

    React.useEffect(() => {
        if (viewData === undefined) return;

        setOldData(viewData.Old.map(MapData));
        setNewData(viewData.New.map(MapData));
        setSelectedSreId(viewData.New[0].SreId);
        setInfoOpen(true);
    }, [viewData]);

    const MapData = (d) => {
        return Object.values(d).map((v) => {
            return {...DefaultTableCellStyle, label: v }
        });
    }

    const OnInfoClosed = () => {
        setInfoOpen(false);
        dispatch(setOneProductReviewData({}));
    }

    return (
        <>
            <NewTableBox title={"Product Review Again List"} refreshAction={OnRefreshFsmProductReviewClicked} resetTimer={refreshFsmProductReview} seconds={90} tooltip={"This panel displays the product information that may require the attention of the Accounts Department. The product listed here was reviewed more than once by Senior RI staff."}>
                <DashboardTable loading={fetching} table={<ProductReviewAgainTable data={data} dispatch={dispatch} setConfirmOpen={setConfirmOpen} setConfirmPayload={setConfirmPayload} />} />
            </NewTableBox>
            <InformationDialog open={infoOpen} onClose={OnInfoClosed} title={`Product reviewed again for SRE ID - ${selectedSreId}`}>
                {
                    fetching === true &&
                    <CircularProgress />
                }
                {
                    fetching !== true &&
                    <>
                        <Box>
                            <Typography>Old Value</Typography>
                            <NewTable columns={oneProductReviewColumns} rows={oldData} disablePagination />
                        </Box>
                        <Box>
                            <Typography>New Value</Typography>
                            <NewTable columns={oneProductReviewColumns} rows={newData} disablePagination />
                        </Box>
                    </>
                }
            </InformationDialog>
        </>
    )
}

export default function ExceptionDashboard (props) {
    const { isMobile } = props;
    
    const dispatch = useDispatch();
    const { data: exData, fetching: exFetching } = useThunk(Slices.ExceptionDashboard, fetchDashboard());

    const [panelNumbers, setPanelNumbers] = React.useState({...defaultPanelNumbers});

    const [statusRows, setStatusRows] = React.useState([]);
    const [urgencyRows, setUrgencyRows] = React.useState([]);

    const [exDashboardData, setExDashboardData] = React.useState(exData);
    const [refreshExDashboard, setRefreshExDashboard] = React.useState(false);

    const audio = React.useRef(null);
    const [muteAlarm, setMuteAlarm] = React.useState(false);
    const [firstSetVol, setFirstSetVol] = React.useState(true);

    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [confirmPayload, setConfirmPayload] = React.useState(undefined);

    const OnVolumeClicked = () => {
        if (muteAlarm === true)
        {
            audio.current.play(); // Temporary workaround for being unable to autoplay sometimes 
        }

        setMuteAlarm(!muteAlarm);
    }

    const OnRefreshExDashboardClicked = () => {
        setRefreshExDashboard(true);
    }

    const OnConfirmClose = () => {
        setConfirmOpen(false);
    }

    const OnConfirmClicked = () => {
        dispatch(resolveFsmProductReview(confirmPayload));
        OnConfirmClose();
    }

    React.useEffect(() => {
        if (exFetching === true) return;
        setExDashboardData(exData);
    }, [exFetching]);

    React.useEffect(() => {
        if (refreshExDashboard === true)
        {
            dispatch(fetchDashboard());
            setRefreshExDashboard(false);
        }
    }, [refreshExDashboard]);

    React.useEffect(() => {
        if (firstSetVol !== true) return;
        audio.current.volume = 0;
        setFirstSetVol(false);
    }, [audio.current]);

    React.useEffect(() => {
        if (panelNumbers.Critical > 0)
        {
            if (audio.current.volume == 0)
            {
                audio.current.currentTime = 0;
                audio.current.volume = 1;
            }
        }
        else
        {
            audio.current.volume = 0;
        }
    }, [panelNumbers.Critical, audio.current?.paused]);


    React.useEffect(() => {
        let panelNums = {...defaultPanelNumbers};
        let _statusRows = [];
        let _urgencyRows = [];

        exDashboardData.forEach((r, i) => {
            _statusRows.push({
                System: r.SystemName,
                Time: r.LastRunTime === null ? "" : FormatDateTime12Hour(r.LastRunTime),
                OverallStatus: r.OverallStatus 
            });

            switch (r.OverallStatus)
            {
                case "Low":
                    panelNums.Normal += 1;
                    break;
                case "Medium":
                    panelNums.Warning += 1;
                    _urgencyRows.push(GenUrgentData(r));
                    break;
                case "High":
                    panelNums.Critical += 1;
                    _urgencyRows.push(GenUrgentData(r));
                    break;
            }
        });

        _urgencyRows = _urgencyRows.sort((a, b) => b.Severity - a.Severity);

        setPanelNumbers(panelNums); 
        setStatusRows(_statusRows);
        setUrgencyRows(_urgencyRows);
    }, [exDashboardData]);

    return (
        <>
            <AppContainer display={"flex"} justifyContent={"center"} overflow={"auto"}>
                {
                    isMobile !== true &&
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Stack spacing={2}>
                                <StatusBox fetching={exFetching} refreshAction={OnRefreshExDashboardClicked} resetTimer={refreshExDashboard} data={statusRows} />
                                <SystemUrgencyBox fetching={exFetching} refreshAction={OnRefreshExDashboardClicked} resetTimer={refreshExDashboard} data={urgencyRows} />
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack spacing={2}>
                                <SystemStatusBox panelNumbers={panelNumbers} onMuteClicked={OnVolumeClicked} muteAlarm={muteAlarm} />
                                <InformationBox dispatch={dispatch} />

                                <FSMInfoPreviewBox dispatch={dispatch} />
                                <FSMFollowUpEmailBox dispatch={dispatch} />
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack spacing={2}>
                                <FSMDetailPreviewBox dispatch={dispatch} />
                                <FSMProductReviewBox dispatch={dispatch} setConfirmOpen={setConfirmOpen} setConfirmPayload={setConfirmPayload} />
                                <PendingJobsBox dispatch={dispatch} />
                                <ProblemsTodayBox />
                                <ProblemsThisWeekBox />
                            </Stack>
                        </Grid>
                    </Grid>
                }
                {
                    isMobile === true &&
                    <Stack spacing={2} width={"100%"}>
                        <SystemStatusBox panelNumbers={panelNumbers} onMuteClicked={OnVolumeClicked} muteAlarm={muteAlarm} />
                        <StatusBox fetching={exFetching} refreshAction={OnRefreshExDashboardClicked} resetTimer={refreshExDashboard} data={statusRows} />
                        <SystemUrgencyBox fetching={exFetching} refreshAction={OnRefreshExDashboardClicked} resetTimer={refreshExDashboard} data={urgencyRows} />
                        <InformationBox dispatch={dispatch} />
                        <ProblemsTodayBox />
                        <ProblemsThisWeekBox />
                        <PendingJobsBox dispatch={dispatch} />
                        <FSMInfoPreviewBox dispatch={dispatch} />
                        <FSMFollowUpEmailBox dispatch={dispatch} />
                        <FSMDetailPreviewBox dispatch={dispatch} />
                        <FSMProductReviewBox dispatch={dispatch} setConfirmOpen={setConfirmOpen} setConfirmPayload={setConfirmPayload} />
                    </Stack>
                }
            </AppContainer>
            <ConfirmationDialog open={confirmOpen} onClose={OnConfirmClose} confirmationMsg={`Are you sure you want to resolve this item (${confirmPayload?.sreid})?`} onConfirm={OnConfirmClicked} />
            <audio ref={audio} muted={muteAlarm} autoPlay loop>
                <source src={WarningAudio} />
            </audio>
        </>
    )
}
import { Close } from "@mui/icons-material";
import { Alert, Box, Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import React from "react";

export default function BaseRoomBookingModal({open, title, OnClose, alert, children})
{
    return (
        <Dialog open={Boolean(open)} onClose={OnClose} maxWidth={"sm"} fullWidth>
            <DialogTitle sx={{borderBottom: '1px solid black'}}>
                <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <span>{title}</span>
                    <IconButton onClick={OnClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Box pt={2} />
                {
                    alert.length > 0 &&
                    <>
                        <Alert variant="filled" severity="error">
                            {alert}
                        </Alert>
                        <Box pt={2} />
                    </>
                }
                {children}
            </DialogContent>
        </Dialog>
    )
}
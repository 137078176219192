import { InfoOutlined } from "@mui/icons-material";
import { ClickAwayListener, IconButton, Tooltip, styled, tooltipClasses } from "@mui/material";
import React from "react";

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        whiteSpace: "pre-wrap",
        overflowX: 'clip',
        overflowY: 'auto',
        maxHeight: '300px',
        backgroundColor: "rgb(91, 156, 125)",
        fontFamily: "Open Sans",
        fontSize: "0.75rem",
        fontWeight: "530"
    }
}))

export default function InfoTooltip({title, icon, position='bottom', color = 'black'}) {
    const [open, setOpen] = React.useState(false);

    const OnClose = () => {
        setOpen(false);
    }

    const OnClick = () => {
        setOpen(true);
    }

    return (
        <ClickAwayListener onClickAway={OnClose}>
            <span>
                <StyledTooltip PopperProps={{disablePortal: true}}
                    open={open} onClose={OnClose} placement={position}
                    title={title}
                    arrow disableFocusListener disableHoverListener disableTouchListener>
                    <IconButton size={"small"} sx={{color: color}} onClick={OnClick}>
                        {
                            icon ?? <InfoOutlined />
                        }
                    </IconButton>
                </StyledTooltip>
            </span>
        </ClickAwayListener>
    )
}
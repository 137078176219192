import { Box, Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Typography, buttonClasses } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Description as DescriptionIcon, Person as PersonIcon, Schedule as ScheduleIcon } from '@mui/icons-material'
import { styled } from '@mui/material';
import RoomBookingModal from './RoomBookingModal';
import logo from '../../Assets/ht.png';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { enUS } from 'date-fns/locale';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { endMeeting, fetchMeetings, selectFetching, selectMeetings, selectMessage, selectRefresh, selectRefreshNextMeeting, startMeeting, cancelMeeting } from '../../redux/slices/timeslotSlice';
import { fetchNextMeeting, selectMeetingFetching, selectNextMeeting } from '../../redux/slices/meetingSlice';
import { fetchRooms, selectRoomFetching, selectRooms } from '../../redux/slices/roomSlice';
import { add, differenceInMinutes, differenceInSeconds, isAfter, isBefore, startOfToday } from 'date-fns';
import { useTime } from 'react-timer-hook';
import { setSnackbar } from '../../redux/slices/appSlice';
import { RoomStatus } from '../../Utils'; 
import ConfirmationDialog from '../ConfirmationDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import ExtendMeetingModal from './ExtendMeetingModal';

const locales = {
    'en-US': enUS
};

const calendarLocalizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
});

const MinTimeslot = add(startOfToday(), { hours: 9 });
const MaxTimeslot = add(startOfToday(), { hours: 20 });

const Timeslot = styled(Calendar, { shouldForwardProp: (props) => props !== 'bg' })(({ theme, bg }) => `
    .rbc-time-view {
        background: ${bg};
        color: white;

        .rbc-time-header {
            display: none;
        }

        .rbc-row-content {
            background: ${bg};
        }

        .rbc-time-content {
            border-top: none;
        }

        .rbc-day-slot {
            background: ${bg};
        }

        .rbc-timeslot-group {
            border-bottom: none;
        }

        .rbc-event {
            background: rgba(0,0,0,0.6); 
            border-color: rgba(0,0,0,0.6);
        }

        .rbc-current-time-indicator {
            height: 2px;
        }
    }
`);

const MeetingActionBtn = styled(Button, { shouldForwardProp: (props) => props !== 'textColor' })(({ theme, textColor }) => `
    background-color: white;
    color: ${textColor};

    :hover {
        background-color: ${theme.palette.grey[300]}
    }
`);

const InfoLine = (props) => {
    const { icon, value } = props;

    return (
        <Grid container>
            <Grid item xs={2} sm={1} minWidth={'24px'}>
                {icon}
            </Grid>
            <Grid item xs={10} sm={11}>
                <Typography >
                    {value}
                </Typography>
            </Grid>
        </Grid>
    )
}

const RoomAvailability = (props) => {
    const { rooms, nextMeeting, selectedRoom, bookingDateTime, dispatch } = props;
    
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [confirmationMsg, setConfirmationMsg] = React.useState("");
    const [extendMeeting, setMeetingExtension] = React.useState(false);

    const {
        minutes
    } = useTime();

    // const currentDate = new Date().toLocaleDateString('en-GB');

    const hasMeeting = Object.keys(nextMeeting).length > 0 && nextMeeting.Room?.length > 0;

    React.useEffect(() => {
        switch (nextMeeting.Status?.status_id)
        {
            case RoomStatus.Available:
                // Has a meeting later
                if (nextMeeting.Start.length > 0)
                {
                    const diffMin = differenceInMinutes(new Date(nextMeeting.Start), new Date());

                    if (diffMin <= 10)
                    {
                        dispatch(fetchNextMeeting(selectedRoom));
                    }
                }
                break;
            case RoomStatus.In_Use:
                if (isAfter(new Date(), new Date(nextMeeting.End)))
                {
                    dispatch(fetchNextMeeting(selectedRoom));
                }
                break;
            case RoomStatus.Starting_Soon:
                let diff = differenceInSeconds(new Date(nextMeeting.Start), new Date());
                // 3 seconds buffer
                if (diff >= 0 && diff <= 3)
                {
                    dispatch(fetchNextMeeting(selectedRoom));
                }
                break;
            default:
                break;
        }

        const payload = {
            date: bookingDateTime,
            roomId: selectedRoom
        };
        dispatch(fetchMeetings(payload))
    }, [minutes]);

    const GetMeetingSchedule = () => {
        if (nextMeeting.Start !== undefined && nextMeeting.Start.length > 0)
        {
            const meetingDate = new Date(nextMeeting.Start);

            if (nextMeeting.Status.status_id === RoomStatus.Available)
            {
                const minuteDiff = differenceInMinutes(meetingDate, new Date());
                let retStr = `${minuteDiff} minutes`;

                if (minuteDiff >= 60)
                {
                    let hours = Math.floor(minuteDiff / 60);
                    let minutes = minuteDiff - (hours * 60);

                    let hourStr = hours > 1 ? "hours" : "hour";
                    let minuteStr = minutes === 0 ? "" : `${minutes} minute`;

                    if (minutes > 1) minuteStr += "s";

                    retStr = `${hours} ${hourStr} ${minuteStr}`;
                }

                return `Next Meeting will start in ${retStr}`;
            }
            else
            {
                let startStr = format(meetingDate, "HH:mm");
                let endStr = format(new Date(nextMeeting.End), "HH:mm");

                return `${startStr} - ${endStr}`;
            }
        }

        return 'Booked Time Slot - None'
    }

    const OnButtonClick = () => {
        setConfirmationMsg(nextMeeting.Status?.status_id === RoomStatus.In_Use
        ? "Confirm to end the meeting now?"
        : "Confirm to start the meeting now?");
        setOpenConfirm(true);
    }

    const onConfirm = () => {
        const payload = {
            roomId: selectedRoom,
            startTime: nextMeeting.Start
        };

        const func = nextMeeting.Status?.status_id === RoomStatus.In_Use
            ? endMeeting
            : startMeeting;

        dispatch(func(payload));
        setOpenConfirm(false);
    }

    const onClose = () => {
        setOpenConfirm(false);
    }

    const isNextMeetingSameDay = () => {
        const next = new Date(nextMeeting.Start);
        const today = new Date();

        const isSameDate = next.getFullYear() === today.getFullYear()
        && next.getMonth() === today.getMonth()
        && next.getDate() === today.getDate();

        return (isSameDate === true && hasMeeting === true); 
    }
    
    const onExtendMeeting = (toggle) => () => {
        setMeetingExtension(toggle);
    }
    const isRoomInUse = () => {
        const inUsed = nextMeeting.Status?.status_id === RoomStatus.In_Use

        return inUsed;

    }

    return (
        <Paper sx={{width: '100%', padding: 2, color: 'white', background: nextMeeting.Status?.status_color_code}} elevation={4}>
            <Stack flexDirection={"column"} justifyContent={'space-between'} spacing={1}>
                <Typography>
                    {rooms.find(r => r.room_id === selectedRoom)?.room_desc} @ present day
                </Typography>
                <Typography variant={'h3'} pt={1} pb={1}>
                    {nextMeeting.Status?.status_name}
                </Typography>
                <Stack direction={'column'} spacing={1}>
                    <InfoLine icon={<DescriptionIcon />} value={`Reason - ${nextMeeting?.Reason || 'None'}`} />
                    <InfoLine icon={<PersonIcon />} value={`Booked By - ${nextMeeting?.BookedBy || 'None'}`} />
                    <InfoLine icon={<ScheduleIcon />} value={GetMeetingSchedule()} />
                </Stack>
                <Stack direction={"row"} spacing={1}>
                    {
                        isNextMeetingSameDay() === true &&
                        <Box>
                            <MeetingActionBtn textColor={nextMeeting.Status?.status_color_code} variant={"contained"} size={"small"} onClick={OnButtonClick}>{nextMeeting.Status?.button_name ?? "Start/End Meeting"}</MeetingActionBtn>
                        </Box>
                    }
                    {
                        isRoomInUse() === true &&
                        <Box>
                            <MeetingActionBtn textColor={nextMeeting.Status?.status_color_code} variant={"contained"} size={"small"} onClick={onExtendMeeting(true)}>Extend Meeting</MeetingActionBtn>
                        </Box>
                    }
                </Stack>
                <ConfirmationDialog open={openConfirm} onClose={onClose} onConfirm={onConfirm} title={"Meeting Confirmation"} confirmationMsg={confirmationMsg} />
                <ExtendMeetingModal open={extendMeeting} onClose={onExtendMeeting(false)} nextMeeting={nextMeeting} room={selectedRoom} date={bookingDateTime} />
            </Stack>
        </Paper>
    )
}

export default function BookingPage(props)
{
    const dispatch = useDispatch();
    const { isMobile } = props;

    const timeSlotFetching = useSelector(selectFetching);
    const timeslotMeetings = useSelector(selectMeetings);
    const timeslotRefresh = useSelector(selectRefresh);
    const timeslotRefreshNextMeeting = useSelector(selectRefreshNextMeeting);
    const timeslotMessage = useSelector(selectMessage);

    const meetingFetching = useSelector(selectMeetingFetching);
    const nextMeeting = useSelector(selectNextMeeting);

    const roomFetching = useSelector(selectRoomFetching);
    const rooms = useSelector(selectRooms);

    const [selectedRoom, setSelectedRoom] = React.useState(-1);
    const [bookingDateTime, setBookingDateTime] = React.useState(new Date());

    const [bookRoomOpen, setBookRoomOpen] = React.useState(false);
    const [timeslotEvents, setTimeslotEvents] = React.useState([]);
    const [totalMeetings, setTotalMeetings] = React.useState(0);

    const [openCancel, setOpenCancel] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState({});

    React.useEffect(() => {
        if (roomFetching !== true)
        {
            dispatch(fetchRooms());
        }
    }, []);

    React.useEffect(() => {
        if (timeslotMeetings === undefined ||  timeslotMeetings === null) return;
        if (timeslotMeetings.length <= 0) return;
        if (timeslotMessage.length <= 0) return;

        dispatch(setSnackbar({
            open: true,
            message: timeslotMessage,
            severity: 'error'
        }));
    }, [timeslotMessage]);

    React.useEffect(() => {
        if (timeslotRefresh !== true) return;
        if (timeSlotFetching !== true)
        {
            const payload = {
                date: bookingDateTime,
                roomId: selectedRoom
            };
            dispatch(fetchMeetings(payload))
        }
    }, [timeslotRefresh]);

    React.useEffect(() => {
        const payload = {
            date: bookingDateTime,
            roomId: selectedRoom
        };
        dispatch(fetchMeetings(payload))
        dispatch(fetchNextMeeting(selectedRoom));
    }, [selectedRoom]);

    React.useEffect(() => {
        const _temp = timeslotMeetings.map((d) => {
            return {
                title: `${d.Reason}\n(${d.BookedBy} - ${d.Room})`,
                start: new Date(d.Start),
                end: new Date(d.End)
            }
        });

        setTimeslotEvents(_temp);
        setTotalMeetings(_temp.length);
    }, [timeslotMeetings]);

    React.useEffect(() => {
        if (meetingFetching !== true)
        {
            dispatch(fetchNextMeeting(selectedRoom));
        }
    }, [totalMeetings])

    React.useEffect(() => {
        if (timeslotRefreshNextMeeting !== true) return;
        if (meetingFetching !== true)
        {
            dispatch(fetchNextMeeting(selectedRoom));
        }
    }, [timeslotRefreshNextMeeting])

    React.useEffect(() => {
        if (isNaN(selectedRoom)) return;
        if (rooms.length <= 0) return;
        setSelectedRoom(rooms[0].room_id);
    }, [rooms]);

    React.useEffect(() => {
        const payload = {
            date: bookingDateTime,
            roomId: selectedRoom
        };
        dispatch(fetchMeetings(payload))
    }, [bookingDateTime]);

    const OnRoomSelected = (e, v) => {
        setSelectedRoom(e.target.value);
    }

    const OnDateTimeChanged = (v) => {
        setBookingDateTime(v);
    }

    const OnBookRoom = (toggle) => () => {
        setBookRoomOpen(toggle);
    }

    const handleEventClick = (event) => {
        // event Booking
        // Splitting the title into separate substrings
        const parts = event.title.match(/^(.*?)\n\((.*?)\s*-\s*(.*?)\)$/).slice(1);

        // Extracting the separate substrings
        event.Reason = parts[0];
        event.BookedBy = parts[1].trim(); 
        event.Room = parts[2].trim(); 

        // Time Slot
        const startTime = event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        const endTime = event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

        const day = String(event.start.getDate()).padStart(2, '0');
        const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(event.start);
        const year = event.start.getFullYear();
        const date = `${day}-${month}-${year}`;

        event.Timeslot = `${startTime} to ${endTime} (${date})`;

        setSelectedEvent(event);
        setOpenCancel(true);
    };

    const onCancelConfirm = () => {
        const payload = {
            roomId: selectedRoom,
            startTime: selectedEvent.start,
            endTime: selectedEvent.end
        };

        dispatch(cancelMeeting(payload));
        setOpenCancel(false);
    }

    const onCancelClose = () => {
        setOpenCancel(false);
    }

    const toDateString = (date) => {
        if (date instanceof Date){
            return date.toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            }).toUpperCase();
        }
        else{
            return "";
        }
    }
    
    const CustomEvent = ({ event, title, handleEventClick }) => {
        const CancelActionBtn = styled(Button)({
            color: 'white',
            padding: '0',
            minWidth: 24,
            maxWidth: 24,
            cursor: 'pointer',
        });

        return (
            <Grid container>
                <Grid item xs={1} style={{paddingRight:'8px', minWidth:'32px', maxWidth:'32px'}}>
                    <CancelActionBtn onClick={() => handleEventClick(event)}><DeleteIcon/></CancelActionBtn>
                </Grid>
                <Grid item xs={11} style={{maxWidth:'calc(100% - 32px)'}}>
                    <Typography >
                        {title}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={isMobile === true ? 12 : 6}>
                    <Container sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box width={'75%'}>
                            <Stack flexDirection={'row'} alignItems={'center'} spacing={1}>
                                <img src={logo} alt={"HT Consulting Asia"} />
                                <Typography variant={'h6'} alignItems={'center'} color={'#408083'} pl={1}>
                                    Room Booking System
                                </Typography>
                            </Stack>
                            <Stack pt={2} alignItems={'center'} spacing={2}>
                                <Box display={'flex'} justifyContent={'center'} width={'100%'}>
                                    <Box width={'100%'}>
                                        <FormControl fullWidth>
                                            <InputLabel id="room-label">Select Room</InputLabel>
                                            <Select labelId="room-label" label="Select Room" value={selectedRoom} onChange={OnRoomSelected}>
                                                {
                                                    rooms.map((r) => {
                                                        return (
                                                            <MenuItem key={`room-${r.room_id}`} value={r.room_id}>{r.room_desc}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box display={'flex'} justifyContent={'center'} width={'100%'}>
                                    <Box width={'100%'}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['DateTimePicker']}>
                                                    <DatePicker label="Booking Date" onChange={OnDateTimeChanged} value={bookingDateTime} format='dd/MM/yyyy' />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <RoomAvailability rooms={rooms} selectedRoom={selectedRoom} nextMeeting={nextMeeting} dispatch={dispatch} bookingDateTime={bookingDateTime} />
                                <Stack flexDirection={'row'} width={'100%'} justifyContent={'space-evenly'}>
                                    {
                                        !isBefore(bookingDateTime, startOfToday()) &&
                                        <Button variant='contained' color="success" onClick={OnBookRoom(true)}>Add New Booking</Button>
                                    }
                                </Stack>
                            </Stack>
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs={isMobile === true ? 12 : 6} sx={{height: '100vh'}}>
                    <Timeslot localizer={calendarLocalizer} defaultView='day' min={MinTimeslot} max={MaxTimeslot} date={bookingDateTime} timeslots={1} toolbar={false} events={timeslotEvents} bg={nextMeeting.Status?.timeslot_color_code}
                        components={{
                            event: ({ event }) => (
                                <CustomEvent
                                    event={event}
                                    title={event.title}
                                    handleEventClick={handleEventClick}
                                />
                            ),
                          }}
                    />
                </Grid>
            </Grid>
            <RoomBookingModal open={bookRoomOpen} onClose={OnBookRoom(false)} room={selectedRoom} date={bookingDateTime} />
            <ConfirmationDialog open={openCancel} onClose={onCancelClose} onConfirm={onCancelConfirm} title={"Cancel Booking @ " + selectedEvent.Room} confirmationMsg={<><p>Reason - {selectedEvent.Reason}</p><p>Booked by - {selectedEvent.BookedBy}</p><p>Time slot - {selectedEvent.Timeslot}</p></>}/>
        </>
    )   
}
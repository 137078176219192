import { Button, Divider, Stack } from "@mui/material"
import AppContainer from "../AppContainer"
import NewTableBox from "../NewTableBox"
import NewTable from "../NewTable"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchFsmTableBankCode, selectFSMTableBankCodes, selectFSMTableBankCodesFetching } from "../../redux/slices/fsmTableBankCodeSlice"
import { clearFsmTableRecords, fetchFsmTableRecords, selectFSMTableRecords, selectFSMTableRecordsFetching } from "../../redux/slices/fsmTableRecordSlice"
import MonitoringSearchCriteria, { MonitoringSearchDate, MonitoringSearchInput, MonitoringSearchRow, MonitoringSearchSelect, MonitoringSearchText } from "../MonitoringSearchCriteria"
import { addDays, format } from "date-fns"
import CollapseWrap from "../CollapseWrap"
import ChipStack from "../ChipStack"
import { APICall, BackendType, SanitizeInput } from "../../Utils"
import { setSnackbar } from "../../redux/slices/appSlice"

const FSMTables = {
    MailAck: "mail_ack",
    CompleteTicket: "fsm_complete_ticket",
    ProductComplete: "fsm_product_complete",
    ProductIncoming: "fsm_product_incoming",
    CompleteDetails: "fsm_complete_details",
    PMDetails: "fsm_pm_details",
    JobDetails: "job_details",
};

const SearchGridSizes = [6, 4, 2];
const MobileSearchGridSizes = [6, 6];

const SelectFSMTables = Object.values(FSMTables).map((v) => {
    return {
        label: v
    }
});

const MailStatuses = [
    { label: "New" }, { label: "Sent" }, { label: "Received" }, { label: "ALL" }
];

const TableStyle = {
    padding: "8px",
    align: "center"
};

const ColumnTableStyle = {
    ...TableStyle,
    sortable: true
}

const DateColumnTableStyle = {
    ...ColumnTableStyle,
    type: Date,
    dateFormat: "ddMMMyyyy hh:mm:ss a"
}

const GenerateTableFields = (labels) => {
    return labels.map((l) => {
        return { label: l, ...TableStyle };
    });
}

const MailAckColumns = [
    { ...ColumnTableStyle, label: "SRE ID" },
    { ...ColumnTableStyle, label: "JOB ID" },
    { ...ColumnTableStyle, label: "FILENAME" },
    { ...DateColumnTableStyle, label: "BANK RECV DATE" },
    { ...DateColumnTableStyle, label: "STORED DATE" },
    { ...DateColumnTableStyle, label: "MAIL SENT DATE" },
    { ...DateColumnTableStyle, label: "SC RECV DATE" },
    { ...ColumnTableStyle, label: "SENT FREQ" },
    { ...ColumnTableStyle, label: "STATUS" },
    { ...ColumnTableStyle, label: "ACTION" }
];

const CompleteTicketColumns = [
    { ...ColumnTableStyle, label: "SRE ID" },
    { ...ColumnTableStyle, label: "JOB ID" },
    { ...DateColumnTableStyle, label: "STORED DATE" },
    { ...DateColumnTableStyle, label: "APPOINTMENT TIME" },
    { ...DateColumnTableStyle, label: "ARRIVED TIME" },
    { ...DateColumnTableStyle, label: "COMPLETED TIME" },
    { ...DateColumnTableStyle, label: "DEPARTED TIME" },
    { ...ColumnTableStyle, label: "CE NAME" },
    { ...ColumnTableStyle, label: "KIOSK ID" },
    { ...ColumnTableStyle, label: "BRANCH" },
    { ...ColumnTableStyle, label: "BANK" },
    { ...ColumnTableStyle, label: "PROBLEM TYPE" },
    { ...ColumnTableStyle, label: "PROBLEM DESC" },
    { ...ColumnTableStyle, label: "PROBLEM DETAIL" },
    { ...ColumnTableStyle, label: "SOLUTION" },
    { ...ColumnTableStyle, label: "CE COMMENT" },
];

const ProductCompleteColumns = [
    { ...ColumnTableStyle, label: "SRE ID" },
    { ...ColumnTableStyle, label: "JOB ID" },
    { ...DateColumnTableStyle, label: "STORED DATE" },
    { ...DateColumnTableStyle, label: "SERVICED TIME" },
    { ...DateColumnTableStyle, label: "REVIEWED TIME" },
    { ...ColumnTableStyle, label: "CE NAME" },
    { ...ColumnTableStyle, label: "REVIEWER" },
    { ...ColumnTableStyle, label: "CE QUANTITY" },
    { ...ColumnTableStyle, label: "REVIEWER QUANTITY" },
    { ...ColumnTableStyle, label: "PRODUCT #" },
    { ...ColumnTableStyle, label: "KIOSK ID" },
    { ...ColumnTableStyle, label: "BRANCH" },
    { ...ColumnTableStyle, label: "BANK" },
    { ...ColumnTableStyle, label: "IS CHARGEABLE?" }
];

const ProductIncomingColumns = [
    { ...ColumnTableStyle, label: "SRE ID" },
    { ...ColumnTableStyle, label: "JOB ID" },
    { ...DateColumnTableStyle, label: "STORED DATE" },
    { ...DateColumnTableStyle, label: "SERVICED TIME" },
    { ...ColumnTableStyle, label: "CE NAME" },
    { ...ColumnTableStyle, label: "PRODUCT #" },
    { ...ColumnTableStyle, label: "QUANTITY" },
    { ...ColumnTableStyle, label: "KIOSK ID" },
    { ...ColumnTableStyle, label: "BRANCH" },
    { ...ColumnTableStyle, label: "BANK" },
    { ...ColumnTableStyle, label: "IS CHARGEABLE?" }
];

const CompleteDetailsColumns = [
    { ...ColumnTableStyle, label: "SRE ID" },
    { ...ColumnTableStyle, label: "JOB ID" },
    { ...DateColumnTableStyle, label: "STORED DATE" },
    { ...DateColumnTableStyle, label: "APPOINTMENT TIME" },
    { ...DateColumnTableStyle, label: "ARRIVED TIME" },
    { ...DateColumnTableStyle, label: "COMPLETED TIME" },
    { ...DateColumnTableStyle, label: "DEPARTED TIME" },
    { ...DateColumnTableStyle, label: "REVIEWED TIME" },
    { ...ColumnTableStyle, label: "CE NAME" },
    { ...ColumnTableStyle, label: "REVIEWER" },
    { ...ColumnTableStyle, label: "PURPOSE" },
    { ...ColumnTableStyle, label: "KIOSK ID" },
    { ...ColumnTableStyle, label: "BRANCH" },
    { ...ColumnTableStyle, label: "BANK" },
    { ...ColumnTableStyle, label: "PROBLEM TYPE" },
    { ...ColumnTableStyle, label: "PROBLEM DESC" },
    { ...ColumnTableStyle, label: "PROBLEM DETAIL" },
    { ...ColumnTableStyle, label: "SOLUTION" },
    { ...ColumnTableStyle, label: "CE COMMENT" }
];

const PmDetailsColumns = [
    { ...ColumnTableStyle, label: "SRE ID" },
    { ...ColumnTableStyle, label: "JOB ID" },
    { ...DateColumnTableStyle, label: "STORED DATE" },
    { ...ColumnTableStyle, label: "KIOSK ID" },
    { ...ColumnTableStyle, label: "SERIAL #" },
    { ...ColumnTableStyle, label: "BRANCH" },
    { ...ColumnTableStyle, label: "BANK" },
    { ...ColumnTableStyle, label: "RECENT PROB CHECK" },
    { ...ColumnTableStyle, label: "CABLES CHECK" },
    { ...ColumnTableStyle, label: "PAPERPATH CHECK" },
    { ...ColumnTableStyle, label: "SOLENOID CHECK" },
    { ...ColumnTableStyle, label: "PAPERPATH CLEAN" },
    { ...ColumnTableStyle, label: "SENSOR CLEAN" },
    { ...ColumnTableStyle, label: "FEEDER CHECK" },
    { ...ColumnTableStyle, label: "FEEDER TEST" },
    { ...ColumnTableStyle, label: "MICR CLEAN" },
    { ...ColumnTableStyle, label: "BELT CHECK" },
    { ...ColumnTableStyle, label: "TOOTHED BELT" },
    { ...ColumnTableStyle, label: "STAMP CHECK" },
    { ...ColumnTableStyle, label: "RIBBON CHECK" },
    { ...ColumnTableStyle, label: "SCANNER CLEAN" },
    { ...ColumnTableStyle, label: "CR CLEAN" },
    { ...ColumnTableStyle, label: "CAS CHECK" },
    { ...ColumnTableStyle, label: "COLL BOX OK" },
    { ...ColumnTableStyle, label: "UPPER COVER CHECK" },
    { ...ColumnTableStyle, label: "UPPER COVER CLEAN" },
    { ...ColumnTableStyle, label: "CLEAN COMP" },
    { ...ColumnTableStyle, label: "TOUCH PANEL" },
    { ...ColumnTableStyle, label: "HDD CHECK" },
    { ...ColumnTableStyle, label: "HOUSEKEEP" },
    { ...ColumnTableStyle, label: "BIOS CHECK" },
    { ...ColumnTableStyle, label: "BOOT SEQ" },
];

const JobDetailsColumns = [
    { ...ColumnTableStyle, label: "SRE ID" },
    { ...DateColumnTableStyle, label: "STORED DATE" },
    { ...DateColumnTableStyle, label: "JOB RECEIVED TIME" },
    { ...DateColumnTableStyle, label: "RESOLUTION TIME" },
    { ...DateColumnTableStyle, label: "SLM MEET TIME" },
    { ...DateColumnTableStyle, label: "SLA START TIME" },
    { ...ColumnTableStyle, label: "BRANCH NAME" },
    { ...ColumnTableStyle, label: "KIOSK ID" },
    { ...ColumnTableStyle, label: "VIP" },
    { ...ColumnTableStyle, label: "OUTSOURCED" },
    { ...ColumnTableStyle, label: "REPORTED JOB ISSUE" },
];

const ResendButton = ({ data, onReload }) => {
    const dispatch = useDispatch();
    
    const OnClick = async () => {
        const payload = {
            MailId: data.MailId,
            OriginSreId: data.OriginSreId
        };

        APICall(BackendType.MnC, "fsm/resendMail", "POST", payload).then((resp) => {
            onReload();
        }).catch((e) => {
            dispatch(setSnackbar({
                open: true,
                message: e.response.data.message,
                severity: "error"
            }));
        });
    }

    return (
        <Button variant={"contained"} disabled={!data.EnableResend} size={"small"} onClick={OnClick}>Resend</Button>
    )
}

export default function FSMTableReports({isMobile}) {
    const dispatch = useDispatch();

    const [selectedTable, setSelectedTable] = React.useState(FSMTables.MailAck); 
    const [startDate, setStartDate] = React.useState(new Date()); 
    const [endDate, setEndDate] = React.useState(new Date()); 
    const [sreId, setSREId] = React.useState("");
    
    const [uniqueItems, setUniqueItems] = React.useState([]);
    const [uniqueItem, setUniqueItem] = React.useState("");

    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    const [gridSize, setGridSize] = React.useState(SearchGridSizes);

    const [firstLoad, setFirstLoad] = React.useState(true);
    const [filterChips, setFilterChips] = React.useState([]);

    const bankCodes = useSelector(selectFSMTableBankCodes);
    const bankCodesFetching = useSelector(selectFSMTableBankCodesFetching);

    const data = useSelector(selectFSMTableRecords);
    const dataFetching = useSelector(selectFSMTableRecordsFetching);

    React.useEffect(() => {
        setGridSize(isMobile === true ? MobileSearchGridSizes : SearchGridSizes);
    }, [isMobile]);

    React.useEffect(() => {
        if (firstLoad !== true) return;
        setFirstLoad(false);

        const url = new URL(window.location);
        const filterTable = url.searchParams.get("table");

        const paramTable = Object.values(FSMTables).find(s => s === filterTable);

        if (paramTable !== undefined && paramTable !== null)
        {
            setSelectedTable(paramTable);
        }

        return () => {
            dispatch(clearFsmTableRecords());
        }
    }, []);

    React.useEffect(() => {
        if (selectedTable === FSMTables.MailAck)
        {
            setUniqueItem("ALL");
            setUniqueItems(MailStatuses);
        }
        else if (selectedTable != FSMTables.JobDetails)
        {
            dispatch(fetchFsmTableBankCode({table: selectedTable}));
        }
        else
        {
            setUniqueItems([]);
            setUniqueItem("");
        }
    }, [selectedTable]);

    React.useEffect(() => {
        if (bankCodesFetching === true) return;
        if (selectedTable === FSMTables.MailAck) return;
        
        if (bankCodes.length > 0)
        {
            let _bankCodes = bankCodes.map((b) => {
                return {
                    label: b
                }
            }) 
            _bankCodes.push({label: "ALL"});
            setUniqueItem("ALL");
            setUniqueItems(_bankCodes);
        }
        else
        {
            setUniqueItems([]);
            setUniqueItem("");
        }
    }, [bankCodes, bankCodesFetching]);

    React.useEffect(() => {
        let _rows = [];

        switch (selectedTable)
        {
            case FSMTables.MailAck:
                data.forEach((d) => {
                    _rows.push(
                        GenerateTableFields([d.OriginSreId, d.FsmJobId, d.MailFile, d.MailRecvOriginDT, d.CreateTimestamp, d.MailSentDT, d.MailRecvScDT, d.MailSentFreq, d.MailStatus, <ResendButton data={d} onReload={OnActionReload} />])
                    );
                })
                break;
            case FSMTables.CompleteTicket:
                data.forEach((d) => {
                    _rows.push(
                        GenerateTableFields([d.OriginSreId, d.FsmJobId, d.CreateTimestamp, d.ServicedDate, d.ArrivedTime, d.CompletedTime, d.DepartedTime, d.ServicedBy, d.MachineId,
                             d.BranchCode, d.BankCode, d.ProblemType, d.ProblemDesc, d.ProblemDetail, d.ActionTaken, d.Remarks])
                    );
                })
                break;
            case FSMTables.ProductComplete:
                data.forEach((d) => {
                    _rows.push(
                        GenerateTableFields([d.OriginSreId, d.FsmJobId, d.CreateTimestamp, d.ServicedDate, d.ReviewedDate, d.ServicedBy, d.ReviewedBy, d.MakerQuantity, d.CheckerQuantity,
                            d.ProductCode, d.MachineId, d.BranchCode, d.BankCode, d.IsChargeable])
                    );
                })
                break;
            case FSMTables.ProductIncoming:
                data.forEach((d) => {
                    _rows.push(
                        GenerateTableFields([d.OriginSreId, d.FsmJobId, d.CreateTimestamp, d.ServicedDate, d.ServicedBy, d.ProductCode, d.QuantityUsed, d.MachineId, d.BranchCode, d.BankCode, d.IsChargeable])
                    );
                })
                break;
            case FSMTables.CompleteDetails:
                data.forEach((d) => {
                    _rows.push(
                        GenerateTableFields([d.OriginSreId, d.FsmJobId, d.CreateTimestamp, d.AppointmentTime, d.ArrivedTime, d.CompletedTime, d.DepartedTime, d.ReviewedTime, d.CEName,
                            d.Reviewer, d.Purpose, d.KioskId, d.Branch, d.Bank, d.ProblemType, d.ProblemDesc, d.ProblemDetail, d.Solution, d.CEComment])
                    );
                });
                break;
            case FSMTables.PMDetails:
                data.forEach((d) => {
                    _rows.push(
                        GenerateTableFields([d.OriginSreId, d.FsmJobId, d.StoredDate, d.KioskId, d.SerialNo, d.Branch, d.Bank, d.RecentProbCheck, d.CablesCheck, d.PaperPathCheck, d.SolenoidCheck,
                            d.PaperPathClean, d.SensorClean, d.FeederCheck, d.FeederTest, d.MICRClean, d.BeltCheck, d.ToothedBelt, d.StampCheck, d.RibbonCheck, d.ScannerClean, d.CRClean, d.CASCheck,
                            d.CollBoxOk, d.UpperCoverCheck, d.UpperCoverClean, d.CleanComp, d.TouchPanel, d.HDDCheck, d.Housekeep, d.BiosCheck, d.BootSeq])
                    );
                });
                break;
            case FSMTables.JobDetails:
                data.forEach((d) => {
                    _rows.push(
                        GenerateTableFields([d.OriginSreId, d.CreateTimestamp, d.JobReceivedTime, d.ResolutionTime, d.SLMMeetTime, d.SLAStartTime, d.BranchName, d.KioskID, d.VIP, d.Outsourced, d.ReportedJobIssue])
                    );
                });
                break;
        }

        setRows(_rows);
    }, [data]);

    const OnActionReload = () => {
        const payload = PrepareFetchPayload();
        dispatch(fetchFsmTableRecords(payload));
    }

    const PrepareFetchPayload = () => {
        let payload = {
            table: selectedTable,
            startDate: startDate,
            endDate: endDate,
            sreId: SanitizeInput(sreId),
            bankCode: "",
            mailStatus: ""
        };

        if (selectedTable === FSMTables.MailAck)
        {
            payload.mailStatus = uniqueItem === "ALL" ? "N,S,R" : uniqueItem[0];
        }
        else if (selectedTable !== FSMTables.JobDetails)
        {
            payload.bankCode = uniqueItem === "ALL" ? bankCodes.join(',') : uniqueItem;
        }

        return payload;
    }

    const OnFilterSubmit = () => {
        let payload = PrepareFetchPayload();

        let _columns = [];

        switch (selectedTable)
        {
            case FSMTables.MailAck:
                _columns = MailAckColumns;
                break;
            case FSMTables.CompleteTicket:
                _columns = CompleteTicketColumns;
                break;
            case FSMTables.ProductComplete:
                _columns = ProductCompleteColumns;
                break;
            case FSMTables.ProductIncoming:
                _columns = ProductIncomingColumns;
                break;
            case FSMTables.CompleteDetails:
                _columns = CompleteDetailsColumns;
                break;
            case FSMTables.PMDetails:
                _columns = PmDetailsColumns;
                break;
            case FSMTables.JobDetails:
                _columns = JobDetailsColumns;
                break;
        }

        const _rows = [_columns.map((c => {
            return { label: `${c.label}-data` }
        }))];

        setColumns(_columns);
        setRows(_rows);

        dispatch(fetchFsmTableRecords(payload));
    }

    const OnCollapse = () => {
        let _stack = [];
        _stack.push(selectedTable);
        if (uniqueItem.length > 0)
        {
            _stack.push(uniqueItem);
        }
        _stack.push(`${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}`);
        if (sreId.length > 0)
        {
            _stack.push(sreId);
        }
        
        setFilterChips(_stack);
    }

    const OnUncollapse = () => {
        setFilterChips([]);
    }

    return (
        <AppContainer>
            <NewTableBox title={"FSM Information List"} height={"100%"}>
                <Stack spacing={1} height={"100%"}>
                    <CollapseWrap defaultShow onHide={OnCollapse} onShow={OnUncollapse}>
                        <MonitoringSearchCriteria isMobile={isMobile}>
                            <MonitoringSearchRow gridSizes={gridSize}>
                                <MonitoringSearchSelect label={"Table Name"} value={selectedTable} setValue={setSelectedTable} items={SelectFSMTables} />
                                {
                                    uniqueItems.length > 0 &&
                                    <MonitoringSearchSelect value={uniqueItem} setValue={setUniqueItem} items={uniqueItems} />
                                }
                            </MonitoringSearchRow>
                            <MonitoringSearchRow gridSizes={gridSize}>
                                <MonitoringSearchDate label={"Date Range"} dateLabel={"Start Date"} value={startDate} setValue={setStartDate} />
                                <MonitoringSearchDate value={endDate} dateLabel={"End Date"} setValue={setEndDate} maxDate={addDays(startDate, 30)} />
                                {
                                    isMobile !== true &&
                                    <MonitoringSearchText color={"blue"} fontStyle={"italic"}>*limit up to 30 days only!</MonitoringSearchText>
                                }
                            </MonitoringSearchRow>
                            <MonitoringSearchRow gridSizes={gridSize}>
                                <MonitoringSearchInput label={"SRE ID"} value={sreId} setValue={setSREId} />
                                <Button fullWidth variant={"contained"} onClick={OnFilterSubmit}>Submit</Button>
                            </MonitoringSearchRow>
                        </MonitoringSearchCriteria>
                    </CollapseWrap>
                    <ChipStack direction={"row"} size={"small"} chips={filterChips} />
                    <Divider />
                    <NewTable columns={columns} rows={rows} pageSizeOptions={[10, 20, 30, 40, 50]} loading={dataFetching} autoHeight />
                </Stack>
            </NewTableBox>
        </AppContainer>
    )
}